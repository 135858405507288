import React from 'react'

import { Empty } from 'antd'
import { Flex } from 'fwego'

const EmptyInsightsContent: React.FC = () => {
  return (
    <Flex
      alignItems="center"
      flexDirection="column"
      height="100%"
      justifyContent="center"
      width="100%"
    >
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
    </Flex>
  )
}

export default EmptyInsightsContent
