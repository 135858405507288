import React, { useState, useMemo } from 'react'

import { css } from '@emotion/core'
import { useAppSelector } from '@src/app/hooks'
import { VIDEO_PERFORMANCE_CARD } from '@src/constants'
import { isXsScreen } from '@src/hooks'
import { Table, Skeleton } from 'antd'
import qs from 'qs'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useParams, useLocation } from 'react-router-dom'

import { Card, CardHeader } from '.'
import { convertTimeUnit } from '../utils/stats'

type PerformanceType = {
  data: any[]
  endDate: string
  startDate: string
  timezone: string
  onClick?: (record: any) => void
  showMetric?: boolean
}

type VideoPerformanceCard = {
  loading: boolean
  data: any[]
  endDate: string
  headerExtra: JSX.Element
  startDate: string
  timezone: string
  onClick?: (record: any) => void
  showMetric?: boolean
}

const PerformanceTable = ({
  data,
  endDate,
  startDate,
  timezone,
  onClick = undefined,
  showMetric = true
}: PerformanceType): JSX.Element => {
  const { businessId, channelId } = useParams()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const isMobile = isXsScreen()
  // Gets the type of Video Performance Insights
  const { pathname } = useLocation()
  const {
    KEY_VIDEO_CAPTION,
    KEY_VIEWS,
    KEY_WATCH_TIME,
    PRODUCT_CLICKS,
    AVG_WATCH_TIME,
    TYPE_CHANNEL
  } = VIDEO_PERFORMANCE_CARD
  const showProductClicks = useMemo(() => {
    if (!data.length) return false
    for (const i of data) {
      if (i[PRODUCT_CLICKS] !== 0) return true
    }

    return false
  }, [data, PRODUCT_CLICKS])
  let type
  if (pathname.includes(TYPE_CHANNEL)) type = TYPE_CHANNEL
  const { username } = useAppSelector(
    (state) => state.channel.channels[channelId]
  )
  const [pageSize, setPageSize] = useState(10)
  const tableColumns: any = []
  tableColumns.push(
    {
      align: 'left',
      fixed: 'left',
      dataIndex: type === TYPE_CHANNEL ? 'video_caption' : ['video', 'caption'],
      key: KEY_VIDEO_CAPTION,
      title: t('Video'),
      width: isMobile ? 200 : 'auto'
    },
    {
      align: 'left',
      dataIndex:
        type === TYPE_CHANNEL
          ? 'video_creator_username'
          : ['video', 'creator', 'username'],
      key: 'creator',
      title: t('Creator'),
      render: function renderCreator(text: string) {
        return text === username && <span>{text}</span>
      },
      width: 150
    }
  )
  if (showMetric) {
    tableColumns.push(
      {
        align: 'left',
        dataIndex: KEY_VIEWS,
        key: KEY_VIEWS,
        title: t('Views'),
        width: 100,
        sorter: (a, b) => b[KEY_VIEWS] - a[KEY_VIEWS],
        render: (value) => value.toLocaleString()
      },
      {
        align: 'left',
        dataIndex: PRODUCT_CLICKS,
        key: PRODUCT_CLICKS,
        title: t('Product Clicks'),
        width: 150,
        sorter: (a, b) => b[PRODUCT_CLICKS] - a[PRODUCT_CLICKS],
        render: (value) => value.toLocaleString()
      }
    )
  }
  tableColumns.push({
    align: 'left',
    dataIndex: KEY_WATCH_TIME,
    key: KEY_WATCH_TIME,
    title: t('Watch Time'),
    width: 150,
    sorter: (a, b) => b[KEY_WATCH_TIME] - a[KEY_WATCH_TIME],
    render: (value) => convertTimeUnit(value)
  })
  // Need to separate 2 steps to keep column order
  if (showMetric) {
    tableColumns.push({
      align: 'left',
      dataIndex: 'avg_watch_time',
      title: t('Avg Watch Time'),
      key: 'avg_watch_time',
      sorter: (a, b) => b[AVG_WATCH_TIME] - a[AVG_WATCH_TIME],
      width: 200
    })
  }
  if (!showProductClicks) {
    let index = 3
    for (const i in tableColumns) {
      if (tableColumns[i].dataIndex === PRODUCT_CLICKS) index = parseInt(i)
    }
    tableColumns.splice(index, 1)
  }

  const onRowClick = (insight) => {
    const id = channelId
    const videoId = insight.video?.encoded_id || insight.video_id
    const query = {
      start_date: startDate,
      end_date: endDate,
      timezone
    }

    navigate(
      `/business/${businessId}/${type}/${id}/video_performance/${videoId}?${qs.stringify(
        query
      )}`
    )
  }

  return (
    <Table
      columns={tableColumns}
      scroll={
        isMobile && {
          y: 300
        }
      }
      css={css`
        .ant-table-row {
          cursor: pointer;
        }
        .anticon {
          vertical-align: middle;
          padding-bottom: 6px;
        }
      `}
      dataSource={data}
      onRow={(record) => ({
        onClick: () => (onClick ? onClick(record) : onRowClick(record))
      })}
      pagination={{ pageSize }}
      rowKey={(record) => record.video_id}
      onChange={({ pageSize }) => setPageSize(pageSize)}
    />
  )
}

const VideoPerformanceCard = ({
  loading,
  data = [],
  endDate,
  headerExtra,
  startDate,
  timezone,
  onClick,
  showMetric = true
}: VideoPerformanceCard): JSX.Element => {
  const { t } = useTranslation()

  // Replaces any falsy video.caption with a translated placeholder
  const sanitizeData = (data) =>
    data.map((entry) => {
      if (!entry.video?.caption) {
        return {
          ...entry,
          video: {
            ...entry.video,
            caption: t('(No title)')
          }
        }
      }

      return entry
    })
  const sanitizedData = sanitizeData(data)

  return (
    <Card m="0">
      <Skeleton active loading={loading}>
        <CardHeader
          extra={headerExtra}
          title={t('Video Performance')}
          tooltip={t('Video performance sorted by views')}
        />
        <PerformanceTable
          data={sanitizedData}
          endDate={endDate}
          startDate={startDate}
          timezone={timezone}
          onClick={onClick}
          showMetric={showMetric}
        />
      </Skeleton>
    </Card>
  )
}

export default VideoPerformanceCard
