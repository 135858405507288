import { createAction, createSlice } from '@reduxjs/toolkit'
import api from '@src/utils/api'
import { Dispatch } from 'redux'

const slice = createSlice({
  name: 'shopMinis',
  initialState: {},
  reducers: {}
})

export default slice.reducer

const publishToShopAppRequest = createAction(
  'channelStore/publishToShopAppRequest'
)
const publishToShopAppFailure = createAction(
  'channelStore/publishToShopAppFailure'
)

export function publishToShopApp(
  businessId: string,
  channelId: string,
  playlistId: string,
  businessStoreId: string,
  location: string
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(publishToShopAppRequest())
      const response = await api.post(
        `/bus/${businessId}/business_stores/shop_mini/set_entry_point`,
        {
          business_store_id: businessStoreId,
          channel_id: channelId,
          location: location,
          playlist_id: playlistId
        }
      )

      return response
    } catch (error) {
      dispatch(publishToShopAppFailure())

      return error
    }
  }
}
