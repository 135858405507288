import { createAction, createSlice } from '@reduxjs/toolkit'
import {
  FB_URL,
  fbClientId,
  FbToken,
  getFbRedirectUri,
  IG_HASHTAG_IMPORTER_URL,
  saveFbToken
} from '@src/utils/fbAuth'
import { Dispatch } from 'redux'

import api from '../utils/api'

const slice = createSlice({
  name: 'fbAuth',
  initialState: {},
  reducers: {
    fetchFbTokenSuccess(state, action) {
      const { userId, fbToken } = action.payload
      saveFbToken(userId, fbToken)
    }
  }
})

export default slice.reducer
export const { fetchFbTokenSuccess } = slice.actions

const fetchFbTokenRequest = createAction('fbAuth/fetchIgTokenRequest')
const fetchFbTokenFailure = createAction('fbAuth/fetchIgTokenFailure')

export function code2Token(userId: string, code: string) {
  return async (dispatch: Dispatch): Promise<FbToken> => {
    try {
      dispatch(fetchFbTokenRequest())

      const response = await fetch(
        `${IG_HASHTAG_IMPORTER_URL}/facebook/exchange?client_id=${fbClientId}&redirect_uri=${getFbRedirectUri()}&code=${code}`,
        {
          method: 'GET',
          headers: {
            Authorization: `bearer ${api.getToken()}`,
            'Content-Type': 'application/json'
          }
        }
      )
      const data = (await response.json()) as any

      const { access_token, expires_in } = data

      const meRes = await fetch(`${FB_URL}/me?access_token=${access_token}`)
      const { id, name } = (await meRes.json()) as any

      const date = new Date()
      const fbToken: FbToken = {
        accessToken: access_token,
        expiresIn: date.getTime() + expires_in * 1000,
        id,
        username: name
      }

      dispatch(
        fetchFbTokenSuccess({
          userId,
          fbToken
        })
      )

      return fbToken
    } catch (error) {
      dispatch(fetchFbTokenFailure())

      return error
    }
  }
}
