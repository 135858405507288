import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from '@src/app/store'
import qs from 'qs'

import api from '../utils/api'

type Config = globalLib.SmartButton.Config
type Channel = globalLib.Channel
type Playlist = globalLib.Playlist
type Video = globalLib.IVideo

type SmartButtonState = {
  smartButtons: Config[]
  deleting: boolean
  saving: boolean
  channels: Record<string, Channel>
  chatChannels: Record<string, any>[]
  playlists: Record<string, Playlist[]>
  videosByChannelId: Record<string, Video[]>
}

const initialState: SmartButtonState = {
  smartButtons: [],
  deleting: false,
  saving: false,
  channels: {},
  chatChannels: [],
  playlists: {},
  videosByChannelId: {}
}

const SLICE_KEY = 'smartButton'

type FetchSmartButtonsParams = {
  businessId: string
  params?: Partial<{
    before_id: string
    since_id: string
    page_size: number
    order: 'asc' | 'desc'
  }>
}

export const fetchSmartButtons = createAsyncThunk(
  `${SLICE_KEY}/fetchSmartButtons`,
  async ({ businessId, params }: FetchSmartButtonsParams) => {
    const qp = qs.stringify(params)
    const { data } = await api.get<{
      entries: Config[]
      nextPage: number | null
    }>(`/bus/${businessId}/smart_buttons?${qp}`)

    return data
  }
)

type CreateSmartButtonParams = {
  businessId: string
  config: Omit<Config, 'id' | 'business_id'>
}

export const createSmartButton = createAsyncThunk(
  `${SLICE_KEY}/createSmartButton`,
  async ({ businessId, config }: CreateSmartButtonParams) => {
    const { data } = await api.post<Config>(
      `/bus/${businessId}/smart_buttons`,
      config
    )

    return data
  }
)

type UpdateSmartButtonParams = {
  businessId: string
  buttonId: string
  config: Omit<Config, 'id' | 'business_id'>
}

export const updateSmartButton = createAsyncThunk(
  `${SLICE_KEY}/updateSmartButton`,
  async ({ businessId, buttonId, config }: UpdateSmartButtonParams) => {
    const { data } = await api.put<Config>(
      `/bus/${businessId}/smart_buttons/${buttonId}`,
      config
    )

    return data
  }
)

type DeleteSmartButtonParams = {
  businessId: string
  buttonId: string
}

export const deleteSmartButton = createAsyncThunk(
  `${SLICE_KEY}/deleteSmartButton`,
  async ({ businessId, buttonId }: DeleteSmartButtonParams) => {
    await api.delete(`/bus/${businessId}/smart_buttons/${buttonId}`)
  }
)

export const fetchSmartButtonChannels = createAsyncThunk(
  `${SLICE_KEY}/fetchSmartButtonChannels`,
  async (businessId: string) => {
    const { data } = await api.get<{ channels: Channel[] }>(
      `/bus/${businessId}/channels?page_size=1000`
    )

    return data
  }
)

export const fetchSmartButtonChatChannels = createAsyncThunk(
  `${SLICE_KEY}/fetchSmartButtonChatChannels`,
  async (businessId: string) => {
    const { data } = await api.get<{ chat_channels: Record<string, any>[] }>(
      `bus/${businessId}/chat_channels?page_size=1000`
    )

    return data
  }
)

type FetchSmartButtonChannelPlaylistsParams = {
  businessId: string
  channelId: string
}

export const fetchSmartButtonChannelPlaylists = createAsyncThunk(
  `${SLICE_KEY}/fetchSmartButtonChannelPlaylists`,
  async ({ businessId, channelId }: FetchSmartButtonChannelPlaylistsParams) => {
    const { data } = await api.get<{ playlists: Playlist[] }>(
      `/bus/${businessId}/channels/${channelId}/playlists?page_size=1000`
    )

    return data
  }
)

type FetchChannelAllVideosParams = {
  businessId: string
  channelId: string
}

export const fetchChannelAllVideos = createAsyncThunk(
  `${SLICE_KEY}/fetchChannelAllVideos`,
  async ({ businessId, channelId }: FetchChannelAllVideosParams) => {
    const { data } = await api.get<{ videos: Video[] }>(
      `/bus/${businessId}/videos`,
      {
        params: {
          channel_id: channelId,
          page_size: 1000
        }
      }
    )

    return data
  }
)

const slice = createSlice({
  name: SLICE_KEY,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(createSmartButton.fulfilled, (state, { payload }) => {
        state.smartButtons.unshift(payload)
        state.saving = false
      })
      .addCase(deleteSmartButton.fulfilled, (state, { meta }) => {
        const buttonId = meta.arg.buttonId
        // We could use `filter` here but findIndex will return as soon as a match is found
        // whereas filter has to visit every element. Minor optimization
        const index = state.smartButtons.findIndex(({ id }) => id === buttonId)
        state.smartButtons.splice(index, 1)
        state.deleting = false
      })
      .addCase(fetchSmartButtons.fulfilled, (state, { payload }) => {
        state.smartButtons = payload.entries
      })
      .addCase(updateSmartButton.fulfilled, (state, { payload }) => {
        const { id } = payload
        const index = state.smartButtons.findIndex(
          ({ id: b_id }) => b_id === id
        )
        state.smartButtons[index] = payload
        state.saving = false
      })
      .addCase(deleteSmartButton.pending, (state) => {
        state.deleting = true
      })
      .addCase(createSmartButton.pending, (state) => {
        state.saving = true
      })
      .addCase(updateSmartButton.pending, (state) => {
        state.saving = true
      })
      .addCase(deleteSmartButton.rejected, (state) => {
        state.deleting = false
      })
      .addCase(createSmartButton.rejected, (state) => {
        state.saving = false
      })
      .addCase(updateSmartButton.rejected, (state) => {
        state.saving = false
      })
      .addCase(fetchSmartButtonChannels.fulfilled, (state, { payload }) => {
        state.channels = payload.channels.reduce(
          (acc, channel) => ({
            ...acc,
            [channel.id]: channel
          }),
          {} as Record<string, Channel>
        )
      })
      .addCase(fetchSmartButtonChatChannels.fulfilled, (state, { payload }) => {
        state.chatChannels = payload.chat_channels
      })
      .addCase(
        fetchSmartButtonChannelPlaylists.fulfilled,
        (state, { payload, meta }) => {
          state.playlists[meta.arg.channelId] = payload.playlists
        }
      )
      .addCase(fetchChannelAllVideos.fulfilled, (state, { payload, meta }) => {
        const { arg } = meta

        state.videosByChannelId[arg.channelId] = payload.videos
      })
  }
})

export const selectSmartButtons = (state: RootState): Config[] =>
  state.smartButton.smartButtons

export const selectSmartButton = (
  state: SmartButtonState,
  id: string
): Config | null => {
  return state.smartButtons.find(({ id: b_id }) => b_id === id) ?? null
}

export const selectIsSavingSmartButton = (state: RootState): boolean =>
  state.smartButton.saving

export const selectIsDeletingSmartButton = (state: RootState): boolean =>
  state.smartButton.deleting

export const selectSmartButtonChannels = (
  state: RootState
): Record<string, Channel> => state.smartButton.channels

export const selectSmartButtonChatChannels = (
  state: RootState
): Record<string, any>[] => state.smartButton.chatChannels

export default slice.reducer
