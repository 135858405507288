import React from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import Avatar from '@src/components/Avatar'
import { InputCtrl, TextAreaCtrl } from '@src/components/ControlledFields'
import { Box, Flex } from '@src/components/EmotionLayout'
import LocaleSelector from '@src/components/Settings/LocaleSelector'
import { updateChannel } from '@src/redux/channel'
import { validateResponseSuccess } from '@src/utils/api'
import { notification } from 'antd'
import isUrl from 'is-url'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import * as Yup from 'yup'

import { FWButton } from '..'
import BrandUsername from './BrandUsername'

type Inputs = {
  name: string
  bio: string
  promoteUrl: string
  gaTrackingID: string
  locale: string
}

export type Props = {
  channel: globalLib.Channel
  closeModal: () => void
  uploadProgress: number
  onFileSelected: (file: any) => void
}

export const BrandEdit: React.FC<Props> = ({
  channel,
  closeModal,
  uploadProgress,
  onFileSelected
}): JSX.Element => {
  const { businessId, channelId } = useParams()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { control, handleSubmit } = useForm<Inputs>({
    resolver: yupResolver(getSchema({ t })),
    defaultValues: {
      name: channel?.name,
      bio: channel?.bio,
      promoteUrl: channel?.promote_url,
      gaTrackingID: channel?.ga_tracking_id,
      locale: channel?.locale
    }
  })

  const onSubmit = async (data) => {
    if (data['promoteUrl']) {
      const val = data['promoteUrl']
      data['promoteUrl'] = val.indexOf('http') < 0 ? `http://${val}` : val
    }
    const res = await dispatch(updateChannel(businessId, channelId, data))
    closeModal()

    if (validateResponseSuccess(res)) {
      notification.success({ message: t('Brand updated') })
    }
  }

  return (
    <Flex flexDirection="column" alignItems="center">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex
          mb="medium"
          pb="large"
          alignItems="center"
          borderBottom="1px solid #d9d9d9"
        >
          <Box
            p="xsmall"
            overflow="hidden"
            borderRadius="4px"
            border="1px solid #d9d9d9"
          >
            <Avatar
              src={channel?.avatar_url}
              uploadProgress={uploadProgress}
              onFileSelected={onFileSelected}
              disabled={false}
              onAvatarClick={null}
              noShadow
            />
          </Box>
          <Box ml="large2" flex="1">
            <InputCtrl
              required
              name="name"
              control={control}
              label={t('Brand Name')}
              placeholder={t('My Brand Name')}
            />
            <BrandUsername username={channel?.name} />
          </Box>
        </Flex>
        <TextAreaCtrl
          name="bio"
          textRows={6}
          control={control}
          label={t('Description')}
          placeholder={t('e.g. A site about all the things I want to share.')}
        />
        <InputCtrl
          control={control}
          name="promoteUrl"
          label={t('Promote URL')}
          placeholder="https://mychannel.com/channely"
        />
        <LocaleSelector control={control} />
        <Flex
          justifyContent="flex-end"
          position="absolute"
          bottom="0"
          right="0"
          m="large2"
        >
          <FWButton onClick={closeModal}>{t('Cancel')}</FWButton>
          <Box mr="medium" />
          <FWButton type="primary" htmlType="submit">
            {t('Save')}
          </FWButton>
        </Flex>
      </form>
    </Flex>
  )
}

const getSchema = ({ t }): any => {
  return Yup.object({
    name: Yup.string().trim().max(100, t('Too Long')).required(t('Required')),
    bio: Yup.string().trim().nullable(),
    promoteUrl: Yup.string()
      .trim()
      .nullable()
      .test('urlTest', t('Not a valid URL'), (val) => {
        let value = val
        if (val) value = val.indexOf('http') < 0 ? `https://${val}` : val

        return !val || isUrl(value)
      })
  })
}

export default BrandEdit
