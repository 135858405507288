import React from 'react'

import { Box } from 'fwego'
import { useTranslation } from 'react-i18next'

import { PieChartCard, CardHeader } from '.'
import { fillUpData } from '../utils/stats'

type IProps = {
  data?: any[]
  loading?: boolean
}

const PresentationPieChartCard = ({
  data = [],
  loading
}: IProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Box height="380">
      <PieChartCard
        data={fillUpData(data, 'presentation', 0, [
          'storyblock',
          'carousel',
          'grid',
          'floating_button'
        ])}
        dataKey="presentation"
        dataTotalKey="total_views"
        titleTotal={t('Total Views')}
        padding={[0, 0, 0, 0]}
        header={
          <CardHeader
            title={t('Video Views by Story Type')}
            tooltip={t(
              'Total views for your videos that use either a carousel, a grid, storyblock or the story button. This includes other websites and apps that embed or link to videos from your Channel.'
            )}
          />
        }
        height={300}
        loading={loading}
      />
    </Box>
  )
}

export default PresentationPieChartCard
