import { localStorageProvider } from '@src/utils/storageProvider'

export const FB_URL = 'https://graph.facebook.com'

const fbClientId = '186171348661014'

const igHashtagImporterUrls = {
  production: 'https://ig-importer.firework-prod.com',
  staging: 'https://ig-importer.firework-staging.com'
}

export const IG_HASHTAG_IMPORTER_URL =
  igHashtagImporterUrls[process.env.REACT_APP_ENV] ||
  (process.env.NODE_ENV === 'production'
    ? igHashtagImporterUrls['production']
    : igHashtagImporterUrls['staging'])

export type FbToken = {
  accessToken?: string
  expiresIn?: number
  id?: string
  username?: string
}

const getFbRedirectUri = (): string => {
  const url = window.location.href
  const urlStr = url.split('/')

  const domain = urlStr[0] + '//' + urlStr[2]

  return domain + '/facebook/auth'
}

export type FbAuthState =
  | 'multi_ig_hashtag_importer'
  | 'video_upload'
  | 'livestream_trailer'
  | 'poster'
  | 'source_importer'

const openFbAuthWindow = (
  state: FbAuthState | string,
  scope = 'pages_show_list%2Cinstagram_basic%2cpages_read_engagement%2cinstagram_manage_insights%2cbusiness_management%2cinstagram_manage_comments'
): void => {
  const w = window.screen.width / 3
  const h = window.screen.height / 2
  const l = window.screen.width / 2 - w / 2
  const t = window.screen.height / 2 - h / 2

  // %2cbusiness_management
  const url =
    'https://www.facebook.com/dialog/oauth' +
    '?client_id=' +
    fbClientId +
    '&response_type=code' +
    '&scope=' +
    scope +
    '&state=' +
    state +
    '&redirect_uri=' +
    encodeURIComponent(getFbRedirectUri())

  window.open(
    url,
    'FwFacebookAuth',
    'resizable,scrollbars,width=' +
      w +
      ',height=' +
      h +
      ',left=' +
      l +
      ',top=' +
      t
  )
}

const openFbAuthWindowWithEditSetting = (state: FbAuthState | string): void => {
  openFbAuthWindow(
    state,
    'pages_show_list%2Cinstagram_basic%2cpages_read_engagement%2cinstagram_manage_insights%2cinstagram_manage_comments'
  )
}

const saveFbToken = (userId: string, fbToken: FbToken): void => {
  localStorageProvider.setItem(`fb_token_${userId}`, JSON.stringify(fbToken))
}

const getFbToken = (userId: string): FbToken => {
  return JSON.parse(localStorageProvider.getItem(`fb_token_${userId}`) || '{}')
}

const isFbTokenExpired = (fbToken: FbToken): boolean => {
  if (!fbToken || !fbToken.accessToken || !fbToken.expiresIn) {
    return true
  }
  const expiresIn = fbToken.expiresIn
  const date = new Date()

  return expiresIn <= date.getTime() + 10 * 60 * 1000
}

export {
  fbClientId,
  getFbRedirectUri,
  openFbAuthWindow,
  openFbAuthWindowWithEditSetting,
  saveFbToken,
  getFbToken,
  isFbTokenExpired
}
