import React from 'react'

import { css } from '@emotion/core'
import { Skeleton } from 'antd'
import { Box, Flex } from 'fwego'
import { useTranslation } from 'react-i18next'

import { Card, BarChartCard, CardHeader } from '.'
import { CHART_COLORS } from '../styles/theme'

type IProps = {
  loading: boolean
  data: globalLib.PageViewInsight[]
  title: string
  dataKey: string
  headerExtra: JSX.Element
}
const PageViewCard = ({
  loading,
  title,
  data = [],
  dataKey,
  headerExtra
}: IProps): JSX.Element => {
  const { t } = useTranslation()
  const chartData = data.map((d) => ({
    date: d.date,
    page_views: d.page_view_counts[dataKey]
  }))
  const total = data.reduce((store, current) => {
    return store + current.page_view_counts[dataKey]
  }, 0)

  return (
    <Card
      height="100%"
      m="0"
      css={css`
        .ant-empty-normal {
          margin: 0;
          margin-bottom: 10px;
        }
      `}
    >
      <Skeleton loading={loading}>
        <CardHeader
          title={title}
          tooltip={t(
            'A page view is an instance of any page on your site, with a Firework story, that gets loaded in the browser. Page views are shown for each channel and then rolled up to the business.'
          )}
          extra={headerExtra}
        />
        <Flex height="380">
          <Flex
            width="100%"
            height="100%"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Flex
              fontSize={['30', '30', '60']}
              color="#3CC90B"
              fontWeight="bold"
              width="100%"
              justifyContent="center"
              mt={50}
            >
              {total.toLocaleString()}
            </Flex>
            <Box>
              <BarChartCard
                data={chartData}
                dataIndexBy="date"
                dataKey="page_views"
                padding={[0, 5, 0, 10]}
                height={200}
                type="AreaLine"
                range={[0, 1]}
                cardPadding="0"
                showXAxis={false}
                showYAxis={false}
                barColor={CHART_COLORS[1]}
              />
            </Box>
          </Flex>
        </Flex>
      </Skeleton>
    </Card>
  )
}

export default PageViewCard
