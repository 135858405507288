import React from 'react'

import { Box, Heading } from 'fwego'
import { sumBy } from 'lodash'
import { useTranslation } from 'react-i18next'

import { CardHeader, BarChartCard, LastXDaysStat } from '.'
import { CHART_COLORS } from '../styles/theme'
import { minutesToHours } from '../utils/format'
import StackedBarChartCard from './StackedBarChartCard'

type IProps = {
  barColor?: string
  data?: any
  dataIndexBy?: string
  dataKey?: string
  headerExtra?: any
  loading?: boolean
  headerTooltip?: string
}

const WatchTimeInsightsCard = ({
  barColor = CHART_COLORS[0],
  data = {},
  dataIndexBy = 'date',
  dataKey = 'total_minutes_watched',
  headerExtra,
  loading,
  headerTooltip
}: IProps): JSX.Element => {
  const { t } = useTranslation()
  // Calculate total
  let total
  if (data?.showFWNDashboard === true) {
    total = sumBy(data?.ooInsights.concat(data?.fwnInsights), dataKey)
  } else {
    total = sumBy(data?.ooInsights, dataKey)
  }

  total = minutesToHours(total)

  const header = (
    <Box>
      <CardHeader
        extra={headerExtra}
        title={t('Total Watch Time')}
        tooltip={headerTooltip}
      />
      <Heading level="2">{total}</Heading>
    </Box>
  )

  let footer
  let chart
  if (data?.showFWNDashboard) {
    const mergedData = data?.ooInsights.concat(data?.fwnInsights)
    footer = (
      <LastXDaysStat
        data={mergedData}
        dataKey={dataKey}
        numDays={7}
        dataType="minutes"
      />
    )
    chart = (
      <StackedBarChartCard
        data={mergedData}
        dataKey="source"
        fieldX={dataIndexBy}
        fieldY={dataKey}
        header={header}
        footer={footer}
        height={235}
        loading={loading}
        tooltipFormatter={minutesToHours}
      />
    )
  } else {
    footer = (
      <LastXDaysStat data={data?.ooInsights} dataKey={dataKey} numDays={7} />
    )
    chart = (
      <BarChartCard
        barColor={barColor}
        data={data?.ooInsights}
        dataIndexBy={dataIndexBy}
        dataKey={dataKey}
        footer={footer}
        header={header}
        height={220}
        loading={loading}
      />
    )
  }

  return <Box height="420">{chart}</Box>
}

export default WatchTimeInsightsCard
