import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { css } from '@emotion/react'
import { useAppSelector } from '@src/app/hooks'
import { Box, Flex } from '@src/components/EmotionLayout'
import {
  completeReview,
  createFeedback,
  deleteFeedback,
  fetchOriginalMessagesByAssistanceId,
  listReviews,
  revokeReview,
  setFeedbackResolution,
  updateFeedback
} from '@src/redux/avaMessageAudit'
import { fetchAvaVideoResources } from '@src/redux/avaVideoResource'
import { validateResponseSuccess } from '@src/utils/api'
import {
  Avatar,
  Button,
  Collapse,
  Divider,
  Empty,
  Input,
  Modal,
  Spin,
  Typography,
  message as antdMessage
} from 'antd'
import { startCase, throttle, upperCase } from 'lodash'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom'

import { useHotkey } from '../hooks/useHotkey'
import {
  MessageAuditResolutionStatus,
  MessageAuditReviewStatus,
  reviewStatusMapping
} from './MessageAudit/MessageAuditStatus'

export interface AssistanceMessagesProps {
  assistance: Assistance
  businessId: string
  hightlightFeedback?: MessageFeedback
  resolutionReview?: boolean //Only hightlightFeedback has value
}

interface OriginalMessageViewModel extends OriginalMessage {
  video?: {
    file_url: string
  }
}

const FeedbackModal = ({
  open,
  onClose,
  onRefresh,
  feedback
}: {
  open: boolean
  onClose: () => void
  onRefresh: () => void
  feedback?: MessageFeedback
}) => {
  const { domainAssistantId, businessId } = useParams()
  const [comment, setComment] = useState('')
  const [reviewStatus, setReviewStatus] = useState<ReviewStatus>()
  const { t } = useTranslation()
  const feedbackId = feedback?.id
  const feedbackMessageId = feedback?.message_id
  const feedbackStatus = feedback?.feedback
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setReviewStatus(feedbackStatus)
  }, [feedbackStatus])

  const onSubmit = useCallback(async () => {
    if (!reviewStatus) {
      antdMessage.error(t('Please select a review status'))

      return
    }
    setLoading(true)
    try {
      if (feedbackId) {
        await updateFeedback(
          businessId,
          domainAssistantId,
          feedbackId,
          reviewStatus,
          comment
        )
      } else {
        // create
        await createFeedback(
          businessId,
          domainAssistantId,
          feedbackMessageId,
          'user',
          reviewStatus,
          comment
        )
      }
      antdMessage.success(t('Feedback saved'))
      setComment('')
      onRefresh()
      onClose()
    } finally {
      setLoading(false)
    }
  }, [
    onClose,
    reviewStatus,
    comment,
    onRefresh,
    t,
    feedbackId,
    feedbackMessageId,
    businessId,
    domainAssistantId
  ])

  return (
    <Modal
      open={open}
      okText={t('Save')}
      destroyOnClose
      onCancel={onClose}
      width={900}
      onOk={onSubmit}
      confirmLoading={loading}
    >
      <Flex flexDirection="column" style={{ marginTop: 20, padding: 40 }}>
        <Typography.Title style={{ fontSize: 24 }}>
          {t('Add Feedback')}
        </Typography.Title>
        <MessageAuditReviewStatus
          reviewStatus={reviewStatus}
          onClick={(key) => {
            setReviewStatus(key)
          }}
        />
        <span style={{ marginTop: 16, marginBottom: 14 }}>{t('Comments')}</span>
        <Input.TextArea
          maxLength={10000}
          rows={7}
          style={{
            resize: 'none'
          }}
          defaultValue={feedback?.comment}
          showCount
          onChange={(e) => {
            setComment(e.target.value)
          }}
        />
      </Flex>
    </Modal>
  )
}

const VisitorMessage = ({
  message,
  assistanceId
}: {
  message: OriginalMessage
  assistanceId: string
}) => {
  const { t } = useTranslation()

  return (
    <Flex
      flexDirection="column"
      css={css`
        padding: 14px 18px;
      `}
    >
      <span style={{ color: '#666c7e' }}>
        <span style={{ fontSize: 16, fontWeight: 600, marginRight: 11 }}>
          {t('Customer')}
        </span>
        <span>{moment(message.created_at).format('MMM D, YYYY h:mm A')}</span>
      </span>
      <span
        css={css`
          margin-top: 16px;
          align-items: flex-start;
          border-radius: 14px 18px;
          align-self: flex-start;
        `}
      >
        {message.text}
      </span>
      <Flex style={{ marginTop: 12 }}>
        <CopyAndShare msgId={message.id} assistanceId={assistanceId} />
      </Flex>
    </Flex>
  )
}

const CopyAndShare = ({
  msgId,
  assistanceId
}: {
  msgId: string
  assistanceId: string
}) => {
  const { domainAssistantId, businessId } = useParams()
  const { t } = useTranslation()

  return (
    <Flex flexDirection="row">
      <Box
        as="img"
        height={22}
        width={22}
        css={css`
          background-color: #6b6e73;
          -webkit-mask: url(/ava/icon_copy.svg) no-repeat center;
          mask: url(/ava/icon_copy.svg) no-repeat center;
        `}
        style={{ cursor: 'pointer' }}
        onClick={() => {
          window.navigator.clipboard.writeText(msgId)
          antdMessage.success(t('Message ID Copied'))
        }}
      />
      <Box
        as="img"
        height={22}
        width={22}
        css={css`
          background-color: #6b6e73;
          -webkit-mask: url(/ava/icon_share.svg) no-repeat center;
          mask: url(/ava/icon_share.svg) no-repeat center;
        `}
        style={{ cursor: 'pointer' }}
        onClick={() => {
          navigator.clipboard.writeText(
            `${window.location.protocol}//${window.location.host}/business/${businessId}/ava/${domainAssistantId}/conversations?id=${assistanceId}&messageId=${msgId}`
          )
          antdMessage.success(t('Message URL Copied'))
        }}
      />
    </Flex>
  )
}

const AvaMessage = ({
  message,
  assistanceId,
  onRefresh,
  onFeedbackClick,
  hightlightMessageId,
  hightlightFeedbackId,
  resolutionReview
}: {
  message: OriginalMessageViewModel
  assistanceId: string
  hightlightMessageId?: string
  hightlightFeedbackId?: string
  resolutionReview?: boolean
  onRefresh: () => void
  onFeedbackClick: (feedback: MessageFeedback) => void
}) => {
  const { domainAssistantId, businessId } = useParams()
  const [preview, setPreview] = useState(false)
  const { t } = useTranslation()

  const onDeleteFeedback = useCallback(
    async (feedback: MessageFeedback) => {
      await deleteFeedback(businessId, domainAssistantId, feedback.id)
      antdMessage.success(t('Feedback deleted'))
      onRefresh()
    },
    [onRefresh, domainAssistantId, businessId, t]
  )

  return (
    <Flex
      flexDirection="column"
      css={css`
        padding: 14px 18px;
      `}
    >
      <span style={{ color: '#666c7e' }}>
        <span style={{ fontSize: 16, fontWeight: 600, marginRight: 11 }}>
          AVA
        </span>
        <span>{moment(message.created_at).format('MMM D, YYYY h:mm A')}</span>
      </span>
      <span
        css={css`
          display: flex;
          flex-direction: column;
          margin-top: 16px;
          align-items: flex-start;
          gap: 12px;
          color: #2b2b2b;
        `}
      >
        {message.text}
        {message.video && (
          // show thumbnaile of the video
          <div
            style={{ position: 'relative' }}
            css={css`
              &:hover {
                filter: brightness(0.5);
              }
              cursor: pointer;
            `}
            onClick={() => setPreview(true)}
          >
            <video
              width={140}
              src={message.video.file_url}
              style={{ borderRadius: '8px' }}
            />
            <Box
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)'
              }}
              as="img"
              src="/play_white_icon.png"
              width="60"
              height="60"
            />
          </div>
        )}
      </span>
      {preview && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            width: '100%',
            height: '100%',
            zIndex: 99
          }}
          onClick={() => setPreview(false)}
        >
          <video
            controls
            autoPlay
            style={{
              position: 'absolute',
              maxWidth: '80%',
              maxHeight: '80%',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)'
            }}
            src={message.video?.file_url}
          />
        </div>
      )}
      <Flex
        flexDirection="row"
        style={{ marginTop: 14 }}
        justifyContent={resolutionReview ? 'flex-end' : 'space-between'}
      >
        {!resolutionReview && (
          <Flex flexDirection="row" alignItems="center">
            <Box
              as="img"
              src="/ava/thumb_up.svg"
              height={20}
              width={20}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                const newfeedback = {
                  message_id: message.id,
                  feedback: 'correct_verified',
                  comment: ''
                } as MessageFeedback
                onFeedbackClick(newfeedback)
              }}
            />
            <Box
              as="img"
              src="/ava/thumb_down.svg"
              height={20}
              width={20}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                const newfeedback = {
                  message_id: message.id,
                  feedback: 'incorrect_mismatched',
                  comment: ''
                } as MessageFeedback
                onFeedbackClick(newfeedback)
              }}
            />
            <Box
              as="img"
              style={{ cursor: 'pointer' }}
              src="/ava/unsure.svg"
              height={20}
              width={20}
              onClick={() => {
                const newfeedback = {
                  message_id: message.id,
                  feedback: 'unknown_lacking',
                  comment: ''
                } as MessageFeedback
                onFeedbackClick(newfeedback)
              }}
            />
            <Box
              as="img"
              style={{ cursor: 'pointer' }}
              src="/ava/icon_feedback.svg"
              height={20}
              width={20}
              onClick={() => {
                const newfeedback = {
                  message_id: message.id
                }
                onFeedbackClick(newfeedback)
              }}
            />
          </Flex>
        )}
        <CopyAndShare msgId={message.id} assistanceId={assistanceId} />
      </Flex>
      {message.message_feedbacks?.length > 0 && (
        <>
          <Divider style={{ margin: '12px 0' }} />
          <Collapse
            accordion
            defaultActiveKey={hightlightMessageId}
            css={css`
              background: none !important;
              border: none;
              .ant-collapse-item {
                border: none;
              }
              .ant-collapse-header {
                padding: 0 !important;
              }
              .ant-collapse-content {
                border: none;
                background: none;
              }
              .ant-collapse-content-box {
                padding: 0;
              }
            `}
          >
            <Collapse.Panel header={t('Feedback')} key={message.id}>
              {message.message_feedbacks?.map((feedback) => (
                <Flex
                  key={feedback.id}
                  style={{
                    background: '#fff',
                    opacity:
                      !hightlightFeedbackId ||
                      hightlightFeedbackId === feedback.id
                        ? 1
                        : 0.6,
                    padding: 14,
                    margin: '14px 0 0 0',
                    border:
                      hightlightFeedbackId === feedback.id
                        ? '2px solid #408FFF'
                        : '1px solid #E1E4E8',
                    borderRadius: 6
                  }}
                  flexDirection="column"
                >
                  <Flex
                    flexDirection="row"
                    justifyContent={
                      resolutionReview ? 'flex-start' : 'space-between'
                    }
                  >
                    <span>
                      {startCase(feedback.created_by)} {feedback.user_id}
                      <span
                        style={{
                          color: '#fff',
                          padding: '1px 7px',
                          marginLeft: 11,
                          background:
                            reviewStatusMapping[feedback.feedback]
                              .highlightColor,
                          borderRadius: 7
                        }}
                      >
                        {reviewStatusMapping[feedback.feedback].text}
                      </span>
                    </span>
                    {!resolutionReview && (
                      <span>
                        <EditOutlined
                          style={{ marginLeft: 6 }}
                          onClick={() => {
                            onFeedbackClick(feedback)
                          }}
                        />
                        <DeleteOutlined
                          style={{ marginLeft: 6 }}
                          onClick={() => {
                            onDeleteFeedback(feedback)
                          }}
                        />
                      </span>
                    )}
                  </Flex>
                  {feedback.comment && (
                    <span style={{ marginTop: 12 }}>{feedback.comment}</span>
                  )}
                </Flex>
              ))}
            </Collapse.Panel>
          </Collapse>
        </>
      )}
    </Flex>
  )
}

const HighlightAnimationContainer = ({
  children,
  refProp,
  bgColor,
  highlight
}: {
  children: React.ReactNode
  refProp?: React.LegacyRef<HTMLDivElement>
  bgColor: string
  highlight?: boolean
}) => {
  return (
    <div
      ref={refProp}
      css={
        highlight
          ? css`
              animation: highlight 2s ease-out 2 forwards;
              animation-delay: 1200ms;
              border-radius: 16px;
              @keyframes highlight {
                0% {
                  background-color: #99ccff;
                }
                100% {
                  background-color: ${bgColor};
                }
              }
            `
          : css`
              background-color: ${bgColor};
              border-radius: 16px;
            `
      }
    >
      {children}
    </div>
  )
}

export const AssistanceMessages: React.FC<AssistanceMessagesProps> = ({
  assistance,
  businessId,
  hightlightFeedback,
  resolutionReview = false
}) => {
  const { domainAssistantId } = useParams()
  const [searchParams] = useSearchParams()
  const [messages, setMessages] = useState<OriginalMessageViewModel[]>(null)
  const [loading, setLoading] = useState(false)
  const [reviewAvatars, setReviewAvatars] = useState<string[]>([])
  const [currentUserReviewId, setCurrentUserReviewId] = useState<string>()
  const [currentFeedback, setCurrentFeedback] = useState<MessageFeedback>(
    undefined
  )
  const user = useAppSelector((state) => state.profile.user)
  const { t } = useTranslation()

  const highlightMsgId =
    searchParams.get('messageId') ?? hightlightFeedback?.message_id

  const [scrollToId, setScrollToId] = useState<string | null>(null)

  const selectedFeedback = useMemo(
    () =>
      messages
        ?.find((message) => message.id === hightlightFeedback?.message_id)
        ?.message_feedbacks.find(
          (feedback) => feedback.id === hightlightFeedback?.id
        ),
    [hightlightFeedback, messages]
  )

  useEffect(() => {
    if (highlightMsgId) {
      setScrollToId(highlightMsgId)
    }
  }, [highlightMsgId])

  const hotKeyResolutionStatusMapping = useMemo(() => {
    return {
      Q: 'pending',
      W: 'resolved',
      E: 'wont_fix',
      R: 'customer_action',
      T: 'investigation'
    }
  }, [])

  const onRefreshMessageFeedbacks = useCallback(async () => {
    const originalMessages = await fetchOriginalMessagesByAssistanceId(
      businessId,
      assistance.id,
      undefined,
      100
    )
    if (!validateResponseSuccess(originalMessages)) {
      return
    }
    const newmessages = originalMessages.data
      .messages as OriginalMessageViewModel[]
    // replace the messages feedback with the new one
    const newMessages = messages.map((message) => {
      const newMessage = newmessages.find(
        (newmessage) => newmessage.id === message.id
      )
      if (newMessage) {
        message.message_feedbacks = newMessage.message_feedbacks
      }

      return message
    })
    setMessages(newMessages)
  }, [assistance, businessId, messages])

  const onClickResolutionStatus = useCallback(
    (status) => {
      throttle(async () => {
        await setFeedbackResolution(
          businessId,
          domainAssistantId,
          selectedFeedback?.id,
          status,
          hightlightFeedback?.comment
        )
        onRefreshMessageFeedbacks()
      }, 1000)()
    },
    [
      businessId,
      domainAssistantId,
      selectedFeedback,
      hightlightFeedback,
      onRefreshMessageFeedbacks
    ]
  )

  const featchVideo = useCallback(
    async (messages: OriginalMessageViewModel[]) => {
      const response = await fetchAvaVideoResources(
        businessId,
        domainAssistantId,
        undefined,
        100
      )
      if (!validateResponseSuccess(response)) {
        antdMessage.error('Failed to fetch video resources')

        return
      }

      const videos = response.data.entries as AvaVideoResource[]
      messages.forEach((message) => {
        const video = videos.find(
          (video) => video.id === message.video_resource_id
        )
        if (video) {
          message.video = {
            file_url: video.url
          }
        }
      })
    },
    [domainAssistantId, businessId]
  )

  const fetchReviews = useCallback(async () => {
    const reviews = await listReviews(businessId, assistance.id)
    if (!validateResponseSuccess(reviews)) {
      return
    }
    setReviewAvatars(
      reviews.data.reviews.map((review) => review.user.avatar_url)
    )
    setCurrentUserReviewId(
      reviews.data.reviews.find(
        (review) => review.user.encoded_id === user.encoded_id
      )?.id
    )
  }, [assistance, businessId, user])

  useEffect(() => {
    setLoading(true)
    const loadData = async () => {
      try {
        setReviewAvatars([])
        const originalMessages = await fetchOriginalMessagesByAssistanceId(
          businessId,
          assistance.id,
          undefined,
          100
        )
        if (!validateResponseSuccess(originalMessages)) {
          return
        }
        const messages = originalMessages.data
          .messages as OriginalMessageViewModel[]
        if (messages.filter((message) => message.visitor_id).length > 0) {
          await featchVideo(messages)
        }
        setMessages(messages)
        fetchReviews()
        setLoading(false)
      } catch (e) {
        setLoading(false)
      } finally {
        setLoading(false)
      }
    }
    loadData()
  }, [assistance, businessId, featchVideo, fetchReviews])

  const onFeedbackClick = useCallback((feedback: MessageFeedback) => {
    setCurrentFeedback(feedback)
  }, [])

  const createReview = useCallback(async () => {
    await completeReview(businessId, assistance.id)
    fetchReviews()
    antdMessage.success(t('Review completed'))
  }, [businessId, assistance.id, t, fetchReviews])

  const revokeCurrentReview = useCallback(async () => {
    await revokeReview(businessId, assistance.id, currentUserReviewId)
    fetchReviews()
    antdMessage.success(t('Review revoked'))
  }, [businessId, assistance.id, t, fetchReviews, currentUserReviewId])

  useHotkey((event) => {
    if (event.ctrlKey || event.metaKey || event.altKey || event.shiftKey) return

    if (
      upperCase(event.key) in hotKeyResolutionStatusMapping &&
      resolutionReview
    ) {
      event.preventDefault()
      onClickResolutionStatus(
        hotKeyResolutionStatusMapping[upperCase(event.key)]
      )
    } else if (upperCase(event.key) === 'C') {
      event.preventDefault()
      if (currentUserReviewId) {
        revokeCurrentReview()
      } else {
        createReview()
      }
    }
  })

  const scrollTo = (ref) => {
    if (ref && scrollToId) {
      setTimeout(() => {
        ref.scrollIntoView({ behavior: 'smooth', block: 'center' })
        setScrollToId(null)
      }, 1000)
    }
  }

  return (
    <Flex flex="1" flexDirection="column">
      <Flex
        style={{
          borderBottom: '1px solid #EAEAEA',
          display: 'flex',
          color: '#9B9B9B',
          flexDirection: 'row',
          padding: '17px 18px',
          height: 68,
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Flex
          flexDirection="row"
          style={{
            gap: 14,
            alignItems: 'center'
          }}
        >
          {assistance.id}
          <Box
            as="img"
            src="/ava/icon_copy.svg"
            style={{ cursor: 'pointer' }}
            width={20}
            height={20}
            onClick={() => {
              navigator.clipboard.writeText(assistance.id)
              antdMessage.success(t('ID Copied'))
            }}
          />
          <Box
            as="img"
            src="/ava/icon_share.svg"
            style={{ cursor: 'pointer' }}
            width={20}
            height={20}
            onClick={() => {
              navigator.clipboard.writeText(
                `${window.location.protocol}//${window.location.host}/business/${businessId}/ava/${domainAssistantId}/conversations?id=${assistance.id}`
              )
              antdMessage.success(t('URL Copied'))
            }}
          />

          <Flex
            style={{ fontSize: 12, alignItems: 'center', height: 20 }}
            flexDirection="row"
          >
            <span>{messages?.length ?? 0}</span>
            <Box
              style={{ marginLeft: 2 }}
              as="img"
              src="/ava/icon_msg.svg"
              width={12}
              height={12}
            />
          </Flex>
          <span>
            {moment(assistance.ended_at).format('MMM D, YYYY h:mm A')}
          </span>
        </Flex>
        {!resolutionReview && (
          <Flex flexDirection="row" gap={12}>
            {reviewAvatars.length > 0 && (
              <Flex
                flexDirection="row"
                style={{
                  gap: 8,
                  alignItems: 'center'
                }}
              >
                {reviewAvatars.map((avatar, index) => (
                  <Avatar
                    key={index}
                    src={avatar}
                    css={css`
                      border-radius: 30px;
                      border: 1px solid #f0f2f5;
                    `}
                    style={{
                      width: 32,
                      height: 32,
                      marginLeft: index === 0 ? undefined : -16
                    }}
                  />
                ))}
              </Flex>
            )}
            <Button
              disabled={loading}
              onClick={() => {
                if (currentUserReviewId) {
                  revokeCurrentReview()
                } else {
                  createReview()
                }
              }}
            >
              {currentUserReviewId
                ? t('Revoke Review(C)')
                : t('Complete Review(C)')}
            </Button>
          </Flex>
        )}
      </Flex>
      <Flex
        flexDirection="column"
        py={24}
        px={30}
        style={{
          flex: 1,
          overflow: 'auto'
        }}
      >
        {loading || !messages ? (
          <Flex justifyContent="center" alignItems="center" flex={1}>
            <Spin />
          </Flex>
        ) : messages.length > 0 ? (
          <Flex flexDirection="row" gap={30}>
            <Flex flexDirection="column" flex={1}>
              {resolutionReview && (
                <Typography.Title style={{ fontSize: 18 }}>
                  {t('Feedback')}
                </Typography.Title>
              )}
              {messages.map((message) =>
                message.visitor_id ? (
                  <HighlightAnimationContainer
                    key={message.id}
                    highlight={message.id === highlightMsgId}
                    refProp={
                      message.id === highlightMsgId ? scrollTo : undefined
                    }
                    bgColor="white"
                  >
                    <VisitorMessage
                      message={message}
                      assistanceId={assistance.id}
                    />
                  </HighlightAnimationContainer>
                ) : (
                  <HighlightAnimationContainer
                    key={message.id}
                    highlight={message.id === highlightMsgId}
                    refProp={
                      message.id === highlightMsgId ? scrollTo : undefined
                    }
                    bgColor="#f4f7fa"
                  >
                    <AvaMessage
                      message={message}
                      assistanceId={assistance.id}
                      onRefresh={onRefreshMessageFeedbacks}
                      onFeedbackClick={onFeedbackClick}
                      hightlightMessageId={selectedFeedback?.message_id}
                      hightlightFeedbackId={selectedFeedback?.id}
                      resolutionReview={resolutionReview}
                    />
                  </HighlightAnimationContainer>
                )
              )}
            </Flex>

            {resolutionReview && (
              <>
                <Divider type="vertical" style={{ height: '100%' }} />
                <Flex
                  flexDirection="column"
                  flex="1 1 0"
                  gap={24}
                  css={css`
                    .ant-tag {
                      padding: 7px 14px;
                    }
                  `}
                >
                  <Typography.Title style={{ fontSize: 18 }}>
                    {t('Resolution Status')}
                  </Typography.Title>
                  <MessageAuditResolutionStatus
                    resolutionStatus={selectedFeedback?.resolution}
                    onClick={onClickResolutionStatus}
                  />
                </Flex>
              </>
            )}
          </Flex>
        ) : (
          <Flex
            style={{
              width: '100%',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Empty />
          </Flex>
        )}
        <FeedbackModal
          onRefresh={onRefreshMessageFeedbacks}
          open={!!currentFeedback}
          feedback={currentFeedback}
          onClose={() => {
            setCurrentFeedback(null)
          }}
        />
      </Flex>
    </Flex>
  )
}
