import React, { useState } from 'react'

import { QuestionCircleOutlined } from '@ant-design/icons'
import { css } from '@emotion/core'
import { Box, Flex } from '@src/components/EmotionLayout'
import FontSelector from '@src/components/Settings/FontSelector'
import { useNabooFeatureFlag } from '@src/hooks/useFeatureFlag'
import { isLgScreen } from '@src/hooks/useMediaQuery'
import { updateChannel } from '@src/redux/channel'
import theme from '@src/styles/theme'
import { validateResponseSuccess } from '@src/utils/api'
import { isUrl } from '@src/utils/url'
import { Typography, Radio, Space, Tooltip, notification, Select } from 'antd'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'

import { FWButton, Label } from '..'
import CardDisplay from './CardDisplay'
import ColorDisplay from './ColorDisplay'
import CornerDisplay from './CornerDisplay'

interface Props {
  config: globalLib.Channel['config']
  closeModal?: () => void
}

type Inputs = {
  ui_button_color: string
  ui_button_font_color: string
  ui_border_style: string
  ui_theme: string
  ui_font: string
  ui_full_cta: null | boolean
}

const AppearanceEdit: React.FC<Props> = ({
  config,
  closeModal
}): JSX.Element => {
  const { businessId, channelId } = useParams()
  const { t } = useTranslation()
  const inline = isLgScreen()

  const { Text } = Typography
  const dispatch = useDispatch()
  const nff = useNabooFeatureFlag()
  const { handleSubmit, control, watch, setValue } = useForm<Inputs>()
  const withFont = nff.cms_branding_settings_font
  const withTheme = nff.cms_branding_settings_theme
  const [selectedButtonColor, setSelectedButtonColor] = useState(
    config?.ui_button_color || '#050505'
  )

  const buttonColor = watch('ui_button_color', config?.ui_button_color || null)
  const buttonFontColor = watch(
    'ui_button_font_color',
    config?.ui_button_font_color || null
  )
  const borderStyle = watch('ui_border_style', config?.ui_border_style || null)
  const fontFamily = watch('ui_font', config?.ui_font || null)
  const ctaSize = watch('ui_full_cta', config?.ui_full_cta)
  const isFullSize = typeof ctaSize !== 'boolean'

  const onSubmit = async (data) => {
    // if data.ui_button_color does not start with # then add # to the beginning
    if (data.ui_button_color) {
      if (!data.ui_button_color.startsWith('#')) {
        data.ui_button_color = `#${data.ui_button_color}`
      }

      // data.ui_button_color must be a valid hex color
      if (!/^#[0-9A-F]{6}$/i.test(data.ui_button_color)) {
        notification.error({
          message: t('Invalid Color'),
          description: t('Please enter a valid hex color.')
        })

        return
      }
    }

    //Validate data.live_stream_terms_and_conditions is a url using a regex
    if (data.live_stream_terms_and_conditions) {
      const termsAndConditionsIsUrl = isUrl(
        data.live_stream_terms_and_conditions
      )
      if (!termsAndConditionsIsUrl) {
        notification.error({
          message: t('Invalid Terms and Conditions URL'),
          description: t('Please enter a valid URL.')
        })

        return
      }
    }

    const response = await dispatch(updateChannel(businessId, channelId, data))
    if (validateResponseSuccess(response)) {
      notification.success({
        message: t('Settings updated!')
      })
    } else {
      notification.error({
        message: t('Error updating settings.')
      })
    }
    closeModal()
  }

  const bgDefault = theme.primary

  return (
    <form id="hook-form" onSubmit={handleSubmit(onSubmit)}>
      <Flex justifyContent="space-between" flexDirection={!inline && 'column'}>
        <Box mb="medium">
          {withTheme && (
            <Box mb="medium">
              <Label>
                {t('Theme')}&nbsp;
                <Tooltip
                  trigger={['hover', 'click']}
                  placement="bottom"
                  title={t(
                    'Dark and Light themes will change how your overall Brand site will appear to your viewers.'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </Label>
              <Controller
                name="ui_theme"
                control={control}
                defaultValue={config?.ui_theme || null}
                render={({ field }) => (
                  <Select
                    placeholder="Select a Font"
                    defaultValue={null}
                    css={css`
                      width: 100%;
                    `}
                    {...field}
                  >
                    <Select.Option value={null}>{t('Dark')}</Select.Option>
                    <Select.Option value="light">{t('Light')}</Select.Option>
                  </Select>
                )}
              />
            </Box>
          )}
          {withFont && (
            <Box mb="medium">
              <Label>{t('Font style')}</Label>
              <Controller
                name="ui_font"
                control={control}
                defaultValue={config?.ui_font || null}
                render={({ field }) => <FontSelector {...field} />}
              />
            </Box>
          )}
          <Flex mb="medium">
            <Box>
              <Label>{t('Primary color')}</Label>
              <Controller
                name="ui_button_color"
                control={control}
                defaultValue={config?.ui_button_color || null}
                render={({ field }) => (
                  <Radio.Group
                    css={css`
                      .ant-radio {
                        top: -0.25em;
                      }
                    `}
                    {...field}
                  >
                    <Radio value={null}>
                      <Flex>
                        <Box
                          height="25px"
                          width="25px"
                          mr="10px"
                          borderRadius={4}
                          bg={bgDefault}
                        />
                        <Text>{t('Default')}</Text>
                      </Flex>
                    </Radio>
                    <Box mb="xxsmall" />
                    <Radio value={selectedButtonColor}>
                      <ColorDisplay
                        selectedColor={selectedButtonColor}
                        setSelectedColor={(hex) => {
                          setSelectedButtonColor(hex)
                          setValue('ui_button_color', hex)
                        }}
                        hasPicker
                      />
                    </Radio>
                  </Radio.Group>
                )}
              />
            </Box>
            <Box>
              <Label>{t('Button font color')}</Label>
              <Controller
                name="ui_button_font_color"
                control={control}
                defaultValue={config?.ui_button_font_color || null}
                render={({ field }) => (
                  <Radio.Group
                    css={css`
                      .ant-radio {
                        top: -0.25em;
                      }
                    `}
                    {...field}
                  >
                    <Radio value={null}>
                      <ColorDisplay
                        selectedColor="#FFFFFF"
                        label={t('White')}
                      />
                    </Radio>
                    <Box mb="xxsmall" />
                    <Radio value="#404040">
                      <ColorDisplay
                        selectedColor="#404040"
                        label={t('Black')}
                      />
                    </Radio>
                  </Radio.Group>
                )}
              />
            </Box>
          </Flex>
          <Label>
            {t('Border corner style')}&nbsp;
            <Tooltip
              trigger={['hover', 'click']}
              placement="top"
              title={t(
                'This will affect all borders on embed widgets and microsites.'
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </Label>
          <Controller
            name="ui_border_style"
            control={control}
            defaultValue={config?.ui_border_style || null}
            render={({ field }) => (
              <Radio.Group {...field}>
                <Space direction="horizontal">
                  <Radio
                    value={null}
                    css={css`
                      text-align: center;
                    `}
                  >
                    <CornerDisplay />
                    <Text>{t('Round')}</Text>
                  </Radio>
                  <Radio
                    value="hard"
                    css={css`
                      text-align: center;
                    `}
                  >
                    <CornerDisplay hard />
                    <Text>{t('Hard')}</Text>
                  </Radio>
                </Space>
              </Radio.Group>
            )}
          />
        </Box>
        <CardDisplay
          buttonFontColor={buttonFontColor}
          borderStyle={borderStyle}
          buttonColor={buttonColor}
          fontFamily={fontFamily}
          label={t('Your Button')}
          isFullSize={isFullSize}
        >
          <>
            <Label>{t('CTA size')}</Label>
            <Controller
              name="ui_full_cta"
              control={control}
              defaultValue={config?.ui_full_cta}
              render={({ field }) => (
                <Radio.Group
                  {...field}
                  size="large"
                  css={css`
                    width: 100%;
                  `}
                >
                  <Flex width="100%" maxWidth="200">
                    <Radio.Button
                      value={null}
                      css={css`
                        flex: 1;
                        text-align: center;
                      `}
                    >
                      {t('Large')}
                    </Radio.Button>
                    <Radio.Button
                      value={false}
                      css={css`
                        flex: 1;
                        text-align: center;
                      `}
                    >
                      {t('Small')}
                    </Radio.Button>
                  </Flex>
                </Radio.Group>
              )}
            />
          </>
        </CardDisplay>
      </Flex>
      <Flex position="absolute" bottom="0" right="0" margin="32px">
        <Box mr="medium" display="inline-block">
          <FWButton
            css={css`
              font-weight: 400;
            `}
            onClick={closeModal}
          >
            {t('Cancel')}
          </FWButton>
        </Box>
        <FWButton
          css={css`
            font-weight: 400;
          `}
          type="primary"
          htmlType="submit"
          form="hook-form"
        >
          {t('Save')}
        </FWButton>
      </Flex>
    </form>
  )
}

export default AppearanceEdit
