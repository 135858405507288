import React from 'react'

import { Row, Col, Skeleton } from 'antd'
import { Chart, Axis, Coord, Guide, Geom, Tooltip } from 'bizcharts'

import { Card, CardHeader, EmptyInsightsContent } from '.'

type IProps = {
  data: any[]
  dataKey?: string
  loading?: boolean
  title?: string
  tooltip?: string
}

const CategoryPieChartsCard: React.FC<IProps> = ({
  data,
  dataKey = 'total_views',
  loading,
  title,
  tooltip
}) => {
  const listTotal = (slice = -1) => {
    return data.slice(0, slice).reduce((acc, obj) => {
      return acc + obj[dataKey]
    }, 0)
  }

  const categoryPercent = (category) => {
    return `${Math.floor((100 * category[dataKey]) / listTotal(6))}%`
  }

  const shorten = (str, len) => {
    if (!str || str.length <= len + 1) return str

    return str.substr(0, len) + '…'
  }

  return (
    <Card height="100%" m="0">
      <Skeleton active loading={loading}>
        <CardHeader title={title} tooltip={tooltip} />
        {data && data.length > 0 ? (
          <Row>
            {data &&
              data.slice(0, 6).map((category, i) => (
                <Col key={`category-${i}`} span={4}>
                  <Chart
                    data={[
                      {
                        [dataKey]: category[dataKey],
                        name: category.display_name
                      },
                      {
                        [dataKey]: listTotal(6) - category[dataKey],
                        name: 'Other'
                      }
                    ]}
                    height={100}
                    width={100}
                    padding={[0, '10%', 0, '55%']}
                    forceFit
                  >
                    <Coord type="theta" radius={1.0} innerRadius={0.6} />
                    <Axis name={dataKey} />
                    <Geom
                      type="intervalStack"
                      position={dataKey}
                      color={['name', ['#49F', '#EEE']]}
                      select={false}
                      style={{ paddingRight: 0 }}
                    />
                    <Guide>
                      <Guide.Text
                        style={{
                          fontSize: '14',
                          fontWeight: '200',
                          fill: '#333'
                        }}
                        content={shorten(category.display_name, 12)}
                        position={['-100%', '40%']}
                      />
                      <Guide.Text
                        style={{
                          fontSize: '16',
                          fontWeight: '200',
                          fill: '#333'
                        }}
                        content={categoryPercent(category)}
                        position={['-100%', '65%']}
                      />
                    </Guide>
                    <Tooltip showTitle={false} />
                  </Chart>
                </Col>
              ))}
          </Row>
        ) : (
          <EmptyInsightsContent />
        )}
      </Skeleton>
    </Card>
  )
}

export default CategoryPieChartsCard
