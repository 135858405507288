import { useCallback, useEffect } from 'react'

export const useHotkey = (handleHotkey: (event: any) => void) => {
  const checkFocusedElement = useCallback(() => {
    const focusedElement = document.activeElement
    if (
      focusedElement &&
      (focusedElement.tagName === 'INPUT' ||
        focusedElement.tagName === 'TEXTAREA')
    ) {
      return true
    } else {
      return false
    }
  }, [])

  useEffect(() => {
    const handleHotkeyEvent = async (event) => {
      if (checkFocusedElement()) return

      handleHotkey(event)
    }

    document.addEventListener('keydown', handleHotkeyEvent)

    return () => {
      document.removeEventListener('keydown', handleHotkeyEvent)
    }
  }, [checkFocusedElement, handleHotkey])
}
