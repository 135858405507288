import React from 'react'

import { Flex, Box } from '@src/components/EmotionLayout'
import { usePixelAmpTracking } from '@src/hooks'
import { useAvaJs } from '@src/hooks/Ava/useAvaJs'
import { isXsScreen } from '@src/hooks/useMediaQuery'
import theme from '@src/styles/theme'
import { localStorageProvider } from '@src/utils/storageProvider'
import { TRACKING_EVENTS } from '@src/utils/tracking'
import { Typography, Divider } from 'antd'
import { useTranslation } from 'react-i18next'

import { Card, FWButton, Title } from './'

const helpCenterLinks = {
  'help-center': 'https://help.firework.com',
  'help-center-dev': 'https://docs.firework.tv/'
}

const trackingEvents = {
  'help-center': TRACKING_EVENTS.HELP_CENTER_EVENTS.BUSINESS_CLICK_HELP_CENTER,
  'help-center-dev':
    TRACKING_EVENTS.HELP_CENTER_EVENTS.BUSINESS_CLICK_DEVELOPER_DOCS,
  'live-chat': TRACKING_EVENTS.HELP_CENTER_EVENTS.BUSINESS_CLICK_LIVE_CHAT
}

const HelpCenter: React.FC = () => {
  const { t } = useTranslation()
  const isMobile = isXsScreen()
  const { pixelAmpTracking } = usePixelAmpTracking()
  const language = localStorageProvider.getItem('i18nextLng')

  // Inject One to One script
  React.useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://asset.fwcdn3.com/js/live-helper.js'
    script.async = true
    script.dataset.fwparam_chat_channel_id = 'Zg20d5'
    document.head.appendChild(script)
  }, [])

  const onButtonClick = (href: string, trackingEvent: string) => {
    pixelAmpTracking(trackingEvent)
    window.open(href, '_blank')
  }

  const showWidget = () => {
    pixelAmpTracking(trackingEvents['live-chat'])
    if (window._fwn && window._fwn.liveHelper) {
      // _fwn is available, use the API immediately
      window._fwn.liveHelper.actions.showWidget()
    } else {
      // Wait for fw:ready event
      document.addEventListener('fw:ready', () => {
        window._fwn.liveHelper.actions.showWidget()
      })
    }
  }

  useAvaJs()

  return (
    <Card>
      <Box>
        <Title level={2}>{t('Get Help')}</Title>
        <Typography.Text
          style={{ fontSize: 14, display: 'block', marginTop: 10 }}
        >
          {t(
            'Check out our Help Center below for an assortment of help articles for everybody.'
          )}
        </Typography.Text>
      </Box>
      <Divider />
      <Flex justifyContent="center" pt={20} flexWrap="wrap">
        <Flex
          flex="1 1 auto"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Box as="a" href={helpCenterLinks['help-center']} target="_blank">
            <Flex
              flexDirection="column"
              alignItems="center"
              justifyContent="flex-start"
              width={330}
              mt={16}
            >
              <Box
                width={82}
                height={82}
                as="img"
                src="/help_center_general.svg"
              />
              <Typography.Text
                style={{ fontSize: 18, fontWeight: 'bold', marginTop: 12 }}
              >
                {t('General Help Center')}
              </Typography.Text>
              <Typography.Text style={{ textAlign: 'center', marginTop: 12 }}>
                {t(
                  'Need help with how to improve performance or how to use specific tools?'
                )}
              </Typography.Text>
              <Typography.Text style={{ textAlign: 'center' }}>
                {t('Check out our General Help Center.')}
              </Typography.Text>
            </Flex>
          </Box>
          <Box my="small">
            <FWButton
              onClick={() =>
                onButtonClick(
                  helpCenterLinks['help-center'],
                  trackingEvents['help-center']
                )
              }
            >
              {t('Visit Help Center')}
            </FWButton>
          </Box>
        </Flex>
        <Divider
          type={isMobile ? 'horizontal' : 'vertical'}
          style={{ height: isMobile ? 'auto' : 400 }}
        />
        <Flex
          flex="1 1 auto"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Box as="a" href={helpCenterLinks['help-center-dev']} target="_blank">
            <Flex
              flexDirection="column"
              alignItems="center"
              justifyContent="flex-start"
              width={330}
              mt={16}
            >
              <Box
                width={82}
                height={82}
                as="img"
                src="/help_center_developer.svg"
              />
              <Typography.Text
                style={{ fontSize: 18, fontWeight: 'bold', marginTop: 12 }}
              >
                {t('For Developers')}
              </Typography.Text>
              <Typography.Text style={{ textAlign: 'center', marginTop: 12 }}>
                {t('Help Center made for developers.')}
              </Typography.Text>
            </Flex>
          </Box>
          <Box my="small">
            <FWButton
              onClick={() =>
                onButtonClick(
                  helpCenterLinks['help-center-dev'],
                  trackingEvents['help-center-dev']
                )
              }
            >
              {t('See Developer Docs')}
            </FWButton>
          </Box>
        </Flex>
        <Divider
          type={isMobile ? 'horizontal' : 'vertical'}
          style={{ height: isMobile ? 'auto' : 400 }}
        />
        <Flex
          flex="1 1 auto"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Box>
            <Flex
              flexDirection="column"
              alignItems="center"
              justifyContent="flex-start"
              width={330}
              mt={16}
            >
              <Box width={82} height={82} as="img" src="/help_center_ava.svg" />
              <Typography.Text
                style={{ fontSize: 18, fontWeight: 'bold', marginTop: 12 }}
              >
                {t('Ask Ava')}
              </Typography.Text>
              <Typography.Text style={{ textAlign: 'center', marginTop: 12 }}>
                {t('Chat with our digital assistant to get the help you need.')}
              </Typography.Text>
            </Flex>
          </Box>
          <Box my="small">
            <FWButton
              onClick={() =>
                window._fwn.ava.actions.showWidget({
                  domain_assistant_id: 'Zg2mNo'
                })
              }
            >
              {t('Ask AI Specialist')}
            </FWButton>
          </Box>
        </Flex>
        <Divider
          type={isMobile ? 'horizontal' : 'vertical'}
          style={{ height: isMobile ? 'auto' : 400 }}
        />
        <Flex
          flex="1 1 auto"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Box>
            <Flex
              flexDirection="column"
              alignItems="center"
              justifyContent="flex-start"
              width={330}
              mt={16}
            >
              <Box
                width={82}
                height={82}
                as="img"
                src="/message-outlined.svg"
              />
              <Typography.Text
                style={{
                  fontSize: 18,
                  fontWeight: 'bold',
                  marginTop: 12
                }}
              >
                {t('Live Chat')}
              </Typography.Text>
              <Typography.Text style={{ textAlign: 'center', marginTop: 12 }}>
                {t(
                  'Want to speak to someone directly? Our agents are here to help.'
                )}
              </Typography.Text>
            </Flex>
          </Box>
          <fw-agent-status action="startChat">
            <div slot="available">
              <Box my="small">
                <FWButton onClick={showWidget}>{t('Chat with us')}</FWButton>
              </Box>
            </div>
            <div slot="not-available">
              <Box my="small">
                <FWButton disabled title={t('Agents Offline')}>
                  {t('Agents currently offline')}
                </FWButton>
              </Box>
            </div>
          </fw-agent-status>
        </Flex>
      </Flex>
      <Divider />
      <Typography.Text>
        {t('Additionally, you can contact us at ')}
        {language === 'ja' ? (
          <a
            href="mailTo:japan_support@fireworkhq.com"
            style={{ color: theme.primary, textDecoration: 'underline' }}
            // eslint-disable-next-line i18next/no-literal-string
          >
            japan_support@fireworkhq.com
          </a>
        ) : (
          <a
            href="mailTo:slasupport@fireworkhq.com"
            style={{ color: theme.primary, textDecoration: 'underline' }}
            // eslint-disable-next-line i18next/no-literal-string
          >
            slasupport@fireworkhq.com
          </a>
        )}
      </Typography.Text>
    </Card>
  )
}

export default HelpCenter
