import { createAction, createSlice } from '@reduxjs/toolkit'
import api from '@src/utils/api'
import { Dispatch } from 'redux'

type IProps = {
  videos: Record<string, globalLib.IVideo>
  videoIds: string[]
  channels: globalLib.Channel[]
  videosPaging: globalLib.Paging | null
  channelsPaging: Record<string, string>
  isFollowingCurrentChannel: boolean
  channelsFollowedByBusinessId: Record<string, any>
}

const initialState: IProps = {
  videos: {},
  videoIds: [],
  channels: [],
  videosPaging: null,
  channelsPaging: {},
  isFollowingCurrentChannel: false,
  channelsFollowedByBusinessId: {}
}

const slice = createSlice({
  name: 'mycontent',
  initialState,
  reducers: {
    searchMyChannelsSuccess(state, action) {
      const { channels, paging, page } = action.payload

      if (!page) state.channels = []
      channels.forEach((channel) => {
        state.channels.push(channel)
      })
      state.channelsPaging = paging
    },

    createRepostSuccess(state, action) {
      const { videoId } = action.payload
      if (state.videos[videoId]) {
        state.videos[videoId].is_reposted = true
      }
    },

    getFollowersSuccess(state, action) {
      const { following, businessId } = action.payload
      state.channelsFollowedByBusinessId[businessId] = following
    },

    searchVideosSuccess(state, action) {
      const { videos, paging, page } = action.payload
      if (!page) {
        state.videos = {}
        state.videoIds = []
      }
      videos.forEach((video) => {
        state.videos[video.encoded_id] = video
        state.videoIds = [...new Set([...state.videoIds, video.encoded_id])]
      })
      state.videosPaging = paging
    },

    isFollowingChannelSuccess(state, action) {
      const { isFollowing } = action.payload
      state.isFollowingCurrentChannel = isFollowing
    },

    searchFollowersVideosSuccess(state, action) {
      const { videos, paging, page } = action.payload
      if (!page) {
        state.videos = {}
        state.videoIds = []
      }
      videos.forEach((video) => {
        state.videos[video.encoded_id] = video
        state.videoIds = [...new Set([...state.videoIds, video.encoded_id])]
      })
      state.videosPaging = paging
    }
  }
})

export default slice.reducer

export const {
  searchMyChannelsSuccess,
  createRepostSuccess,
  getFollowersSuccess,
  searchVideosSuccess,
  isFollowingChannelSuccess,
  searchFollowersVideosSuccess
} = slice.actions

const searchVideosRequest = createAction('mycontent/searchVideosRequest')
const searchVideosFailure = createAction('mycontent/searchVideosFailure')

export function searchVideos({
  businessId,
  channelId = null,
  searchObj = null,
  page = null,
  exclude_channel_id
}: {
  businessId: string
  channelId?: string | null
  searchObj?: Record<string, any> | null
  page?: string | null
  exclude_channel_id?: string
}) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(searchVideosRequest())
      let params = {
        exclude_channel_id,
        channel_id: channelId || undefined,
        sort: 'started_at_or_inserted_at_desc',
        exclude_reposts: true
      }
      if (searchObj) {
        params = {
          ...params,
          ...searchObj
        }
      }
      const response = page
        ? await api.get(page)
        : await api.get(`bus/${businessId}/videos`, {
            params
          })
      const { videos, paging } = response.data
      dispatch(searchVideosSuccess({ videos, paging, page }))
    } catch (error) {
      dispatch(searchVideosFailure())

      return error
    }
  }
}

const searchFollowersVideoRequest = createAction(
  'mycontent/searchFollowersVideoRequest'
)
const searchFollowersVideoFailure = createAction(
  'mycontent/searchFollowersVideoFailure'
)

export function searchFollowersVideos({
  searchObj = null,
  page = null
}: {
  searchObj?: Record<string, any> | null
  page?: string | null
}) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(searchFollowersVideoRequest())
      let params = {}
      if (searchObj) {
        params = {
          ...params,
          ...searchObj
        }
      }
      const response = page
        ? await api.get(page)
        : await api.get(`bus/videos?is_following=true`, {
            params
          })
      const { videos, paging } = response.data
      dispatch(searchFollowersVideosSuccess({ videos, paging, page }))
    } catch (error) {
      dispatch(searchFollowersVideoFailure())

      return error
    }
  }
}

const searchMyChannelsRequest = createAction(
  'mycontent/searchMyChannelsRequest'
)
const searchMyChannelsFailure = createAction(
  'mycontent/searchMyChannelsFailure'
)

export function searchMyChannels(
  businessId: string,
  q?: string,
  page?: string
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(searchMyChannelsRequest())
      const params = { q }
      const response = await api.get(page || `/bus/${businessId}/channels`, {
        params
      })
      const { channels, paging } = response.data
      dispatch(searchMyChannelsSuccess({ channels, paging, page }))

      return response
    } catch (error) {
      dispatch(searchMyChannelsFailure())

      return error
    }
  }
}

const repostMultiplePlaylistsRequest = createAction(
  'mycontent/repostMultiplePlaylistsRequest'
)
const repostMultiplePlaylistsFailure = createAction(
  'mycontent/repostMultiplePlaylistsFailure'
)

export function repostMultiplePlaylists(
  businessId: string,
  channelId: string,
  videoId?: string,
  playlists?: globalLib.Playlist[]
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(repostMultiplePlaylistsRequest())
      const apiRequests = playlists.map((playlist) => {
        const data = {
          video_ids: [...new Set([videoId, ...playlist.video_ids])]
        }

        return api.patch(
          `/bus/${businessId}/channels/${channelId}/playlists/${playlist.id}`,
          data
        )
      })
      const responses = await Promise.all(apiRequests)

      return responses
    } catch (error) {
      dispatch(repostMultiplePlaylistsFailure())

      return error
    }
  }
}

const repostMultipleChannelsRequest = createAction(
  'mycontent/repostMultipleChannelsRequest'
)
const repostMultipleChannelsFailure = createAction(
  'mycontent/repostMultipleChannelsFailure'
)

export function sydicationMultipleChannels(
  businessId: string,
  videoId: string,
  channels: any[],
  originalChannelId: string
) {
  return async (dispatch) => {
    try {
      dispatch(repostMultipleChannelsRequest())
      const requests = channels.map((channel) => {
        return api.post(
          `/bus/${businessId}/channels/${originalChannelId}/videos/${videoId}/copy`,
          {
            linked_channel_id: channel.encoded_id
          }
        )
      })
      const responses = await Promise.allSettled(requests)

      return responses
    } catch (error) {
      dispatch(repostMultipleChannelsFailure())

      return error
    }
  }
}

export function repostMultipleChannels(
  businessId: string,
  videoId: string,
  channels: any[]
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(repostMultipleChannelsRequest())
      const requests = channels.map((channel) => {
        return api.post(
          `/bus/${businessId}/channels/${channel.encoded_id}/reposts`,
          {
            video_id: videoId
          }
        )
      })
      const responses = await Promise.allSettled(requests)

      return responses
    } catch (error) {
      dispatch(repostMultipleChannelsFailure())

      return error
    }
  }
}

const repostVideoRequest = createAction('mycontent/repostVideoRequest')
const repostVideoFailure = createAction('mycontent/repostVideoFailure')

export function createRepost(
  businessId: string,
  channelId: string,
  videoId: string
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(repostVideoRequest())
      const response = await api.post(
        `/bus/${businessId}/channels/${channelId}/reposts`,
        { video_id: videoId }
      )
      dispatch(createRepostSuccess({ videoId }))

      return response
    } catch (error) {
      dispatch(repostVideoFailure())

      return error
    }
  }
}

const followChannelRequest = createAction('mycontent/followChannelRequest')
const followChannelFailure = createAction('mycontent/followChannelFailure')

export function followChannel(channelUserName: string) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(followChannelRequest())
      await api.post(`/bus/channels/${channelUserName}/followships`)
      dispatch(isFollowingChannelSuccess({ isFollowing: true }))
    } catch (e) {
      dispatch(followChannelFailure())
    }
  }
}

const unfollowChannelRequest = createAction('mycontent/unfollowChannelRequest')
const unfollowChannelFailure = createAction('mycontent/unfollowChannelFailure')

export function unfollowChannel(channelUserName: string) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(unfollowChannelRequest())
      await api.delete(`/bus/channels/${channelUserName}/followships`)
      dispatch(isFollowingChannelSuccess({ isFollowing: false }))
    } catch (e) {
      dispatch(unfollowChannelFailure())
    }
  }
}

const getFollowersRequest = createAction('mycontent/getFollowersRequest')
const getFollowersFailure = createAction('mycontent/getFollowersFailure')

export function getFollowers(query: string, businessId: string) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(getFollowersRequest())
      let params = {}
      if (query) params = { ...params, business_id: businessId }
      const response = await api.get('/bus/channels/followships', {
        params
      })
      const { following } = response.data
      dispatch(getFollowersSuccess({ following, businessId }))

      return response
    } catch (e) {
      dispatch(getFollowersFailure())
    }
  }
}

const isFollowingChannelRequest = createAction(
  'mycontent/isFollowingChannelRequest'
)
const isFollowingChannelFailure = createAction(
  'mycontent/isFollowingChannelFailure'
)

export function isFollowingChannel(query: string, businessId: string) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(isFollowingChannelRequest())
      let params = {}
      if (query) params = { ...params, q: query, business_id: businessId }
      const response = await api.get('/bus/channels/followships', {
        params
      })
      const { following } = response.data
      if (following.length && following[0].username === query) {
        dispatch(isFollowingChannelSuccess({ isFollowing: true }))
      } else {
        dispatch(isFollowingChannelSuccess({ isFollowing: false }))
      }

      return response
    } catch (e) {
      dispatch(isFollowingChannelFailure())
    }
  }
}
