import { createSlice, createAction } from '@reduxjs/toolkit'
import { getS3Signature, uploadFileToS3 } from '@src/utils/s3'
import { Dispatch, AnyAction } from 'redux'

import api from '../utils/api'

type IProps = {
  emailVerified: boolean | null
  primaryEmail: string
  user: Record<string, any>
  primaryEmailGroup: string
}

const initialState: IProps = {
  emailVerified: null,
  primaryEmail: '',
  user: {},
  primaryEmailGroup: ''
}

const slice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    fetchEmailsSuccess(state, action) {
      const { emails } = action.payload
      const primaryEmail = emails.filter((email) => {
        return email.primary
      })

      state.primaryEmail =
        primaryEmail.length > 0 ? primaryEmail[0].address : ''
      state.primaryEmailGroup = primaryEmail.length > 0 ? primaryEmail[0] : ''
      state.emailVerified =
        primaryEmail.length > 0 && !!primaryEmail[0].verified_at
    },
    updateProfileSuccess(state, action) {
      const { user } = action.payload
      state.user = user
    },
    fetchProfileSuccess(state, action) {
      const { user } = action.payload
      state.user = user
    }
  }
})

export default slice.reducer

export const {
  fetchEmailsSuccess,
  updateProfileSuccess,
  fetchProfileSuccess
} = slice.actions

const sendEmailVerificationRequest = createAction(
  'profile/sendEmailVerificationRequest'
)
const sendEmailVerificationFailure = createAction(
  'profile/sendEmailVerificationFailure'
)

export function sendEmailVerification(email: Record<string, any>) {
  return async (dispatch: Dispatch<AnyAction>): Promise<string | any> => {
    const { id } = email
    try {
      dispatch(sendEmailVerificationRequest())
      const response = await api.post(`/emails/${id}/verification`)

      return response
    } catch (error) {
      dispatch(sendEmailVerificationFailure())

      return error
    }
  }
}

const fetchEmailsRequest = createAction('profile/fetchEmailsRequest')
const fetchEmailsFailure = createAction('profile/fetchEmailsFailure')

export function fetchEmails() {
  return async (dispatch: Dispatch<AnyAction>): Promise<string | any> => {
    try {
      dispatch(fetchEmailsRequest())
      const response = await api.get('/api/emails')
      dispatch(fetchEmailsSuccess(response.data))

      return response
    } catch (error) {
      dispatch(fetchEmailsFailure())

      return error
    }
  }
}

const updateProfileAvatarRequest = createAction(
  'profile/updateProfileAvatarRequest'
)
const updateProfileAvatarFailure = createAction(
  'profile/updateProfileAvatarFailure'
)

export function updateProfileAvatar(
  file: File,
  onProgress: (input: any) => void
) {
  return async (dispatch: Dispatch<AnyAction>): Promise<boolean | any> => {
    try {
      await dispatch(updateProfileAvatarRequest())

      const signature = await getS3Signature(file)
      const { key } = await uploadFileToS3(file, signature, onProgress)
      if (key) {
        await dispatch(updateProfile({ avatar_key: key }) as any)

        return true
      }

      return false
    } catch (error) {
      dispatch(updateProfileAvatarFailure())

      return false
    }
  }
}

const updateProfileRequest = createAction('profile/updateProfileRequest')
const updateProfileFailure = createAction('profile/updateProfileFailure')

export function updateProfile(data: any) {
  return async (dispatch: Dispatch<AnyAction>): Promise<string | any> => {
    try {
      dispatch(updateProfileRequest())
      const response = await api.patch('/api/me', data)
      dispatch(updateProfileSuccess({ user: response.data }))

      return response
    } catch (error) {
      dispatch(updateProfileFailure())

      return error
    }
  }
}

const fetchProfileRequest = createAction('profile/fetchProfileRequest')
const fetchProfileFailure = createAction('profile/fetchProfileFailure')

export function fetchProfile() {
  return async (dispatch: Dispatch<AnyAction>): Promise<string | any> => {
    try {
      dispatch(fetchProfileRequest())
      const response = await api.get('/api/me')
      dispatch(fetchProfileSuccess({ user: response.data }))

      return response
    } catch (error) {
      dispatch(fetchProfileFailure())

      return error
    }
  }
}

const createEmailRequest = createAction('profile/createEmailSuccess')
const createEmailFailure = createAction('profile/createEmailFailure')

export function createEmail(email: string) {
  return async (dispatch: Dispatch<AnyAction>): Promise<string | any> => {
    try {
      dispatch(createEmailRequest())
      const data = {
        address: email,
        primary: true
      }
      const response = await api.post('/api/emails', data)

      return response
    } catch (error) {
      dispatch(createEmailFailure())

      return error
    }
  }
}
