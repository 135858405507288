import React, { useEffect, useState } from 'react'

import { CloudUploadOutlined } from '@ant-design/icons'
import { css } from '@emotion/core'
import { Upload, notification, message } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { uploadImage } from '../redux/product'
import theme from '../styles/theme'

interface Props {
  newImagePayload?: any
  setNewImagePayload: (any) => void
  accept?: string[]
  width?: number
  height?: number
}

const FormUploadImage: React.FC<Props> = ({
  newImagePayload,
  setNewImagePayload,
  accept = ['.jpg', '.jpeg'],
  width = 102,
  height = 102
}): JSX.Element => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [files, setFiles] = useState<any>([])

  const [newImageFile, setNewImageFile] = useState<any>(null)
  const [newImage, setNewImage] = useState<any>(null)
  const [imageSrc, setImageSrc] = useState<any>(null)

  useEffect(() => {
    if (newImagePayload && !files.length) {
      setFiles([newImagePayload])
    }
  }, [files.length, newImagePayload])

  const props = {
    name: 'file',
    accept: accept.join(','),
    fileList: files,
    multiple: false,
    beforeUpload: (file) => {
      const fileExtension = `.${file.name.split('.').pop().toLowerCase()}`
      if (!accept.includes(fileExtension)) {
        message
          .error({
            content: t('{{file}} file is in the wrong format', {
              file: file.name
            }),
            className: 'fw-notification'
          })
          .then()

        return false
      }

      const URL = window.URL || window.webkitURL
      const img = new Image()
      const uploadFile = new File([file], file.name.replace(/\s/g, '_'), {
        type: file.type
      })
      img.src = URL.createObjectURL(uploadFile)

      setImageSrc(img.src)
      setNewImageFile(uploadFile)
      setNewImage(img)

      return uploadFile
    },
    customRequest: (info) => {
      dispatch(uploadImage(info.file, info.onProgress, info.onSuccess))
    },
    onChange({ file, fileList }) {
      setFiles(fileList)
      const { status, response } = file
      if (status === 'error') {
        notification.error({
          message: t('{{file}} file upload failed', { file: file.name })
        })
      }
      if (fileList.length && response && response.key) {
        setNewImagePayload(getImagePayload(response))
      }
    },
    onRemove() {
      setNewImagePayload(null)
    },
    async onPreview(file) {
      let src = file.url || file.thumbUrl
      if (!src) {
        src = await new Promise((resolve) => {
          const reader = new FileReader()
          reader.readAsDataURL(file.originFileObj)
          reader.onload = () => resolve(reader.result)
        })
      }
      const image = new Image()
      image.src = src
      const imgWindow = window.open(src)
      imgWindow?.document.write(image.outerHTML)
    }
  }

  const getImagePayload = ({ key }) => {
    const { width, height, aspect_ratio } = getImgAttribute(newImage)
    const payload = {
      key: key,
      aspect_ratio: aspect_ratio,
      height: height,
      width: width,
      format: getFileExtension(newImageFile?.name),
      src: imageSrc
    }

    return payload
  }

  const getImgAttribute = (img) => {
    const ratios = ['9:16', '3:4', '4:5', '1:1', '5:4', '4:3', '16:9']
    const ratios2 = [0.5625, 0.75, 0.8, 1.0, 1.25, 1.3333, 1.7778]
    const r = img.width / img.height
    const idx = ratios2.reduce((a, b, index) => {
      return Math.abs(b - r) < Math.abs(ratios2[a] - r) ? index : a
    }, 0)

    return {
      width: img.width || 1,
      height: img.height || 1,
      aspect_ratio: ratios[idx] || '1:1'
    }
  }

  const getFileExtension = (filename) => {
    const formats = {
      png: 'png',
      jpg: 'jpg',
      jpeg: 'jpg',
      gif: 'gif',
      heic: 'heic',
      svg: 'svg',
      webp: 'webp'
    }
    const fileExtension = filename.split('.').pop().toLowerCase()

    return formats[fileExtension]
  }

  return (
    <Upload
      {...props}
      listType="picture-card"
      css={css`
        .ant-upload.ant-upload-select-picture-card,
        .ant-upload-list-picture-card-container {
          width: ${width}px;
          height: ${height}px;
        }

        ${width && height
          ? `.ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
                background: url(${
                  newImagePayload?.src || newImagePayload?.thumbUrl
                });
                background-size: cover;
                background-position: center center;
             }

             .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
                display: none;
             }
            `
          : ''}
      `}
    >
      {files.length ? null : (
        <CloudUploadOutlined
          style={{
            color: theme.primary,
            fontSize: '24px'
          }}
        />
      )}
    </Upload>
  )
}

export default FormUploadImage
