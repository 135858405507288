import React, { useRef, useState, useEffect } from 'react'

import { css } from '@emotion/core'
import { Box, Flex } from '@src/components/EmotionLayout'
import { Typography, Input } from 'antd'
import { SizeType } from 'antd/lib/config-provider/SizeContext'
import { ChromePicker } from 'react-color'

interface Props {
  selectedColor: string
  setSelectedColor?: (hex: string) => void
  label?: string
  top?: string
  id?: string
  hasPicker?: boolean
  size?: SizeType
  width?: string
  placeholder?: string
  pickerSize?: string | number
}

const ColorDisplay: React.FC<Props> = ({
  selectedColor,
  setSelectedColor,
  label,
  top,
  id,
  width = '74px',
  size = 'small',
  pickerSize = 25,
  placeholder = '',
  hasPicker = false
}): JSX.Element => {
  const [showPicker, setShowPicker] = useState(false)
  const ref = useRef<HTMLDivElement>(null)
  const { Text } = Typography

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setShowPicker(false)
      }
    }

    document.addEventListener('click', handleClickOutside, true)

    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [])

  const handlePickerChange = (color) => setSelectedColor(color.hex)
  const handleInputChange = (e) => setSelectedColor(e.target.value)
  const handleClick = (e) => {
    if (hasPicker) {
      e.preventDefault()
      setShowPicker(!showPicker)
    }
  }

  return (
    <div ref={ref}>
      {showPicker ? (
        <div
          css={css`
            position: absolute;
            top: ${top ? top : '-80px'};
            z-index: 1;
          `}
        >
          <ChromePicker
            color={selectedColor}
            onChange={handlePickerChange}
            disableAlpha
          />
        </div>
      ) : null}
      <Flex alignItems="center">
        <Box
          bg={selectedColor}
          width={pickerSize}
          height={pickerSize}
          minWidth={pickerSize}
          marginRight="10"
          borderRadius={4}
          cursor="pointer"
          border={
            !selectedColor || /(white|#ffffff|#fefefe)/i.test(selectedColor)
              ? '1px solid #909090'
              : `1px solid ${selectedColor}`
          }
          onClick={handleClick}
        />
        {hasPicker ? (
          <Input
            id={id}
            size={size}
            onChange={handleInputChange}
            value={selectedColor}
            placeholder={placeholder}
            css={css`
              width: ${width};
            `}
          />
        ) : (
          <Text>{label}</Text>
        )}
      </Flex>
    </div>
  )
}

export default ColorDisplay
