import { combineReducers } from '@reduxjs/toolkit'
import activationStatus from '@src/redux/activationStatus'
import avaDomainAssistant from '@src/redux/avaDomainAssistant'
import avaCanonicalMessage from '@src/redux/avaMessageAudit'
import avaResource from '@src/redux/avaResource'
import avaTextResource from '@src/redux/avaTextResource'
import avaVideoResource from '@src/redux/avaVideoResource'
import websocket from '@src/redux/websocket'

import aiKnowledgeBase from '../redux/aiKnowledgeBase'
import autoEmbed from '../redux/autoEmbed'
import automation from '../redux/automation'
import avaAssistantProfile from '../redux/avaAssistantProfile'
import avaBlocklist from '../redux/avaBlocklist'
import billing from '../redux/billing'
import bulkVideoSelection from '../redux/bulkVideoSelection'
import business from '../redux/business'
import businessStore from '../redux/businessStore'
import channel from '../redux/channel'
import channelInsight from '../redux/channelInsight'
import channelStore from '../redux/channelStore'
import chatChannel from '../redux/chatChannel'
import chatChannelFeeds from '../redux/chatChannelFeeds'
import chatChannelSegment from '../redux/chatChannelSegment'
import chatChannelSnippet from '../redux/chatChannelSnippet'
import cohorts from '../redux/cohorts'
import columnDisplay from '../redux/columnDisplay'
import content from '../redux/content'
import customCTA from '../redux/customCTA'
import display from '../redux/display'
import earning from '../redux/earning'
import embed from '../redux/embed'
import errors from '../redux/errors'
import favoriteBusiness from '../redux/favoriteBusiness'
import favoriteChannel from '../redux/favoriteChannel'
import fbAuth from '../redux/fbAuth'
import flows from '../redux/flows/index'
import geoLocation from '../redux/geoLocation'
import hashtag from '../redux/hashtag'
import home from '../redux/home'
import igAuth from '../redux/igAuth'
import igHashtagImporter from '../redux/igHashtagImporter'
import igImporter from '../redux/igImporter'
import impression from '../redux/impression'
import insight from '../redux/insight'
import insightAnnotations from '../redux/insightAnnotations'
import livestream from '../redux/livestream'
import marketplace from '../redux/marketplace'
import membership from '../redux/membership'
import microsites from '../redux/microsites'
import mrss from '../redux/mrss'
import mycontent from '../redux/mycontent'
import oauthApp from '../redux/oauthApp'
import payment from '../redux/payment'
import playlists from '../redux/playlist'
import product from '../redux/product'
import profile from '../redux/profile'
import request from '../redux/request'
import session from '../redux/session'
import shopMinis from '../redux/shopMinis'
import showrooms from '../redux/showrooms'
import sigmaEmbed from '../redux/sigmaEmbed'
import smartButton from '../redux/smartButton'
import sourceImporter from '../redux/sourceImporter'
import storeProduct from '../redux/storeProduct'
import subscriptions from '../redux/subscriptions'
import ui from '../redux/ui'
import user from '../redux/user'
import video from '../redux/video'
import videoUpload from '../redux/videoUpload'
import webhooks from '../redux/webhooks'

export default combineReducers({
  activationStatus,
  aiKnowledgeBase,
  automation,
  autoEmbed,
  billing,
  bulkVideoSelection,
  business,
  businessStore,
  channel,
  channelInsight,
  channelStore,
  chatChannel,
  chatChannelFeeds,
  chatChannelSegment,
  chatChannelSnippet,
  cohorts,
  content,
  customCTA,
  display,
  columnDisplay,
  earning,
  embed,
  errors,
  favoriteBusiness,
  favoriteChannel,
  flows,
  geoLocation,
  hashtag,
  home,
  fbAuth,
  igHashtagImporter,
  sourceImporter,
  igAuth,
  igImporter,
  impression,
  insight,
  insightAnnotations,
  livestream,
  marketplace,
  membership,
  microsites,
  mrss,
  mycontent,
  oauthApp,
  payment,
  playlists,
  product,
  profile,
  request,
  ui,
  session,
  shopMinis,
  showrooms,
  sigmaEmbed,
  smartButton,
  storeProduct,
  subscriptions,
  user,
  video,
  videoUpload,
  webhooks,
  websocket,
  avaAssistantProfile,
  avaBlocklist,
  avaDomainAssistant,
  avaTextResource,
  avaResource,
  avaVideoResource,
  avaCanonicalMessage
})
