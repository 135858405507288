import React, { useEffect, useMemo, useState } from 'react'

import { CommandBar } from '@src/components/CommandBar/CommandBar'
import { MaintenanceWindow } from '@src/components/MaintenanceWindow'
import '@src/localization/i18n'
import { DataTrackingProvider } from '@src/contexts/DataTrackingContext'
import { NabooFFContextProvider } from '@src/contexts/NabooFeatureFlag'
import { ThemeProvider } from '@src/contexts/ThemeContext'
import { useFacebookTracking, useGoogleTracking, useHistory } from '@src/hooks'
import { initEmbedApp } from '@src/utils/embeddedApp'
import { sessionStorageProvider } from '@src/utils/storageProvider'
import { CookiesProvider } from 'react-cookie'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import Router from '../app/Router'
import UnauthenticatedRoutes from '../app/UnauthenticatedRoutes'
import { BusinessProvider } from '../contexts/BusinessContext'
import AppLayout from '../layouts/AppLayout'
import { createSession } from '../redux/session'
import { makeGetRequest } from '../selectors/request'
import api from '../utils/api'

import 'react-cmdk/dist/cmdk.css'

/* A function that is used to initialize the embedded app. */
initEmbedApp()

const token = api.getToken()

/**
 * Adding Local storage and Session storage fallbacks to the window object
 * @type {{}}
 */

function App() {
  const dispatch = useDispatch()
  const [authenticated, setAuthenticated] = useState(!!token)
  const getRequest = useMemo(makeGetRequest, [])
  const { state: createSessionRequest } = useSelector((state) =>
    getRequest(state, 'session/createSession')
  )
  const { history } = useHistory()
  const { gtagTrack } = useGoogleTracking()
  const { fbTrack } = useFacebookTracking()

  //Needs to put amplitude_id into the session storage before redirect
  useEffect(() => {
    const url = new URL(window.location.href)
    const params = url.searchParams
    const deviceId = params.get('amplitude_id')
    if (deviceId) {
      sessionStorageProvider.setItem('amplitude_id', deviceId)
    }
  }, [])

  useEffect(() => {
    if (token) {
      dispatch(createSession())
      gtagTrack('event', 'conversion', {
        send_to: 'AW-856325107/1yZKCKP7-fUBEPP3qZgD'
      })
      gtagTrack('event', 'conversion', {
        send_to: 'AW-411548285/iRRICNrXiPgBEP30nsQB'
      })
      fbTrack('track', 'Lead')
    }
  }, [dispatch, fbTrack, gtagTrack])

  useEffect(() => {
    if (createSessionRequest === 'rejected') {
      setAuthenticated(false)
    }
  }, [createSessionRequest])

  /**
   * Inject story block script
   */
  useEffect(() => {
    const script = document.createElement('script')
    script.async = true
    script.type = 'text/javascript'
    script.dataset['fwparam_cookies_root'] = 'false'
    if (
      process.env.REACT_APP_ENV === 'production' ||
      process.env.REACT_APP_VERCEL_ENV === 'production'
    ) {
      script.src = '//asset.fwbiz1.com/js/fwn.js'
    } else {
      script.src = 'https://asset-staging.fireworktv.com/js/fwn.js'
    }
    document.head.appendChild(script)
  }, [])

  return (
    <ThemeProvider>
      <BrowserRouter history={history}>
        {authenticated ? (
          <CookiesProvider>
            <BusinessProvider>
              <NabooFFContextProvider>
                <MaintenanceWindow>
                  <DataTrackingProvider>
                    <CommandBar />
                    <AppLayout>
                      <Router />
                    </AppLayout>
                  </DataTrackingProvider>
                </MaintenanceWindow>
              </NabooFFContextProvider>
            </BusinessProvider>
          </CookiesProvider>
        ) : (
          <UnauthenticatedRoutes />
        )}
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default App
