import React from 'react'

import { Box, Flex, Text } from 'fwego'
import { useTranslation } from 'react-i18next'

type IProps = {
  textColor?: string
  video?: globalLib.IVideo
  onClick?: (val: string) => void
}

const VideoThumbnail = ({
  textColor = '#000000',
  video,
  onClick
}: IProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Box width="160" mb={['xsmall', 'medium']} pr={['xsmall', 'medium']}>
      <Box
        as="img"
        width="160"
        height="260"
        borderRadius="10"
        cursor="pointer"
        objectFit="cover"
        src={video?.thumbnail_url}
        alt={t('Video thumbnail')}
        onClick={() => onClick(video?.download_url)}
      />
      <Flex py="xsmall" alignItems="center">
        <Box
          as="img"
          src={video?.creator?.avatar_url}
          width={25}
          mr="xsmall"
          borderRadius="50%"
        />
        <Text size="xsmall" weight="normal" color={textColor}>
          {video?.creator?.name}
        </Text>
      </Flex>
      <Text size="small" weight="bold" color={textColor}>
        {video?.caption}
      </Text>
    </Box>
  )
}

export default VideoThumbnail
