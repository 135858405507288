import React, { useState } from 'react'

import { css } from '@emotion/core'
import { Table, Skeleton, TableColumnGroupType, TableColumnType } from 'antd'
import { Select, SelectOption } from 'fwego'
import { useTranslation } from 'react-i18next'

import { Card, CardHeader } from '.'
import { convertTimeUnit } from '../utils/stats'

const KEY_VIDEO_CAPTION = 'video_caption'
const KEY_VIEWS = 'total_views'
const KEY_WATCH_TIME = 'total_seconds_watched'
const KEY_CTR = 'thumbnail_click_rate'

type PerformanceTableProps = {
  data: any[]
  startDate?: string
  endDate?: string
}

type PlaylistVideoPerformanceCardProps = {
  loading?: boolean
  endDate?: string
  startDate?: string
  headerExtra?: any
  data?: any[]
}

const PerformanceTable = ({ data }: PerformanceTableProps): JSX.Element => {
  const { t } = useTranslation()
  const tableColumns: (TableColumnGroupType<any> | TableColumnType<any>)[] = [
    {
      align: 'left',
      dataIndex: KEY_VIDEO_CAPTION,
      key: KEY_VIDEO_CAPTION,
      title: t('Video')
    },
    {
      align: 'left',
      dataIndex: KEY_VIEWS,
      key: KEY_VIEWS,
      title: t('Views'),
      width: 100,
      render: (value) => value.toLocaleString()
    },
    {
      align: 'left',
      dataIndex: KEY_WATCH_TIME,
      key: KEY_WATCH_TIME,
      title: t('Watch Time'),
      render: function renderWatchTime(value) {
        return <span>{convertTimeUnit(value)}</span>
      },
      width: 150
    },
    {
      align: 'left',
      dataIndex: KEY_CTR,
      title: t('CTR'),
      key: KEY_CTR,
      render: function renderCTR(value) {
        return <span>{`${(value * 100).toFixed(1)}%`}</span>
      },
      width: 100
    }
  ]

  return (
    <Table
      columns={tableColumns}
      css={css`
        .ant-table-row {
          cursor: pointer;
        }
        .anticon {
          vertical-align: middle;
          padding-bottom: 6px;
        }
      `}
      dataSource={data}
      pagination={{ pageSize: 5, total: data.length }}
      rowKey={(record) => record.video_id}
    />
  )
}

const PlaylistVideoPerformanceCard = ({
  loading,
  data = [],
  endDate,
  startDate,
  headerExtra
}: PlaylistVideoPerformanceCardProps): JSX.Element => {
  const { t } = useTranslation()
  const [filter, setFilter] = useState(0)
  const playlistData = data[filter]

  const sanitizeData = (data) =>
    data.map((entry) => {
      if (!entry[KEY_VIDEO_CAPTION]) {
        return {
          ...entry,
          [KEY_VIDEO_CAPTION]: t('(No title)')
        }
      }

      return entry
    })
  const sanitizedData = sanitizeData(playlistData?.insights || [])

  return (
    <Card m="0">
      <Skeleton active loading={loading}>
        <CardHeader
          extra={headerExtra}
          title={t('Top videos for your playlist')}
          tooltip={t('Video performance sorted by views')}
        />
        <Select
          mb="large"
          width="250"
          onChange={(e) => setFilter(e.target.value)}
        >
          {data.map((playlist, i) => (
            <SelectOption key={i} value={i}>
              {playlist.name}
            </SelectOption>
          ))}
        </Select>
        <PerformanceTable
          data={sanitizedData}
          endDate={endDate}
          startDate={startDate}
        />
      </Skeleton>
    </Card>
  )
}

export default PlaylistVideoPerformanceCard
