import React, { useContext } from 'react'

import { FWButton } from '@src/components'
import { Flex, Box } from '@src/components/EmotionLayout'
import { KEY_ONBOARDING_STATUS, KEY_ACTIVATION_STATUS } from '@src/constants'
import { BusinessContext } from '@src/contexts/BusinessContext'
import { updateBusinessActivationStatus } from '@src/redux/business'
import {
  localStorageProvider,
  sessionStorageProvider
} from '@src/utils/storageProvider'
import { Switch, Card, Space, Input } from 'antd'
import { useTranslation } from 'react-i18next'

export const DevTools: React.FC = () => {
  const { t } = useTranslation()
  const businessId = useContext(BusinessContext)
  const [showTools, setShowTools] = React.useState(false)
  const [apiURL, setApiURL] = React.useState('')

  const handleResetOnboarding = async () => {
    await updateBusinessActivationStatus(businessId, true)
    localStorageProvider.setItem(
      KEY_ONBOARDING_STATUS,
      JSON.stringify({ oq: false, wm: false, pb: false, pdp: false })
    )
    localStorageProvider.setItem(
      KEY_ACTIVATION_STATUS,
      JSON.stringify({
        uploadVideo: 'active',
        createPlaylist: 'inactive',
        embedPlaylist: 'inactive'
      })
    )
    window.location.reload()
  }

  if (process.env.REACT_APP_ENV === 'production') return null

  return (
    <>
      <Flex
        position="fixed"
        bottom="20"
        right="10"
        flexDirection="column"
        justifyContent="flex-end"
        alignItems="flex-end"
        z-index="100"
      >
        {showTools && (
          <Card bordered>
            <Flex flexDirection="column" gap="10">
              <Space>
                <Input
                  value={apiURL}
                  onChange={(e) => setApiURL(e.target.value)}
                  placeholder={t('API URL')}
                />
                <FWButton
                  onClick={() => {
                    sessionStorageProvider.setItem('apiURL', apiURL)
                    window.location.reload()
                  }}
                >
                  {t('Set API URL')}
                </FWButton>
              </Space>
              <FWButton onClick={handleResetOnboarding}>
                {t('Reset Onboarding')}
              </FWButton>
            </Flex>
          </Card>
        )}
        <Flex gap="5">
          <Box>{t('Dev Tools')}</Box>
          <Switch checked={showTools} onChange={setShowTools} />
        </Flex>
      </Flex>
    </>
  )
}
