import React from 'react'

import { Text } from 'fwego'

import theme from '../styles/theme'

type IProps = {
  children: React.ReactNode
}

const FormLabel: React.FC<IProps> = ({ children }) => {
  return (
    <Text color={theme.text} fontSize="medium">
      {children}
    </Text>
  )
}

export default FormLabel
