import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { SIGMA_EMBED_LEVELS } from '@src/constants'
import api from '@src/utils/api'
import { localStorageProvider } from '@src/utils/storageProvider'

type InitialState = {
  sigmaUrlByLevel: Record<string, Record<string, string>>
  loading: boolean
  startDate: string | null
  endDate: string | null
}

type argsType = {
  businessId: string
  channelId: string
  dashboardId: string
  level: string
  params?:
    | {
        start_date: string
        end_date: string
      }
    | Record<string, unknown>
  id: string
}

const initialState: InitialState = {
  sigmaUrlByLevel: {
    [SIGMA_EMBED_LEVELS.BUSINESS]: {},
    [SIGMA_EMBED_LEVELS.CHANNEL]: {},
    [SIGMA_EMBED_LEVELS.VIDEO]: {},
    [SIGMA_EMBED_LEVELS.PLAYLIST]: {},
    [SIGMA_EMBED_LEVELS.SHOWROOM]: {},
    [SIGMA_EMBED_LEVELS.LIVESTREAMS]: {},
    [SIGMA_EMBED_LEVELS.LIVESTREAM]: {},
    [SIGMA_EMBED_LEVELS.OTO_CHAT]: {},
    [SIGMA_EMBED_LEVELS.OTO_REQUEST]: {},
    [SIGMA_EMBED_LEVELS.OTO_PERFORMANCE]: {},
    [SIGMA_EMBED_LEVELS.OTO_TEAM]: {},
    [SIGMA_EMBED_LEVELS.AVA_INDIVIDUAL]: {},
    [SIGMA_EMBED_LEVELS.AVA_BUSINESS]: {},
    [SIGMA_EMBED_LEVELS.REVENUE]: {},
    [SIGMA_EMBED_LEVELS.SHORT_VIDEO]: {}
  },
  loading: true,
  startDate: null,
  endDate: null
}

const sigmaLocales = {
  zh: 'zh-cn',
  en: 'en',
  fr: 'fr',
  de: 'de',
  it: 'it',
  ja: 'ja',
  ko: 'ko-kr',
  pl: 'pl',
  pt: 'pt',
  ru: 'ru',
  es: 'es',
  sv: 'sv-se',
  th: 'th'
}

export const getLocale = (): string => {
  const browserLocale = localStorageProvider.getItem('i18nextLng') || 'en'

  return sigmaLocales[browserLocale.slice(0, 2)] || 'en'
}

export const fetchSigmaUrl = createAsyncThunk(
  'sigmaEmbed/fetchSigmaUrl',
  async (args: argsType) => {
    const { businessId, channelId, dashboardId, level, params, id } = args
    if (
      [
        SIGMA_EMBED_LEVELS.VIDEO,
        SIGMA_EMBED_LEVELS.PLAYLIST,
        SIGMA_EMBED_LEVELS.LIVESTREAM,
        SIGMA_EMBED_LEVELS.SHOWROOM,
        SIGMA_EMBED_LEVELS.AVA_INDIVIDUAL,
        SIGMA_EMBED_LEVELS.SHORT_VIDEO
      ].includes(level)
    ) {
      params[`${level}_id`] = id
    }
    params[':lng'] = getLocale()
    const res = await api.get(
      `bus/${businessId}/channels/${channelId}/sigma/${dashboardId}`,
      { params }
    )

    return { ...res.data, level, id, params }
  }
)

const slice = createSlice({
  name: 'sigmaEmbed',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSigmaUrl.fulfilled, (state, action) => {
      state.loading = false
      const { level, id, url, params } = action.payload
      state.sigmaUrlByLevel[level][id] = url
      state.startDate = params.start_date
      state.endDate = params.end_date
    })
  }
})

export default slice.reducer
