import React, { useCallback, useEffect, useState } from 'react'

import { FilterFilled } from '@ant-design/icons'
import { css } from '@emotion/react'
import { Flex } from '@src/components/EmotionLayout'
import { fetchAvaDomainAssistant } from '@src/redux/avaDomainAssistant'
import {
  fetchAssistanceByAssistanceId,
  fetchAssistances,
  fetchOriginalMessagesByAssistanceIds
} from '@src/redux/avaMessageAudit'
import { validateResponseSuccess } from '@src/utils/api'
import {
  Breadcrumb,
  Button,
  Divider,
  Empty,
  Input,
  List,
  Popover,
  Skeleton,
  Space,
  Spin
} from 'antd'
import { upperCase } from 'lodash'
import moment from 'moment'
import { CSVLink } from 'react-csv'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'

import { AssistanceMessages } from './components/AssistanceMessages'
import { FixInfiniteScroll } from './components/FixInfineteScroll'
import { useHotkey } from './hooks/useHotkey'

const headersMap = [
  { label: 'Ava Id', key: 'domain_asisstant_id' },
  { label: 'Conversation Id', key: 'assistance_id' },
  { label: 'Timestamp', key: 'created_at' },
  { label: 'Author', key: 'author' },
  { label: 'Message', key: 'message' },
  { label: 'Feedback Status', key: 'feedback_status' },
  { label: 'Video Resource Id', key: 'video_resource_id' }
]

interface IExportMessage {
  domain_asisstant_id: string
  assistance_id: string
  created_at: string
  author: string
  message: string
  video_resource_id?: string
  feedback_status: string
}

interface OriginalMessageViewModel extends OriginalMessage {
  assistance_id: string
  assistance_created_at: string
}

export const ConversationHistory: React.FC = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const searchKey = searchParams.get('id')

  const [assistances, setAssistances] = useState<Assistance[]>([])
  const [
    selectedAssistance,
    setSelectedAssistance
  ] = useState<Assistance | null>(null)
  const dispatch = useDispatch()
  const { businessId, domainAssistantId } = useParams()
  const [loading, setLoading] = useState(true)
  const [filterStatus, setFilterStatus] = useState<
    'reviewed' | 'unreviewed' | 'all'
  >('all')

  const [domainAssistant, setDomainAssistant] = useState(undefined)

  const [nextPage, setNextPage] = useState<Record<string, string>>({})

  const [exporting, setExporting] = useState(false)
  const [serializedData, setSerializedData] = useState<IExportMessage[]>([])
  const csvLinkEl = React.useRef(null)

  const loadMoreData = useCallback(
    async (refresh = false) => {
      const response = await fetchAssistances(
        businessId,
        domainAssistantId,
        filterStatus !== 'all' ? filterStatus : undefined,
        refresh ? null : nextPage
      )
      if (validateResponseSuccess(response)) {
        if (refresh) {
          setAssistances(response.data.assistances)
          setSelectedAssistance(
            response.data.assistances.length > 0
              ? response.data.assistances[0]
              : null
          )
        } else {
          setAssistances(assistances.concat(response.data.assistances))
        }
      }

      setNextPage(response.data.paging)
    },
    [businessId, domainAssistantId, nextPage, assistances, filterStatus]
  )

  useEffect(() => {
    const loadDomainAssistant = async () => {
      const response = await dispatch(
        fetchAvaDomainAssistant(businessId, domainAssistantId)
      )
      setDomainAssistant(response)
    }
    loadDomainAssistant()
  }, [businessId, domainAssistantId, dispatch])

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        if (searchKey?.length > 0) {
          const response = await fetchAssistanceByAssistanceId(
            businessId,
            domainAssistantId,
            searchKey
          )
          if (validateResponseSuccess(response)) {
            setAssistances([response.data])
            setSelectedAssistance(response.data)
          } else {
            setAssistances([])
            setSelectedAssistance(null)
          }
          setNextPage({})
        } else {
          loadMoreData(true)
        }
        setLoading(false)
      } catch (e) {
        setLoading(false)
      }
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessId, dispatch, domainAssistantId, searchKey, filterStatus])

  useHotkey((event) => {
    if (event.ctrlKey || event.metaKey || event.altKey || event.shiftKey) return

    const index = assistances.findIndex(
      (item) => item.id === selectedAssistance.id
    )
    if (upperCase(event.key) === 'W') {
      if (index > 0) {
        event.preventDefault()
        setSelectedAssistance(assistances[index - 1])
      }
    } else if (upperCase(event.key) === 'S') {
      if (index < assistances.length - 1) {
        event.preventDefault()
        setSelectedAssistance(assistances[index + 1])
      }
    }
  })

  const fetchDataWithSearchKey = async (searchKey: string) => {
    if (loading) return

    let url = `/business/${businessId}/ava/${domainAssistant.id}/conversations`
    if (searchKey.length > 0) {
      url = url + `?id=${searchKey}`
    }
    navigate(url, {
      replace: true
    })
  }

  const onFilterChange = (status: 'reviewed' | 'unreviewed' | 'all') => {
    if (loading) return
    setFilterStatus(status)
  }

  const fetchMessageInBatch = useCallback(
    async (businessId: string, assistanceIds: string) => {
      let response = await fetchOriginalMessagesByAssistanceIds(
        businessId,
        assistanceIds
      )
      let messages: OriginalMessageViewModel[] = response.data.messages

      while (response.data.paging.next) {
        response = await fetchOriginalMessagesByAssistanceIds(
          businessId,
          assistanceIds,
          response.data.paging
        )
        messages = messages.concat(response.data.messages)
      }

      return messages
    },
    []
  )

  const sortMessageByTimeAndConvId = useCallback(
    async (messages: OriginalMessageViewModel[]) => {
      const newMessages = [...messages]
      newMessages.sort((a, b) => {
        if (a.assistance_created_at > b.assistance_created_at) {
          return -1
        } else if (a.assistance_created_at < b.assistance_created_at) {
          return 1
        } else {
          if (a.assistance_id > b.assistance_id) {
            return -1
          } else if (a.assistance_id < b.assistance_id) {
            return 1
          } else {
            if (a.created_at < b.created_at) {
              return -1
            } else if (a.created_at > b.created_at) {
              return 1
            } else {
              return 0
            }
          }
        }
      })

      return newMessages
    },
    []
  )

  const onClickExport = async () => {
    try {
      setExporting(true)

      // Export a maximum of 100 conversation list
      let exportMessages: OriginalMessageViewModel[] = []
      const response = await fetchAssistances(
        businessId,
        domainAssistantId,
        filterStatus !== 'all' ? filterStatus : undefined,
        null,
        100
      )
      const assistances = response.data.assistances as Assistance[]

      // Get the message list of the conversation in batches
      for (let i = 0; i < assistances.length; i += 10) {
        const subAssistantces = assistances.slice(i, i + 10)
        const assistanceIds = subAssistantces.map((item) => item.id).join()
        const originalMessages = await fetchMessageInBatch(
          businessId,
          assistanceIds
        )
        exportMessages.push(...originalMessages)
      }

      // Sort messages by message time and same conversation id
      exportMessages = await sortMessageByTimeAndConvId(exportMessages)
      setSerializedData(
        exportMessages.map((value) => {
          return {
            domain_asisstant_id: domainAssistantId,
            assistance_id: value.assistance_id,
            created_at: value.created_at,
            author: value.from,
            message: value.text,
            video_resource_id: value.video_resource_id,
            feedback_status: value.message_feedbacks
              .map((item) => item.feedback)
              .join()
          }
        })
      )
      csvLinkEl.current.link.click()
      setExporting(false)
    } catch (error) {
      setExporting(false)
    }
  }

  return (
    <>
      <Breadcrumb
        style={{
          background: 'white',
          padding: '0 16px',
          marginTop: '-6px',
          marginBottom: '5px'
        }}
      >
        <Breadcrumb.Item>
          <Link to="../../">{t('AI Video Assistant')}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="../">{domainAssistant?.title}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{t('Conversations')}</Breadcrumb.Item>
      </Breadcrumb>
      <Flex
        flexDirection="row"
        style={{
          padding: 14,
          height: 'calc(100vh - 86px)'
        }}
      >
        <Flex
          flexDirection="row"
          style={{
            height: '100%',
            width: '100%',
            background: 'white',
            borderRadius: 12
          }}
        >
          <>
            <Flex
              flexDirection="column"
              style={{
                height: '100%',
                width: 330
              }}
            >
              <Flex
                style={{
                  padding: '18px 12px 18px 24px',
                  width: '100%',
                  height: 68,
                  borderBottom: '1px solid #EAEAEA'
                }}
                justifyContent="space-between"
                flexDirection="row"
              >
                <Flex gap={10}>
                  <Input.Search
                    allowClear
                    style={{ maxWidth: 150 }}
                    placeholder={t('Search #ID')}
                    defaultValue={searchKey}
                    onSearch={fetchDataWithSearchKey}
                  />
                  <Popover
                    showArrow={false}
                    placement="bottomLeft"
                    trigger="click"
                    content={
                      <List style={{ width: 240 }}>
                        {([
                          { status: 'all', label: t('All') },
                          { status: 'reviewed', label: t('Reviewed') },
                          { status: 'unreviewed', label: t('Unreviewed') }
                        ] as {
                          status: 'reviewed' | 'unreviewed' | 'all'
                          label: string
                        }[]).map((item) => (
                          <List.Item
                            key={item.status}
                            style={{
                              padding: '12px 12px',
                              borderRadius: 4,
                              background:
                                filterStatus === item.status
                                  ? '#EBF4FF'
                                  : undefined
                            }}
                            onClick={() => onFilterChange(item.status)}
                          >
                            {item.label}
                          </List.Item>
                        ))}
                      </List>
                    }
                  >
                    <Button icon={<FilterFilled />} />
                  </Popover>
                </Flex>
                <Button loading={exporting} onClick={onClickExport}>
                  {t('Export')}
                </Button>
                <CSVLink
                  ref={csvLinkEl}
                  data={serializedData}
                  headers={headersMap}
                  filename="assistance_metadata.csv"
                  target="_blank"
                />
              </Flex>

              {loading ? (
                <Flex
                  width="100%"
                  height="100%"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Spin size="large" />
                </Flex>
              ) : (
                <FixInfiniteScroll
                  load={loadMoreData}
                  hasMore={!!nextPage.next}
                  loader={
                    <Skeleton
                      paragraph={{ rows: 1 }}
                      active
                      css={css`
                        .ant-skeleton-content {
                          vertical-align: middle;
                        }
                        .ant-skeleton-paragraph {
                          margin: 0 !important;
                        }
                        .ant-skeleton-title {
                          width: 50% !important;
                        }
                      `}
                      style={{
                        height: 88,
                        paddingLeft: 22,
                        paddingRight: 22
                      }}
                    />
                  }
                  endMessage={
                    assistances.length > 0 ? (
                      <Divider plain>{t('End')}</Divider>
                    ) : null
                  }
                >
                  <List
                    dataSource={assistances}
                    renderItem={(assistance) => (
                      <List.Item
                        key={assistance.id}
                        style={{
                          height: 88,
                          paddingLeft: 10,
                          paddingRight: 10,
                          background:
                            assistance.id === selectedAssistance?.id
                              ? '#f4f7fa'
                              : null
                        }}
                        onClick={() => {
                          setSelectedAssistance(assistance)
                        }}
                      >
                        <List.Item.Meta
                          style={{ padding: 12 }}
                          title={
                            <span
                              style={{
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                width: '100%',
                                display: 'inline-block'
                              }}
                            >
                              {assistance.last_message ?? '-'}
                            </span>
                          }
                          description={
                            <Space
                              size={16}
                              style={{
                                display: 'flex',
                                color: 'var(--Gray-2---Dark, #9B9B9B)',
                                flexDirection: 'row',
                                alignItems: 'center'
                              }}
                            >
                              <span>{assistance.id}</span>
                              <span>
                                {moment(assistance.ended_at).format(
                                  'MMM D, YYYY h:mm A'
                                )}
                              </span>
                            </Space>
                          }
                        />
                      </List.Item>
                    )}
                  />
                </FixInfiniteScroll>
              )}
            </Flex>
            <Divider type="vertical" style={{ height: '100%', margin: 0 }} />
            {selectedAssistance ? (
              <AssistanceMessages
                assistance={selectedAssistance}
                businessId={businessId}
              />
            ) : (
              <Flex
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  flex: 1
                }}
              >
                <Empty
                  description={
                    <span>{t('Select a conversation to view')}</span>
                  }
                />
              </Flex>
            )}
          </>
        </Flex>
      </Flex>
    </>
  )
}
