import moment from 'moment'

export function isScheduledVideo(video: globalLib.IVideo): boolean {
  return (
    moment(video?.inserted_at).format() !==
      moment(video?.published_at).format() &&
    moment(video?.published_at).format() > moment().format()
  )
}

export const replaceVideosById = (
  originalVideos?: globalLib.IVideo[],
  videos?: globalLib.IVideo[]
): globalLib.IVideo[] => {
  return originalVideos?.map((item) => {
    const foundItem = videos?.find(
      (video) => video.encoded_id === item.encoded_id
    )

    return foundItem ? foundItem : item
  })
}
