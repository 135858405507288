import React, { ReactNode } from 'react'

import css from '@emotion/css'
import { Tooltip } from 'antd'
import { Divider, Skeleton } from 'antd'
import { Text } from 'fwego'
import { Box } from 'fwego'
import { useTranslation } from 'react-i18next'

import { Card, EmptyInsightsContent } from '.'
import theme from '../styles/theme'

function calculate_diff(value, prevValue) {
  const result = ((value - prevValue) / prevValue) * 100

  return isNaN(result) || result === Infinity ? 0 : result
}

function truncateNumber(number) {
  if (number > 1) {
    return Math.round(number)
  } else if (number === 0) {
    return 0
  } else {
    return number.toFixed(2)
  }
}

type MetricComparisonProps = {
  value?: number | string
  prevValue?: number
}

type LearnMoreProps = {
  text: ReactNode
}

type VisorMetricCardProps = {
  value?: number
  label?: string
  unit?: string
  header?: string
  loading?: boolean
  footerText?: string
  icon?: string
  aggregation_level?: string
  prevValue?: number
  learnMoreText?: ReactNode
}

const MetricComparison = ({ value, prevValue }: MetricComparisonProps) => {
  const rate_difference = calculate_diff(
    typeof value === 'string' ? parseInt(value) : value,
    prevValue
  )
  const absoluteDifference = Math.abs(rate_difference)
  const cleanedNumber = truncateNumber(absoluteDifference)

  if (rate_difference > 0) {
    return (
      <div style={{ display: 'flex', justifyContent: 'start', margin: '10px' }}>
        <Text color="#27AE60">{cleanedNumber}%</Text>
        <Box
          as="img"
          src="/polygon_up.svg"
          height="13"
          width="13"
          objectFit="contain"
          m="5px"
          alignContent="center"
        />
      </div>
    )
  } else if (rate_difference < 0) {
    return (
      <div style={{ display: 'flex', justifyContent: 'start', margin: '10px' }}>
        <Text color={theme.primary}>{cleanedNumber}%</Text>
        <Box
          as="img"
          src="/polygon_down.svg"
          height="13"
          width="13"
          objectFit="contain"
          m="5px"
          alignContent="center"
        />
      </div>
    )
  } else if (rate_difference === 0) {
    return (
      <div
        style={{ display: 'flex', justifyContent: 'center', margin: '10px' }}
      >
        <Text color="#FAC120">{cleanedNumber}%</Text>
      </div>
    )
  } else {
    return null
  }
}

function LearnMore({ text }: LearnMoreProps) {
  const { t } = useTranslation()

  return (
    <Tooltip
      title={text}
      overlayInnerStyle={{
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '12px',
        lineHeight: '16px'
      }}
    >
      <Text
        color={theme.primary}
        fontSize="12px"
        fontWeight="medium"
        lineHeight="16px"
        textDecoration="underline"
      >
        {t('Learn how to improve this')}
      </Text>
    </Tooltip>
  )
}

const VisorMetricCard = ({
  value,
  label,
  unit = '',
  header,
  loading,
  footerText,
  icon,
  prevValue,
  learnMoreText
}: VisorMetricCardProps): JSX.Element => {
  const { t } = useTranslation()

  const metricHeader = (
    <Text
      color={theme.gray19}
      fontSize="14px"
      fontWeight="bold"
      lineHeight="16px"
      textAlign="left"
      width="100%"
      css={css`
        margin-left: 10px;
      `}
    >
      {header}
    </Text>
  )

  let metricLabel
  if (label !== undefined) {
    metricLabel = (
      <Text
        color={theme.gray19}
        fontSize="16px"
        fontWeight="medium"
        lineHeight="22px"
        textAlign="center"
      >
        {label}
      </Text>
    )
  } else {
    metricLabel = <div style={{ height: '22px' }}></div>
  }

  let metricFooter
  if (footerText !== undefined) {
    metricFooter = (
      <Text
        color={theme.gray19}
        fontSize="12px"
        fontWeight="medium"
        lineHeight="14px"
      >
        {footerText}
      </Text>
    )
  } else {
    metricFooter = null
  }

  let metricComparison
  if (prevValue !== undefined) {
    metricComparison = (
      <Tooltip
        title={t('Preceding Period')}
        placement="bottom"
        align={{ offset: [0, -10] }}
      >
        <span>
          <MetricComparison value={value} prevValue={prevValue} />
        </span>
      </Tooltip>
    )
  } else {
    metricComparison = <div style={{ height: '47px' }}></div>
  }

  let metricBody
  if (value === undefined) {
    metricBody = (
      <Box p="55px">
        {metricHeader}
        <EmptyInsightsContent />
      </Box>
    )
  } else {
    metricBody = (
      <>
        <div
          style={{
            display: 'flex',
            flexShrink: 2,
            alignItems: 'center',
            alignSelf: 'start',
            justifyContent: 'space-between',
            flexDirection: 'column',
            maxHeight: '260px'
          }}
        >
          {metricHeader}
          <Box
            as="img"
            src={icon}
            height="45"
            objectFit="contain"
            m="20px"
            alignContent="center"
          />
          <span
            style={{
              color: '#353F52',
              fontWeight: 600
            }}
            className="visor-metric"
          >
            {value.toLocaleString()}
            {unit}
          </span>
          {metricLabel}
          {metricComparison}
        </div>
        {footerText || learnMoreText ? <Divider /> : null}
        {metricFooter}
        {learnMoreText ? <LearnMore text={learnMoreText} /> : null}
      </>
    )
  }

  return (
    <Card height="100%" m="0" p="20">
      <Box height="368">
        <Skeleton loading={loading}>{metricBody}</Skeleton>
      </Box>
    </Card>
  )
}

export default VisorMetricCard
