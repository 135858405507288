import React from 'react'

import { Divider, Skeleton } from 'antd'
import { Chart, Geom, Axis, Tooltip, Legend } from 'bizcharts'
import { Box } from 'fwego'
import moment from 'moment'

import { Card, EmptyInsightsContent } from '.'
import { numberFormatter } from '../utils/format'

type IProps = {
  data: any[]
  dataKey?: string
  loading?: boolean
  footer?: React.ReactNode
  header?: React.ReactNode
  title?: string
  fieldX: string
  fieldY: string
  padding?: any
  height?: number
  tooltip?: string
  tooltipFormatter: (val: any) => void
}

const StackedBarChartCard = ({
  loading,
  data = [],
  dataKey,
  fieldX,
  fieldY,
  header,
  footer,
  height,
  padding = [10, 40, 60, 50],
  tooltipFormatter
}: IProps): JSX.Element => {
  const newData = data.map((d) => ({
    ...d,
    report_date: moment(d.report_date).format('MM/DD')
  }))
  const scale = {
    [fieldX]: {
      tickCount: 15
    }
  }

  return (
    <Card height="100%" m="0">
      <Skeleton loading={loading}>
        {header}
        {data && data.length > 0 ? (
          <>
            <Chart
              padding={padding}
              height={height}
              data={newData}
              scale={scale}
              forceFit
            >
              <Legend />
              <Axis name={fieldX} />
              <Axis
                name={fieldY}
                label={{ formatter: numberFormatter, offset: 15 }}
              />
              <Tooltip />
              <Geom
                type="intervalStack"
                position={`${fieldX}*${fieldY}`}
                color={dataKey}
                style={{
                  stroke: '#fff',
                  lineWidth: 1
                }}
                tooltip={[
                  `date*${dataKey}*${fieldY}`,
                  (date, label, value) => {
                    return {
                      title: date,
                      name: label,
                      value: `${tooltipFormatter(value)}`
                    }
                  }
                ]}
              />
            </Chart>
            {footer && (
              <>
                <Divider style={{ marginTop: 5 }} />
                {footer}
              </>
            )}
          </>
        ) : (
          <Box>
            <EmptyInsightsContent />
          </Box>
        )}
      </Skeleton>
    </Card>
  )
}

export default StackedBarChartCard
