import { useEffect, useState } from 'react'

export default function useAdBlockEnabled() {
  const [adBlockEnabled, setAdBlockEnabled] = useState(false)
  useEffect(() => {
    const detectAdBlock = () => {
      const adBlockTestUrl = '//asset.fwcdn1.com/js/fwn.js'
      try {
        fetch(adBlockTestUrl).catch(() => setAdBlockEnabled(true))
      } catch (e) {
        setAdBlockEnabled(true)
      }
    }
    detectAdBlock()
  }, [])

  return adBlockEnabled
}
