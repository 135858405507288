import { createAction, createSlice } from '@reduxjs/toolkit'
import i18n from '@src/localization/i18n'
import { notification } from 'antd'
import { Dispatch } from 'redux'

import api from '../utils/api'

export interface ContentState {
  videos: Record<string, globalLib.IVideo>
  hashtags: Record<string, any>
  hashtagsPaging: Record<string, string>
  channels: Record<string, globalLib.Channel>
  channelsPaging: Record<string, globalLib.Channel>
  searchedHashtagNames: string[]
  searchedChannelUsernames: string[]
  hashtagVideoIds: Record<string, string[]>
  hashtagVideosPaging: Record<string, globalLib.Channel>
  channelAllVideoIds: Record<string, string[]>
  channelAllVideosPaging: Record<string, globalLib.Channel>
  channelOriginalVideoIds: Record<string, string[]>
  channelOriginalVideosPaging: Record<string, globalLib.Channel>
  recommendationVideosIds: string[]
  recommendationVideosPaging: Record<string, globalLib.Channel>
}

const initialState: ContentState = {
  videos: {},
  hashtags: {},
  hashtagsPaging: {},
  channels: {},
  channelsPaging: {},
  searchedHashtagNames: [],
  searchedChannelUsernames: [],
  hashtagVideoIds: {},
  hashtagVideosPaging: {},
  channelAllVideoIds: {},
  channelAllVideosPaging: {},
  channelOriginalVideoIds: {},
  channelOriginalVideosPaging: {},
  recommendationVideosIds: [],
  recommendationVideosPaging: {}
}

const slice = createSlice({
  name: 'content',
  initialState: initialState,
  reducers: {
    fetchChannelSuccess(state, action) {
      const { channel } = action.payload
      state.channels[channel.username] = channel
    },
    fetchVideoSuccess(state, action) {
      const { video } = action.payload
      state.videos[video.encoded_id] = video
    },
    updateVideoSuccess(state, action) {
      const { video } = action.payload
      state.videos[video.encoded_id] = video
    },
    searchHashtagsSuccess(state, action) {
      const { hashtags, paging, page } = action.payload
      if (page) {
        hashtags.forEach((hashtag) => {
          state.searchedHashtagNames.push(hashtag.name)
        })
      } else {
        state.searchedHashtagNames = hashtags.map((hashtag) => hashtag.name)
      }
      hashtags.forEach((hashtag) => {
        state.hashtags[hashtag.name] = hashtag
      })
      state.hashtagsPaging = paging
    },
    searchChannelsSuccess(state, action) {
      const { channels, paging, page } = action.payload
      if (page) {
        channels.forEach((channel) => {
          state.searchedChannelUsernames.push(channel.username)
        })
      } else {
        state.searchedChannelUsernames = channels.map(
          (channel) => channel.username
        )
      }
      channels.forEach((channel) => {
        state.channels[channel.username] = channel
      })
      state.channelsPaging = paging
    },
    fetchHashtagVideosSuccess(state, action) {
      const { tag, videos, paging, page } = action.payload
      videos.forEach((video) => {
        state.videos[video.encoded_id] = video
      })
      if (page) {
        videos.forEach((video) => {
          state.hashtagVideoIds[tag].push(video.encoded_id)
        })
      } else {
        state.hashtagVideoIds[tag] = videos.map((video) => video.encoded_id)
      }
      state.hashtagVideosPaging[tag] = paging
    },
    fetchChannelAllVideosSuccess(state, action) {
      const { username, videos, paging, page } = action.payload
      videos.forEach((video) => {
        state.videos[video.encoded_id] = video
      })
      if (page) {
        videos.forEach((video) => {
          state.channelAllVideoIds[username].push(video.encoded_id)
        })
      } else {
        state.channelAllVideoIds[username] = videos.map(
          (video) => video.encoded_id
        )
      }
      state.channelAllVideosPaging[username] = paging
    },
    fetchChannelOriginalVideosSuccess(state, action) {
      const { username, videos, paging, page } = action.payload
      videos.forEach((video) => {
        state.videos[video.encoded_id] = video
      })
      if (page) {
        videos.forEach((video) => {
          state.channelOriginalVideoIds[username].push(video.encoded_id)
        })
      } else {
        state.channelOriginalVideoIds[username] = videos.map(
          (video) => video.encoded_id
        )
      }
      state.channelOriginalVideosPaging[username] = paging
    },
    createRepostSuccess(state, action) {
      const { videoId } = action.payload
      state.videos[videoId].is_reposted = true
    },
    deleteRepostSuccess(state, action) {
      const { videoId } = action.payload
      state.videos[videoId].is_reposted = false
    },
    fetchRecommendationSuccess(state, action) {
      const { videos, paging, page } = action.payload
      videos.forEach((video) => {
        state.videos[video.encoded_id] = video
      })

      if (page) {
        videos.forEach((video) => {
          state.recommendationVideosIds.push(video.encoded_id)
        })
      } else {
        state.recommendationVideosIds = videos.map((video) => video.encoded_id)
      }

      state.recommendationVideosPaging = paging
    }
  }
})

export default slice.reducer

export const {
  fetchChannelSuccess,
  fetchVideoSuccess,
  updateVideoSuccess,
  searchHashtagsSuccess,
  searchChannelsSuccess,
  fetchHashtagVideosSuccess,
  fetchChannelAllVideosSuccess,
  fetchChannelOriginalVideosSuccess,
  createRepostSuccess,
  deleteRepostSuccess,
  fetchRecommendationSuccess
} = slice.actions

const fetchChannelRequest = createAction('content/fetchChannelRequest')
const fetchChannelFailure = createAction('content/fetchChannelFailure')

export function fetchChannel(username: string) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchChannelRequest())
      const response = await api.get(`/users/${username}`)
      const channel = response.data
      dispatch(fetchChannelSuccess({ channel }))
    } catch (error) {
      dispatch(fetchChannelFailure())
    }
  }
}

const updateVideoRequest = createAction('content/updateVideoRequest')
const updateVideoFailure = createAction('content/updateVideoFailure')

export function updateVideo(
  businessId: string,
  channelId: string,
  videoId: string,
  data?: any
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(updateVideoRequest())
      const response = await api.patch(
        `/bus/${businessId}/channels/${channelId}/videos/${videoId}`,
        data
      )
      const video = response.data
      dispatch(updateVideoSuccess({ video }))
    } catch (error) {
      dispatch(updateVideoFailure())

      return error
    }
  }
}

const fetchVideoRequest = createAction('content/fetchVideoRequest')
const fetchVideoFailure = createAction('content/fetchVideoFailure')

export function fetchVideo(
  businessId: string,
  channelId: string,
  videoId: string,
  withRequestAction = true
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      if (withRequestAction) {
        dispatch(fetchVideoRequest())
      }
      const response = await api.get(
        `/bus/${businessId}/channels/${channelId}/videos/${videoId}`
      )
      const video = response.data
      dispatch(fetchVideoSuccess({ video }))
    } catch (error) {
      dispatch(fetchVideoFailure())
    }
  }
}

const searchHashtagsRequest = createAction('content/searchHashtagsRequest')
const searchHashtagsFailure = createAction('content/searchHashtagsFailure')

export function searchHashtags(q?: string, page?: string) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(searchHashtagsRequest())
      const params: Record<string, any> = { page_size: 10 }
      if (q) {
        params.q = q
      }
      const response = await api.get(page || '/hashtags', {
        params
      })
      const { hashtags, paging } = response.data
      dispatch(searchHashtagsSuccess({ hashtags, paging, page }))

      return response
    } catch (error) {
      dispatch(searchHashtagsFailure())

      return error
    }
  }
}

const searchChannelsRequest = createAction('content/searchChannelsRequest')
const searchChannelsFailure = createAction('content/searchChannelsFailure')

export function searchChannels(q?: string, page?: string) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(searchChannelsRequest())
      const params: Record<string, any> = {}
      if (q) {
        params.q = q
      }
      const response = await api.get(page || '/users', { params })
      const { users, paging } = response.data
      dispatch(searchChannelsSuccess({ channels: users, paging, page }))

      return response
    } catch (error) {
      dispatch(searchChannelsFailure())

      return error
    }
  }
}

const fetchHashtagVideosRequest = createAction(
  'content/fetchHashtagVideosRequest'
)
const fetchHashtagVideosFailure = createAction(
  'content/fetchHashtagVideosFailure'
)

export function fetchHashtagVideos(
  businessId: string,
  channelId: string,
  tag: string,
  page: string
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchHashtagVideosRequest())
      const response = await api.get(
        page ||
          `/bus/${businessId}/channels/${channelId}/hashtags/${tag}/videos`
      )
      const { videos, paging } = response.data
      dispatch(fetchHashtagVideosSuccess({ tag, videos, page, paging }))
    } catch (error) {
      dispatch(fetchHashtagVideosFailure())

      return error
    }
  }
}

const fetchChannelAllVideosRequest = createAction(
  'content/fetchChannelAllVideosRequest'
)
const fetchChannelAllVideosFailure = createAction(
  'content/fetchChannelAllVideosFailure'
)

export function fetchChannelAllVideos(
  businessId: string,
  channelId: string,
  username: string,
  page: string
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchChannelAllVideosRequest())
      const response = await api.get(
        page ||
          `/bus/${businessId}/channels/${channelId}/users/${username}/videos`
      )
      const { videos, paging } = response.data
      dispatch(fetchChannelAllVideosSuccess({ username, videos, paging, page }))
    } catch (error) {
      dispatch(fetchChannelAllVideosFailure())

      return error
    }
  }
}

const fetchChannelOriginalVideosRequest = createAction(
  'content/fetchChannelOriginalVideosRequest'
)
const fetchChannelOriginalVideosFailure = createAction(
  'content/fetchChannelOriginalVideosFailure'
)

export function fetchChannelOriginalVideos(
  businessId: string,
  channelId: string,
  username: string,
  page: string
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchChannelOriginalVideosRequest())
      const response = await api.get(
        page ||
          `/bus/${businessId}/channels/${channelId}/users/${username}/created_videos`
      )
      const { videos, paging } = response.data
      dispatch(
        fetchChannelOriginalVideosSuccess({ username, videos, paging, page })
      )
    } catch (error) {
      dispatch(fetchChannelOriginalVideosFailure())

      return error
    }
  }
}

const repostVideoRequest = createAction('content/repostVideoRequest')
const repostVideoFailure = createAction('content/repostVideoFailure')

export function createRepost(
  businessId: string,
  channelId: string,
  videoId: string
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(repostVideoRequest())
      const response = await api.post(
        `/bus/${businessId}/channels/${channelId}/reposts`,
        { video_id: videoId }
      )
      dispatch(createRepostSuccess({ videoId }))
      notification.success({
        message: i18n.t('Added to Channel')
      })

      return response
    } catch (error) {
      dispatch(repostVideoFailure())

      return error
    }
  }
}

const deleteRepostRequest = createAction('content/deleteRepostRequest')
const deleteRepostFailure = createAction('content/deleteRepostFailure')

export function deleteRepost(
  businessId: string,
  channelId: string,
  videoId: string
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(deleteRepostRequest())
      const response = await api.delete(
        `/bus/${businessId}/channels/${channelId}/reposts`,
        { data: { video_id: videoId } }
      )
      dispatch(deleteRepostSuccess({ videoId }))

      return response
    } catch (error) {
      dispatch(deleteRepostFailure())
    }
  }
}

const fetchRecommendationRequest = createAction(
  'content/fetchRecommendationRequest'
)
const fetchRecommendationFailure = createAction(
  'content/fetchRecommendationFailure'
)

export function fetchRecommendation(
  businessId: string,
  channelId: string,
  page: string
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchRecommendationRequest())
      const response = await api.get(
        page ||
          `bus/${businessId}/channels/${channelId}/content_selections/recommended_videos`
      )
      const { videos, paging } = response.data
      dispatch(fetchRecommendationSuccess({ videos, paging, page }))

      return response
    } catch (error) {
      dispatch(fetchRecommendationFailure())

      return error
    }
  }
}
