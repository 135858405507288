import React, { useState } from 'react'

import { Divider, Skeleton } from 'antd'
import { Chart, Tooltip, Axis, Geom } from 'bizcharts'
import { Box, Select, SelectOption } from 'fwego'

import { Card, EmptyInsightsContent } from '.'
import { CHART_COLORS } from '../styles/theme'
import { convertTimeUnit } from '../utils/stats'

const MAX_LENGTH = 15

const getIndex = (objArray, key) => {
  let i = 0
  for (i; i < objArray.length; i++) {
    let value = objArray[i].key
    value = value[value.length - 1]
    if (key === value) return i
  }
}

type IProps = {
  barColor?: any
  colors?: any
  data: any[]
  dataIndexBy: string
  footer?: JSX.Element
  header?: JSX.Element
  height?: number
  loading?: boolean
  dropdownOptions: any[]
  tooltipUnit?: string
  tickCount?: number
}

const BarDropdownChartCard: React.FC<IProps> = ({
  barColor = CHART_COLORS[1],
  colors,
  data,
  dataIndexBy,
  footer,
  header,
  height = 100,
  loading,
  dropdownOptions,
  tooltipUnit,
  tickCount = 10
}) => {
  const [filter, setFilter] = useState<string[]>(
    Object.values(dropdownOptions[0].key)
  )
  data.sort((a, b) => b[filter[0]] - a[filter[0]])
  const getLabel = (value) => {
    const unit =
      dropdownOptions[getIndex(dropdownOptions, filter[0])].unit || ''
    if (unit === '%') return `${(value * 100).toFixed(1)}%`
    else if (unit === 's') return convertTimeUnit(value)
    else return `${value.toLocaleString()} ${unit}`
  }

  const scale = {
    [filter[0]]: {
      min: 0
    },
    [dataIndexBy]: {
      tickCount
    }
  }

  return (
    <Card height="100%" m="0">
      <Skeleton loading={loading}>
        {header}
        {data && data.length > 0 ? (
          <>
            <Select
              mb="large"
              onChange={(e) => {
                const value = e.target.value
                setFilter([value])
                data.sort((a, b) => b[value] - a[value])
              }}
              width="250"
            >
              {dropdownOptions.map((option, i) => {
                let value = option.key
                value = value[value.length - 1]
                const key = option.name

                return (
                  <SelectOption key={i} value={value}>
                    {key}
                  </SelectOption>
                )
              })}
            </Select>
            <Chart
              scale={scale}
              height={height}
              data={data}
              padding={[20, 30, 40, 30]}
              forceFit
            >
              <Tooltip />
              <Axis
                name={dataIndexBy}
                label={{
                  formatter(text) {
                    return text.length > MAX_LENGTH
                      ? text.substring(0, MAX_LENGTH) + '...'
                      : text
                  }
                }}
              />
              <Axis name={filter[0]} visible={false} key={filter[0]} />
              <Geom
                type="interval"
                position={`${dataIndexBy}*${filter[0]}`}
                size={40}
                color={colors || barColor}
                tooltip={[
                  `${dataIndexBy}*${filter[0]}*insights`,
                  (name, value, insight) => {
                    return {
                      title: tooltipUnit
                        ? `${name} (${insight.length} ${tooltipUnit})`
                        : name,
                      name: filter[0],
                      value: getLabel(value)
                    }
                  }
                ]}
              ></Geom>
            </Chart>
            {footer && (
              <>
                <Divider />
                {footer}
              </>
            )}
          </>
        ) : (
          <Box>
            <EmptyInsightsContent />
          </Box>
        )}
      </Skeleton>
    </Card>
  )
}

export default BarDropdownChartCard
