import React from 'react'

import { Box } from 'fwego'
import { useTranslation } from 'react-i18next'

import { PieChartCard, CardHeader } from '.'

type IProps = {
  data?: any[]
  loading?: boolean
  title?: string
  tooltip?: string
  headerExtra?: any
}

const RefererPieChartCard = ({
  data = [],
  loading,
  title,
  tooltip,
  headerExtra
}: IProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Box height="380">
      <PieChartCard
        data={data}
        dataKey="referer"
        dataTotalKey="total_views"
        titleTotal={t('Total Views')}
        padding={[0, 0, 0, 0]}
        header={
          <CardHeader title={title} tooltip={tooltip} extra={headerExtra} />
        }
        height={250}
        loading={loading}
      />
    </Box>
  )
}

export default RefererPieChartCard
