import React, { useEffect } from 'react'

import { Flex } from '@src/components/EmotionLayout'
import LoadingIndicator from '@src/components/LoadingIndicator'
import { AUTH_URL } from '@src/utils/api'

export default function HomePage() {
  const authUrl = AUTH_URL

  // redirect to auth url
  useEffect(() => {
    window.location.href = authUrl
  }, [authUrl])

  return (
    <Flex width="100%" m="large" justifyContent="center" alignContent="center">
      <LoadingIndicator />
    </Flex>
  )
}
