import React, {
  createContext,
  useContext,
  useMemo,
  useReducer,
  useRef
} from 'react'

import { ContextValue, VUActions, VUState } from './VU.types'

const initialState: VUState = {
  caption: '',
  description: null,
  access: 'public',
  showPrivateNotification: false,
  repostable: true,
  videoUrl: null,
  videoWH: undefined,
  selectedProductOptions: [],
  thumbnailUrl: null,
  mediaKey: null,
  videoUploaded: false,
  selectedProductIds: [],
  ctaLink: null,
  ctaType: null,
  ctaCustomLabel: null,
  questionObj: null,
  pollObj: null,
  showVideoOverlayModal: false,
  hashtagArray: [],
  confirmLoading: false,
  changed: false,
  newPosterPayload: null,
  newAnimatedPosterPayload: null,
  customGifAnimatedPosterPayload: null,
  newWidePosterPayload: null,
  changedPosters: false,
  showDiscardModal: false,
  publishTime: null,
  canEditPublishTime: true,
  originalPublishStartDate: null,
  clearSchedule: false,
  selectPlaylists: undefined,
  badge: null,
  subtitles: [],
  showUploadNow: false,
  showUploadNowNotification: false,
  searchedPlaylists: [],
  submitValues: {
    values: null,
    reset: () => null
  },
  has_access_code: false,
  accessCode: null,
  selectedInteractionType: null,
  remindMeDoubleOptIn: false
}

const stateActions = (
  dispatch: React.Dispatch<Partial<VUState>>
): VUActions => {
  return {
    setCaption: (caption) => dispatch({ caption }),
    setDescription: (description) => dispatch({ description }),
    setAccess: (access) => dispatch({ access }),
    setShowPrivateNotification: (showPrivateNotification) =>
      dispatch({ showPrivateNotification }),
    setRepostable: (repostable) => dispatch({ repostable }),
    setVideoUrl: (videoUrl) => dispatch({ videoUrl }),
    setVideoWH: (videoWH) => dispatch({ videoWH }),
    setThumbnailUrl: (thumbnailUrl) => dispatch({ thumbnailUrl }),
    setMediaKey: (mediaKey) => dispatch({ mediaKey }),
    setVideoUploaded: (videoUploaded) => dispatch({ videoUploaded }),
    setSelectedProductIds: (selectedProductIds) =>
      dispatch({ selectedProductIds }),
    setSelectedProductOptions: (selectedProductOptions) =>
      dispatch({ selectedProductOptions }),
    setCtaLink: (ctaLink) => dispatch({ ctaLink }),
    setCtaType: (ctaType) => dispatch({ ctaType }),
    setCtaCustomLabel: (ctaCustomLabel) => dispatch({ ctaCustomLabel }),
    setQuestionObj: (questionObj) => dispatch({ questionObj }),
    setPollObj: (pollObj) => dispatch({ pollObj }),
    setShowVideoOverlayModal: (showVideoOverlayModal) =>
      dispatch({ showVideoOverlayModal }),
    setHashtagArray: (hashtagArray) => dispatch({ hashtagArray }),
    setConfirmLoading: (confirmLoading) => dispatch({ confirmLoading }),
    setChanged: (changed) => dispatch({ changed }),
    setNewPosterPayload: (newPosterPayload) => dispatch({ newPosterPayload }),
    setNewAnimatedPosterPayload: (newAnimatedPosterPayload) =>
      dispatch({ newAnimatedPosterPayload }),
    setCustomGifAnimatedPosterPayload: (customGifAnimatedPosterPayload) =>
      dispatch({ customGifAnimatedPosterPayload }),
    setNewWidePosterPayload: (newWidePosterPayload) =>
      dispatch({ newWidePosterPayload }),
    setChangedPosters: (changedPosters) =>
      dispatch({ changedPosters, changed: true }),
    setShowDiscardModal: (showDiscardModal) => dispatch({ showDiscardModal }),
    setPublishTime: (publishTime) => dispatch({ publishTime }),
    setCanEditPublishTime: (canEditPublishTime) =>
      dispatch({ canEditPublishTime }),
    setClearSchedule: (clearSchedule) => dispatch({ clearSchedule }),
    setSelectPlaylists: (selectPlaylists) => dispatch({ selectPlaylists }),
    setBadge: (badge) => dispatch({ badge }),
    setSubtitles: (subtitles) => dispatch({ subtitles }),
    setShowUploadNow: (showUploadNow) => dispatch({ showUploadNow }),
    setOriginalPublishStartDate: (originalPublishStartDate) =>
      dispatch({ originalPublishStartDate }),
    setShowUploadNowNotification: (showUploadNowNotification) =>
      dispatch({ showUploadNowNotification }),
    setSearchedPlaylists: (searchedPlaylists) =>
      dispatch({ searchedPlaylists }),
    setSubmitValues: (submitValues) => dispatch({ submitValues }),
    setAccessCode: (accessCode) => dispatch({ accessCode }),
    setSelectedInteractionType: (selectedInteractionType) =>
      dispatch({ selectedInteractionType }),
    setRemindMeDoubleOptIn: (remindMeDoubleOptIn) =>
      dispatch({ remindMeDoubleOptIn })
  }
}

/** REDUCER */
const reducer = (state: VUState, payload: Partial<VUState>): VUState => {
  const newState = { ...state, ...payload }
  if (payload.ctaLink) {
    newState.selectedProductIds = []
    newState.selectedProductOptions = []
    newState.questionObj = null
    newState.pollObj = null
  }
  if (payload.questionObj) {
    newState.selectedProductIds = []
    newState.selectedProductOptions = []
    newState.ctaLink = null
    newState.pollObj = null
  }
  if (payload.pollObj) {
    newState.selectedProductIds = []
    newState.selectedProductOptions = []
    newState.questionObj = null
    newState.ctaLink = null
  }
  if (payload.selectedProductOptions) {
    newState.ctaLink = null
    newState.questionObj = null
    newState.pollObj = null
  }

  return newState
}

/** CONTEXT PROVIDER */
export const VUContext = createContext<ContextValue | undefined>(undefined)

const VUProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const actions = useMemo(() => stateActions(dispatch), [dispatch])
  const playerRef = useRef<HTMLVideoElement>(null)

  return (
    <VUContext.Provider value={{ state, actions, playerRef }}>
      {children}
    </VUContext.Provider>
  )
}

/** Hook wrapper for useContext **/
export const useVUContext = (): ContextValue => {
  const ctx = useContext(VUContext)
  if (!ctx) throw new Error('useVUContext must be used within VUProvider')
  else return ctx
}

export default VUProvider
