import { createAsyncThunk } from '@reduxjs/toolkit'
import { FLOW_LINK_TARGET_TYPES } from '@src/constants'
import api from '@src/utils/api'
import { sanitizeInteractionData } from '@src/utils/sanitize'

const SLICE_NAME = 'flows'

type OrderPayload = {
  flow_links: {
    id: string
    link_order: number
  }[]
}

const ApiClient = (businessId: string, channelId: string) => {
  const API_ROOT = `/bus/${businessId}/channels/${channelId}/flows`
  const LINKS_API_ROOT = `/bus/${businessId}/channels/${channelId}/flow_interactions`

  return {
    fetchChannelVideos: (params: {
      page?: globalLib.Paging
      searchQuery?: string
      filter?: string
      sort?: string
    }) =>
      params?.page?.next
        ? api.get(params?.page?.next)
        : api.get(`/bus/${businessId}/videos`, {
            params: {
              channel_id: channelId,
              query: params?.searchQuery,
              filter: params?.filter,
              is_live_stream: false,
              sort: params?.sort || 'id_desc',
              exclude_reposts: true,
              exclude_flow_videos: true
            }
          }),
    fetchFlows: (params: { page?: globalLib.Paging }) =>
      params?.page?.next
        ? api.get(params?.page?.next)
        : api.get(API_ROOT, { params }),
    fetchFlow: (flowId: string) => api.get(`${API_ROOT}/${flowId}`),
    createFlow: (flowPayload: { name: string; start_video_id: string }) =>
      api.post(`${API_ROOT}`, flowPayload),
    patchFlow: (flowId: string, flowPayload: { name: string }) =>
      api.patch(`${API_ROOT}/${flowId}`, flowPayload),
    deleteFlow: (flowId: string) => api.delete(`${API_ROOT}/${flowId}`),
    fetchFlowInteractions: (
      flowId: string,
      params: { page?: globalLib.Paging }
    ) =>
      params?.page?.next
        ? api.get(params?.page?.next)
        : api.get(`${API_ROOT}/${flowId}/flow_interactions`, { params }),
    createFlowInteraction: (
      flowId: string,
      flowInteractionPayload: Partial<globalLib.FlowInteraction>
    ) =>
      api.post(
        `${API_ROOT}/${flowId}/flow_interactions`,
        flowInteractionPayload
      ),
    patchFlowInteraction: (
      flowId: string,
      flowInteractionId: string,
      flowInteractionPayload: Partial<globalLib.FlowInteraction>
    ) =>
      api.patch(
        `${API_ROOT}/${flowId}/flow_interactions/${flowInteractionId}`,
        flowInteractionPayload
      ),
    deleteFlowInteraction: (flowId: string, flowInteractionId: string) =>
      api.delete(
        `${API_ROOT}/${flowId}/flow_interactions/${flowInteractionId}`
      ),
    createFlowLink: (
      flowInteractionId: string,
      flowLinkPayload: Partial<globalLib.FlowLink>
    ) =>
      api.post(
        `${LINKS_API_ROOT}/${flowInteractionId}/flow_links`,
        flowLinkPayload
      ),
    patchFlowLink: (
      flowInteractionId: string,
      flowLinkId: string,
      flowLinkPayload: Partial<globalLib.FlowLink>
    ) =>
      api.patch(
        `${LINKS_API_ROOT}/${flowInteractionId}/flow_links/${flowLinkId}`,
        flowLinkPayload
      ),
    deleteFlowLink: (flowInteractionId: string, flowLinkId: string) =>
      api.delete(
        `${LINKS_API_ROOT}/${flowInteractionId}/flow_links/${flowLinkId}`
      ),
    updateChannelVideo: (videoId: string, data: any) =>
      api.patch(
        `/bus/${businessId}/channels/${channelId}/videos/${videoId}`,
        data
      ),
    fetchFlowVideos: (flowId: string, page?: globalLib.Paging) =>
      page?.next
        ? api.get(page?.next)
        : api.get(`${API_ROOT}/${flowId}/videos`),
    patchChoiceOrder: (flowInteractionId: string, orderPayload: OrderPayload) =>
      api.post(
        `${LINKS_API_ROOT}/${flowInteractionId}/flow_links/bulk`,
        orderPayload
      )
  }
}

export const fetchChannelVideos = createAsyncThunk(
  `${SLICE_NAME}/fetchChannelVideos`,
  async (params: {
    businessId: string
    channelId: string
    page?: globalLib.Paging
    searchQuery?: string
    filter?: string
    sort?: string
  }) => {
    const request = await ApiClient(
      params.businessId,
      params.channelId
    ).fetchChannelVideos({
      page: params?.page,
      searchQuery: params?.searchQuery,
      filter: params?.filter,
      sort: params?.sort
    })

    const response = await request

    const { videos, paging } = response.data

    return {
      videos,
      paging,
      page: params.page
    }
  }
)

export const fetchFlows = createAsyncThunk(
  `${SLICE_NAME}/fetchFlows`,
  async (params: {
    businessId: string
    channelId: string
    page?: globalLib.Paging
  }) => {
    const request = await ApiClient(
      params.businessId,
      params.channelId
    ).fetchFlows({
      page: params?.page
    })

    const response = await request

    const { flows, paging } = response.data

    return {
      flows,
      paging,
      page: params.page
    }
  }
)

export const fetchFlow = createAsyncThunk(
  `${SLICE_NAME}/fetchFlow`,
  async (params: { businessId: string; channelId: string; flowId: string }) => {
    const request = await ApiClient(
      params.businessId,
      params.channelId
    ).fetchFlow(params.flowId)

    const response = await request

    return response.data
  }
)

export const createFlow = createAsyncThunk(
  `${SLICE_NAME}/createFlow`,
  async (params: {
    businessId: string
    channelId: string
    flowPayload: {
      name: string
      startVideoId: string
    }
  }) => {
    const response = await ApiClient(
      params.businessId,
      params.channelId
    ).createFlow({
      name: params.flowPayload.name,
      start_video_id: params.flowPayload.startVideoId
    })

    const { data } = response

    return data as globalLib.Flow
  }
)

export const patchFlow = createAsyncThunk(
  `${SLICE_NAME}/patchFlow`,
  async (params: {
    businessId: string
    channelId: string
    flowId: string
    flowPayload: {
      name: string
    }
  }) => {
    await ApiClient(params.businessId, params.channelId).patchFlow(
      params.flowId,
      {
        name: params.flowPayload.name
      }
    )

    return {
      flowId: params.flowId,
      name: params.flowPayload.name
    }
  }
)

export const deleteFlow = createAsyncThunk(
  `${SLICE_NAME}/deleteFlow`,
  async (params: { businessId: string; channelId: string; flowId: string }) => {
    await ApiClient(params.businessId, params.channelId).deleteFlow(
      params.flowId
    )

    return {
      flowId: params.flowId
    }
  }
)

export const updateFlowVideo = createAsyncThunk(
  `${SLICE_NAME}/updateFlowVideo`,
  async (params: {
    businessId: string
    channelId: string
    videoId: string
    data: any
  }) => {
    const response = await ApiClient(
      params.businessId,
      params.channelId
    ).updateChannelVideo(params.videoId, params.data)

    return {
      videoId: params.videoId,
      data: response.data
    }
  }
)

export const createVideoInteraction = createAsyncThunk(
  `${SLICE_NAME}/createVideoInteraction`,
  async (params: { videoId: string; data: any }) => {
    const response = await api.post(
      `/videos/${params.videoId}/interactions`,
      sanitizeInteractionData(params.data)
    )

    return {
      videoId: params.videoId,
      data: response.data
    }
  }
)

export const updateVideoInteraction = createAsyncThunk(
  `${SLICE_NAME}/updateVideoInteraction`,
  async (params: { videoId: string; interactionId: string; data: any }) => {
    const response = await api.patch(
      `/videos/${params.videoId}/interactions/${params.interactionId}`,
      sanitizeInteractionData(params.data)
    )

    return {
      videoId: params.videoId,
      data: response.data
    }
  }
)

export const deleteVideoInteraction = createAsyncThunk(
  `${SLICE_NAME}/deleteVideoInteraction`,
  async (params: { videoId: string; interactionId: string }) => {
    await api.delete(
      `/videos/${params.videoId}/interactions/${params.interactionId}`
    )

    return {
      videoId: params.videoId,
      interactionId: params.interactionId
    }
  }
)

export const fetchFlowInteractions = createAsyncThunk(
  `${SLICE_NAME}/fetchFlowInteractions`,
  async (params: {
    businessId: string
    channelId: string
    flowId: string
    page?: globalLib.Paging
  }) => {
    const response = await ApiClient(
      params.businessId,
      params.channelId
    ).fetchFlowInteractions(params.flowId, {
      page: params?.page
    })

    const { data } = response

    const { flow_interactions, paging } = data

    return {
      flowInteractions: flow_interactions,
      paging,
      page: params.page
    }
  }
)

export const postFlowInteraction = createAsyncThunk(
  `${SLICE_NAME}/postFlowInteraction`,
  async (params: {
    businessId: string
    channelId: string
    flowId: string
    data: Partial<globalLib.FlowInteraction>
  }) => {
    const response = await ApiClient(
      params.businessId,
      params.channelId
    ).createFlowInteraction(params.flowId, {
      type: params.data?.type,
      shows_in: 0,
      prompt_text: params.data?.prompt_text,
      video_id: params.data?.video_id
    })

    const { data } = response

    return data as globalLib.FlowInteraction
  }
)

export const deleteFlowInteraction = createAsyncThunk(
  `${SLICE_NAME}/deleteFlowInteraction`,
  async (params: {
    businessId: string
    channelId: string
    flowId: string
    interactionId: string
  }) => {
    await ApiClient(params.businessId, params.channelId).deleteFlowInteraction(
      params.flowId,
      params.interactionId
    )

    return {
      flowId: params.flowId,
      interactionId: params.interactionId
    }
  }
)

export const patchFlowInteraction = createAsyncThunk(
  `${SLICE_NAME}/patchFlowInteraction`,
  async (params: {
    businessId: string
    channelId: string
    flowId: string
    interactionId: string
    data: Partial<globalLib.FlowInteraction>
  }) => {
    const response = await ApiClient(
      params.businessId,
      params.channelId
    ).patchFlowInteraction(params.flowId, params.interactionId, {
      type: params.data?.type,
      shows_in: params.data?.shows_in,
      prompt_text: params.data?.prompt_text
    })

    const { data } = response

    return data as globalLib.FlowInteraction
  }
)

export const postFlowLink = createAsyncThunk(
  `${SLICE_NAME}/postFlowLink`,
  async (params: {
    businessId: string
    channelId: string
    flowInteractionId: string
    link_order: number
  }) => {
    const response = await ApiClient(
      params.businessId,
      params.channelId
    ).createFlowLink(params.flowInteractionId, {
      target_type: FLOW_LINK_TARGET_TYPES.VIDEO,
      target_video_id: null,
      link_order: params.link_order
    })

    const { data } = response

    return data as globalLib.FlowLink
  }
)

export const patchFlowLink = createAsyncThunk(
  `${SLICE_NAME}/patchFlowLink`,
  async (params: {
    businessId: string
    channelId: string
    flowInteractionId: string
    flowLinkId: string
    data: Partial<globalLib.FlowLink>
  }) => {
    const response = await ApiClient(
      params.businessId,
      params.channelId
    ).patchFlowLink(params.flowInteractionId, params.flowLinkId, {
      target_type: params.data?.target_type,
      target_video_id: params.data?.target_video_id || null,
      text: params.data?.text
    })

    const { data } = response

    return data as globalLib.FlowLink
  }
)

export const deleteFlowLink = createAsyncThunk(
  `${SLICE_NAME}/deleteFlowLink`,
  async (params: {
    businessId: string
    channelId: string
    flowInteractionId: string
    flowLinkId: string
  }) => {
    await ApiClient(params.businessId, params.channelId).deleteFlowLink(
      params.flowInteractionId,
      params.flowLinkId
    )

    return {
      flowLinkId: params.flowLinkId
    }
  }
)

export const fetchFlowVideos = createAsyncThunk(
  `${SLICE_NAME}/fetchFlowVideos`,
  async (params: {
    businessId: string
    channelId: string
    flowId: string
    page?: globalLib.Paging
  }) => {
    const response = await ApiClient(
      params.businessId,
      params.channelId
    ).fetchFlowVideos(params.flowId, params.page)

    const { data } = response

    const { videos, paging } = data

    return {
      videos,
      paging,
      page: params.page
    }
  }
)

export const patchChoiceOrder = createAsyncThunk(
  `${SLICE_NAME}/patchChoiceOrder`,
  async (params: {
    businessId: string
    channelId: string
    flowInteractionId: string
    orderPayload: OrderPayload
  }) => {
    const response = await ApiClient(
      params.businessId,
      params.channelId
    ).patchChoiceOrder(params.flowInteractionId, params.orderPayload)

    const { data } = response

    return data as globalLib.FlowLink[]
  }
)
