import React from 'react'

import {
  LeftCircleFilled,
  RightCircleFilled,
  CloseCircleFilled
} from '@ant-design/icons'
import { css } from '@emotion/core'
import { FWStoryblock } from '@src/components/FWStoryblock'
import { Flex, Box } from 'fwego'

import { useSpace, isLgScreen } from '../hooks'

type IProps = {
  visible?: boolean
  videos?: globalLib.IVideo[]
  muted?: boolean
  currentIndex?: number
  onVideoChange?: (val: number) => void
  onClose?: () => void
  overlay?: string
  channelId?: string
}

const VideoPlayer = ({
  visible,
  videos,
  muted = true,
  currentIndex,
  onVideoChange,
  onClose,
  channelId
}: IProps): JSX.Element => {
  const spaceMd = useSpace('medium')
  const spaceSm = useSpace('small')
  const lgScreen = isLgScreen()

  const video = videos[currentIndex]

  return (
    <>
      {!!visible && currentIndex !== -1 && (
        <Flex
          width="100%"
          height="100%"
          position="fixed"
          top={0}
          left={0}
          overflow="hidden"
          bg="#000000cc"
          alignItems="center"
          justifyContent="center"
          onClick={onClose}
          zIndex={9999999}
        >
          <Flex flexDirection="row" alignItems="center">
            <LeftCircleFilled
              css={css`
                padding: ${lgScreen ? spaceMd : spaceSm};
                color: white;
                font-size: ${lgScreen ? '50px' : '24px'};
                font-weight: bold;
                visibility: ${currentIndex > 0 ? 'visible' : 'hidden'};
              `}
              onClick={(e) => {
                e.stopPropagation()
                onVideoChange(currentIndex - 1)
              }}
            />
            <Box position="relative">
              {video.download_url ? (
                <>
                  <CloseCircleFilled
                    css={css`
                      position: absolute;
                      top: 10px;
                      right: 10px;
                      color: grey;
                      opacity: 0.8;
                      font-size: ${lgScreen ? '28px' : '20px'};
                      z-index: 10;
                    `}
                    onClick={(e) => {
                      e.stopPropagation()
                      onClose()
                    }}
                  />
                  <video
                    controls
                    loop
                    muted={muted}
                    playsInline
                    autoPlay
                    disablePictureInPicture
                    controlsList="nodownload"
                    src={video.download_url}
                    onClick={(e) => e.stopPropagation()}
                    css={css`
                      max-width: ${lgScreen ? '90vw' : '70vw'};
                      max-height: ${lgScreen ? '90vh' : '70vh'};
                      border-radius: 12px;
                    `}
                  />
                </>
              ) : (
                <div
                  css={css`
                    height: ${lgScreen ? '90vh' : '70vh'};
                    aspect-ratio: 9 / 16;
                  `}
                  id="storyblock_container"
                >
                  <FWStoryblock
                    channel={video.creator && video.creator?.username}
                    channel_id={channelId}
                    video={video.encoded_id}
                    loop="true"
                    height="100%"
                    player_fullscreen="false"
                    player_minimize="false"
                  />
                </div>
              )}
            </Box>
            <RightCircleFilled
              css={css`
                padding: ${lgScreen ? spaceMd : spaceSm};
                color: white;
                font-size: ${lgScreen ? '50px' : '24px'};
                font-weight: bold;
                visibility: ${currentIndex < videos.length - 1
                  ? 'visible'
                  : 'hidden'};
              `}
              onClick={(e) => {
                e.stopPropagation()
                onVideoChange(currentIndex + 1)
              }}
            />
          </Flex>
        </Flex>
      )}
    </>
  )
}

export default VideoPlayer
