import { createAction, createSlice } from '@reduxjs/toolkit'
import { BatchImporterMediaType } from '@src/components/BatchImporter/BatchImporterMediaModel'
import {
  IgHashtagMedia,
  IgHashtagMediaRes,
  IPublicPage
} from '@src/components/IgHashtagImporter/igHashtagImporterModel'
import { FB_URL, getFbToken } from '@src/utils/fbAuth'
import { Dispatch } from 'redux'

type IProps = {
  igHashtagRecentMediaHashtagId: string
  igHashtagRecentMediaData: IgHashtagMediaRes
  igHashtagRecentMediaList: IgHashtagMedia[]
  igHashtagRecentMediaUrl: string[]
  igHashtagTopMediaHashtagId: string
  igHashtagTopMediaData: IgHashtagMediaRes
  igHashtagTopMediaList: IgHashtagMedia[]
  igHashtagTopMediaUrl: string[]
  igAccountMediaUserId: string
  igAccountMediaData: IgHashtagMediaRes
  igAccountMediaList: IgHashtagMedia[]
  igAccountMediaUrl: string[]
  igUsernameMediaUsername: string
  igUsernameMediaData: IgHashtagMediaRes
  igUsernameMediaList: IgHashtagMedia[]
  igUsernameMediaUrl: string[]
  igAccountStoryUserId: string
  igAccountStoryData: IgHashtagMediaRes
  igAccountStoryList: IgHashtagMedia[]
  igAccountStoryUrl: string[]
  igAccountTagsUserId: string
  igAccountTagsData: IgHashtagMediaRes
  igAccountTagsList: IgHashtagMedia[]
  igAccountTagsUrl: string[]
}

const initialState: IProps = {
  igHashtagRecentMediaHashtagId: undefined,
  igHashtagRecentMediaData: undefined,
  igHashtagRecentMediaList: [],
  igHashtagRecentMediaUrl: [],
  igHashtagTopMediaHashtagId: undefined,
  igHashtagTopMediaData: undefined,
  igHashtagTopMediaList: [],
  igHashtagTopMediaUrl: [],
  igAccountMediaUserId: undefined,
  igAccountMediaData: undefined,
  igAccountMediaList: [],
  igAccountMediaUrl: [],
  igUsernameMediaUsername: undefined,
  igUsernameMediaData: undefined,
  igUsernameMediaList: [],
  igUsernameMediaUrl: [],
  igAccountStoryUserId: undefined,
  igAccountStoryData: undefined,
  igAccountStoryList: [],
  igAccountStoryUrl: [],
  igAccountTagsUserId: undefined,
  igAccountTagsData: undefined,
  igAccountTagsList: [],
  igAccountTagsUrl: []
}

const slice = createSlice({
  name: 'igHashtagImporter',
  initialState: initialState,
  reducers: {
    fetchIgHashtagRecentMediaSuccess(state, action) {
      const { hashtagId, igMediaData = {}, url } = action.payload
      const list =
        igMediaData.data?.filter((item) => {
          return (
            ((item.media_type === BatchImporterMediaType.VIDEO ||
              item.media_type === BatchImporterMediaType.IMAGE) &&
              !!item.media_url) ||
            item.media_type === BatchImporterMediaType.CAROUSEL_ALBUM
          )
        }) || []

      if (!url) {
        state.igHashtagRecentMediaHashtagId = hashtagId
        state.igHashtagRecentMediaData = igMediaData
        state.igHashtagRecentMediaList = list
        state.igHashtagRecentMediaUrl = []
      } else {
        if (!state.igHashtagRecentMediaUrl.includes(url)) {
          state.igHashtagRecentMediaHashtagId = hashtagId
          state.igHashtagRecentMediaData = igMediaData
          state.igHashtagRecentMediaList = state.igHashtagRecentMediaList.concat(
            list
          )
          state.igHashtagRecentMediaUrl = state.igHashtagRecentMediaUrl.concat(
            url
          )
        }
      }
    },
    fetchIgHashtagTopMediaSuccess(state, action) {
      const { hashtagId, igMediaData = {}, url } = action.payload
      const list =
        igMediaData.data?.filter((item) => {
          return (
            ((item.media_type === BatchImporterMediaType.VIDEO ||
              item.media_type === BatchImporterMediaType.IMAGE) &&
              !!item.media_url) ||
            item.media_type === BatchImporterMediaType.CAROUSEL_ALBUM
          )
        }) || []

      if (!url) {
        state.igHashtagTopMediaHashtagId = hashtagId
        state.igHashtagTopMediaData = igMediaData
        state.igHashtagTopMediaList = list
        state.igHashtagTopMediaUrl = []
      } else {
        state.igHashtagTopMediaHashtagId = hashtagId
        state.igHashtagTopMediaData = igMediaData
        state.igHashtagTopMediaList = state.igHashtagTopMediaList.concat(list)
        state.igHashtagTopMediaUrl = state.igHashtagTopMediaUrl.concat(url)
      }
    },
    fetchIgAccountMediaSuccess(state, action) {
      const { userId, igMediaData = {}, url } = action.payload
      const list =
        igMediaData.data?.filter((item) => {
          return (
            ((item.media_type === BatchImporterMediaType.VIDEO ||
              item.media_type === BatchImporterMediaType.IMAGE) &&
              !!item.media_url) ||
            item.media_type === BatchImporterMediaType.CAROUSEL_ALBUM
          )
        }) || []

      if (!url) {
        state.igAccountMediaUserId = userId
        state.igAccountMediaData = igMediaData
        state.igAccountMediaList = list
        state.igAccountMediaUrl = []
      } else {
        if (!state.igAccountMediaUrl.includes(url)) {
          state.igAccountMediaUserId = userId
          state.igAccountMediaData = igMediaData
          state.igAccountMediaList = state.igAccountMediaList.concat(list)
          state.igAccountMediaUrl = state.igAccountMediaUrl.concat(url)
        }
      }
    },
    fetchIgAccountStorySuccess(state, action) {
      const { userId, igMediaData = {}, url } = action.payload
      const list =
        igMediaData.data?.filter((item) => {
          return (
            ((item.media_type === BatchImporterMediaType.VIDEO ||
              item.media_type === BatchImporterMediaType.IMAGE) &&
              !!item.media_url) ||
            item.media_type === BatchImporterMediaType.CAROUSEL_ALBUM
          )
        }) || []

      if (!url) {
        state.igAccountStoryUserId = userId
        state.igAccountStoryData = igMediaData
        state.igAccountStoryList = list
        state.igAccountStoryUrl = []
      } else {
        if (!state.igAccountStoryUrl.includes(url)) {
          state.igAccountStoryUserId = userId
          state.igAccountStoryData = igMediaData
          state.igAccountStoryList = state.igAccountStoryList.concat(list)
          state.igAccountStoryUrl = state.igAccountStoryUrl.concat(url)
        }
      }
    },
    fetchIgAccountTagsSuccess(state, action) {
      const { userId, igMediaData = {}, url } = action.payload
      const list =
        igMediaData.data?.filter((item) => {
          return (
            ((item.media_type === BatchImporterMediaType.VIDEO ||
              item.media_type === BatchImporterMediaType.IMAGE) &&
              !!item.media_url) ||
            item.media_type === BatchImporterMediaType.CAROUSEL_ALBUM
          )
        }) || []

      if (!url) {
        state.igAccountTagsUserId = userId
        state.igAccountTagsData = igMediaData
        state.igAccountTagsList = list
        state.igAccountTagsUrl = []
      } else {
        if (!state.igAccountTagsUrl.includes(url)) {
          state.igAccountTagsUserId = userId
          state.igAccountTagsData = igMediaData
          state.igAccountTagsList = state.igAccountTagsList.concat(list)
          state.igAccountTagsUrl = state.igAccountTagsUrl.concat(url)
        }
      }
    },
    fetchIgUsernameMediaSuccess(state, action) {
      const { username, igMediaData = {}, url } = action.payload
      const list =
        igMediaData.data?.filter((item) => {
          return (
            ((item.media_type === BatchImporterMediaType.VIDEO ||
              item.media_type === BatchImporterMediaType.IMAGE) &&
              !!item.media_url) ||
            item.media_type === BatchImporterMediaType.CAROUSEL_ALBUM
          )
        }) || []

      if (!url) {
        state.igUsernameMediaUsername = username
        state.igUsernameMediaData = igMediaData
        state.igUsernameMediaList = list
        state.igUsernameMediaUrl = []
      } else {
        if (!state.igUsernameMediaUrl.includes(url)) {
          state.igUsernameMediaUsername = username
          state.igUsernameMediaData = igMediaData
          state.igUsernameMediaList = state.igUsernameMediaList.concat(list)
          state.igUsernameMediaUrl = state.igUsernameMediaUrl.concat(url)
        }
      }
    },
    clearIgHashtagRecentMediaData(state) {
      state.igHashtagRecentMediaHashtagId = undefined
      state.igHashtagRecentMediaData = undefined
      state.igHashtagRecentMediaList = []
      state.igHashtagRecentMediaUrl = []
    },
    clearIgHashtagTopMediaData(state) {
      state.igHashtagTopMediaHashtagId = undefined
      state.igHashtagTopMediaData = undefined
      state.igHashtagTopMediaList = []
      state.igHashtagTopMediaUrl = []
    },
    clearIgAccountMediaData(state) {
      state.igAccountMediaUserId = undefined
      state.igAccountMediaData = undefined
      state.igAccountMediaList = []
      state.igAccountMediaUrl = []
    },
    clearIgAccountStoryData(state) {
      state.igAccountStoryUserId = undefined
      state.igAccountStoryData = undefined
      state.igAccountStoryList = []
      state.igAccountStoryUrl = []
    },
    clearIgAccountTagsData(state) {
      state.igAccountTagsUserId = undefined
      state.igAccountTagsData = undefined
      state.igAccountTagsList = []
      state.igAccountTagsUrl = []
    },
    clearIgUsernameMediaData(state) {
      state.igUsernameMediaUsername = undefined
      state.igUsernameMediaData = undefined
      state.igUsernameMediaList = []
      state.igUsernameMediaUrl = []
    }
  }
})

export default slice.reducer
export const {
  fetchIgHashtagRecentMediaSuccess,
  clearIgHashtagRecentMediaData,
  fetchIgHashtagTopMediaSuccess,
  clearIgHashtagTopMediaData,
  fetchIgAccountMediaSuccess,
  fetchIgAccountStorySuccess,
  fetchIgAccountTagsSuccess,
  clearIgAccountMediaData,
  clearIgAccountStoryData,
  clearIgAccountTagsData,
  fetchIgUsernameMediaSuccess,
  clearIgUsernameMediaData
} = slice.actions

export function fetchMeAccount(userId: string) {
  return async (): Promise<IPublicPage> => {
    try {
      const curFbToken = getFbToken(userId)
      const meAccountRes = await fetch(
        `${FB_URL}/me/accounts?fields=name,instagram_business_account{username,name,ig_id}&access_token=${curFbToken.accessToken}`
      )
      const data = (await meAccountRes.json()) as any
      const list = data.data || []

      return list.map((item) => {
        return {
          name: item.name,
          id: item.id,
          instagramBusinessAccount: item.instagram_business_account
            ? {
                username: item.instagram_business_account.username,
                name: item.instagram_business_account.name,
                igId: item.instagram_business_account.ig_id,
                id: item.instagram_business_account.id
              }
            : undefined
        }
      })
    } catch (error) {
      return null
    }
  }
}

export function fetchIgHashtagSearch(
  igBusinessAccountId: string,
  q: string,
  userId: string
) {
  return async (): Promise<any> => {
    try {
      const curFbToken = getFbToken(userId)
      const res = await fetch(
        `${FB_URL}/ig_hashtag_search?user_id=${igBusinessAccountId}&q=${q}&access_token=${curFbToken.accessToken}`
      )
      const data = (await res.json()) as any
      const list = data.data || []
      if (!list.length && data.error?.code) {
        return {
          error: {
            ...data.error
          }
        }
      }

      return {
        hashtagId: list[0]?.id
      }
    } catch (error) {
      return error
    }
  }
}

export function fetchInstagramBusinessAccount(
  publicPageId: string,
  userId: string
) {
  return async (): Promise<string> => {
    try {
      const curFbToken = getFbToken(userId)
      const res = await fetch(
        `${FB_URL}/${publicPageId}?fields=instagram_business_account{username,name,ig_id}&access_token=${curFbToken.accessToken}`
      )
      const data = (await res.json()) as any

      return data?.instagram_business_account?.id
    } catch (error) {
      return null
    }
  }
}

const fetchIgHashtagRecentMediaRequest = createAction(
  'igHashtagImporter/fetchRecentMediaRequest'
)
const fetchIgHashtagRecentMediaFailure = createAction(
  'igHashtagImporter/fetchRecentMediaFailure'
)

export function fetchRecentMedia(
  igBusinessAccountId: string,
  hashtagId: string,
  userId: string,
  url?: string
) {
  return async (dispatch: Dispatch): Promise<IgHashtagMediaRes> => {
    try {
      dispatch(fetchIgHashtagRecentMediaRequest())
      const igToken = getFbToken(userId)
      const response = await fetch(
        url
          ? url
          : `${FB_URL}/${hashtagId}/recent_media?user_id=${igBusinessAccountId}&fields=id,caption,timestamp,media_type,media_url,permalink,comments_count,like_count,children{id,media_type,media_url}&limit=20&access_token=${igToken.accessToken}`
      )
      const data = (await response.json()) as IgHashtagMediaRes
      dispatch(
        fetchIgHashtagRecentMediaSuccess({
          hashtagId,
          igMediaData: data,
          url
        })
      )

      return data
    } catch (error) {
      dispatch(fetchIgHashtagRecentMediaFailure())
    }
  }
}

const fetchIgHashtagTopMediaRequest = createAction(
  'igHashtagImporter/fetchTopMediaRequest'
)
const fetchIgHashtagTopMediaFailure = createAction(
  'igHashtagImporter/fetchTopMediaFailure'
)
export function fetchTopMedia(
  igBusinessAccountId: string,
  hashtagId: string,
  userId: string,
  url?: string
) {
  return async (dispatch: Dispatch): Promise<IgHashtagMediaRes> => {
    try {
      dispatch(fetchIgHashtagTopMediaRequest())
      const igToken = getFbToken(userId)
      const response = await fetch(
        url
          ? url
          : `${FB_URL}/${hashtagId}/top_media?user_id=${igBusinessAccountId}&fields=id,caption,timestamp,media_type,media_url,permalink,comments_count,like_count,children{id,media_type,media_url}&limit=20&access_token=${igToken.accessToken}`
      )
      const data = (await response.json()) as IgHashtagMediaRes
      dispatch(
        fetchIgHashtagTopMediaSuccess({
          hashtagId,
          igMediaData: data,
          url
        })
      )

      return data
    } catch (error) {
      dispatch(fetchIgHashtagTopMediaFailure())
    }
  }
}

const fetchIgAccountMediaRequest = createAction(
  'igHashtagImporter/fetchAccountMediaRequest'
)
const fetchIgAccountMediaFailure = createAction(
  'igHashtagImporter/fetchAccountMediaFailure'
)
export function fetchAccountMedia(
  igBusinessAccountId: string,
  userId: string,
  url?: string
) {
  return async (dispatch: Dispatch): Promise<IgHashtagMediaRes> => {
    try {
      dispatch(fetchIgAccountMediaRequest())
      const igToken = getFbToken(userId)
      const response = await fetch(
        url
          ? url
          : `${FB_URL}/${igBusinessAccountId}/media?user_id=${igBusinessAccountId}&fields=id,caption,timestamp,username,media_type,media_url,permalink,comments_count,like_count,children{id,media_type,media_url}&limit=20&access_token=${igToken.accessToken}`
      )
      const data = (await response.json()) as IgHashtagMediaRes
      dispatch(
        fetchIgAccountMediaSuccess({
          userId,
          igMediaData: data,
          url
        })
      )

      return data
    } catch (error) {
      dispatch(fetchIgAccountMediaFailure())

      return error
    }
  }
}

const fetchIgAccountStoryRequest = createAction(
  'igHashtagImporter/fetchAccountStoryRequest'
)
const fetchIgAccountStoryFailure = createAction(
  'igHashtagImporter/fetchAccountStoryFailure'
)
export function fetchAccountStory(
  igBusinessAccountId: string,
  userId: string,
  url?: string
) {
  return async (dispatch: Dispatch): Promise<IgHashtagMediaRes> => {
    try {
      dispatch(fetchIgAccountStoryRequest())
      const igToken = getFbToken(userId)
      const response = await fetch(
        url
          ? url
          : `${FB_URL}/${igBusinessAccountId}/stories?user_id=${igBusinessAccountId}&fields=id,caption,timestamp,username,media_type,media_url,permalink,comments_count,like_count,children{id,media_type,media_url}&limit=20&access_token=${igToken.accessToken}`
      )
      const data = (await response.json()) as IgHashtagMediaRes
      dispatch(
        fetchIgAccountStorySuccess({
          userId,
          igMediaData: data,
          url
        })
      )

      return data
    } catch (error) {
      dispatch(fetchIgAccountStoryFailure())

      return error
    }
  }
}

const fetchIgAccountTagsRequest = createAction(
  'igHashtagImporter/fetchAccountTagsRequest'
)
const fetchIgAccountTagsFailure = createAction(
  'igHashtagImporter/fetchAccountTagsFailure'
)
export function fetchAccountTags(
  igBusinessAccountId: string,
  userId: string,
  url?: string
) {
  return async (dispatch: Dispatch): Promise<IgHashtagMediaRes> => {
    try {
      dispatch(fetchIgAccountTagsRequest())
      const igToken = getFbToken(userId)
      const response = await fetch(
        url
          ? url
          : `${FB_URL}/${igBusinessAccountId}/tags?fields=id,username,timestamp,caption,media_type,media_url,permalink,comments_count,like_count,children{id,media_type,media_url}&limit=20&access_token=${igToken.accessToken}`
      )
      const data = (await response.json()) as IgHashtagMediaRes
      dispatch(
        fetchIgAccountTagsSuccess({
          userId,
          igMediaData: data,
          url
        })
      )

      return data
    } catch (error) {
      dispatch(fetchIgAccountTagsFailure())

      return error
    }
  }
}

const fetchIgUsernameMediaRequest = createAction(
  'igHashtagImporter/fetchUsernameMediaRequest'
)
const fetchIgUsernameMediaFailure = createAction(
  'igHashtagImporter/fetchUsernameMediaFailure'
)
export function fetchUsernameMedia(
  igBusinessAccountId: string,
  username: string,
  userId: string,
  after?: string
) {
  return async (dispatch: Dispatch): Promise<IgHashtagMediaRes> => {
    try {
      dispatch(fetchIgUsernameMediaRequest())
      const igToken = getFbToken(userId)
      const url = `${FB_URL}/${igBusinessAccountId}?fields=business_discovery.username(${username}){followers_count,media_count,media.limit(20)${
        after ? `.after(${after})` : ''
      }{timestamp,caption,username,comments_count,like_count,media_url,thumbnail_url,media_type,children{id,media_type,media_url}}}&access_token=${
        igToken.accessToken
      }`
      const response = await fetch(url)
      const data = (await response.json()) as any
      dispatch(
        fetchIgUsernameMediaSuccess({
          username,
          igMediaData: data?.business_discovery?.media,
          url: after ? url : undefined
        })
      )

      return data
    } catch (error) {
      dispatch(fetchIgUsernameMediaFailure())

      return error
    }
  }
}
