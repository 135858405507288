import React, { ReactElement } from 'react'

import { css } from '@emotion/core'
import { Flex } from '@src/components/EmotionLayout'
import { Tag } from 'antd'

export const reviewStatusMapping = {
  // pending: {
  //   text: 'Pending',
  //   hoverColor: '#75757514',
  //   highlightColor: '#E2E2E2',
  //   hotkey: '1'
  // },
  correct_verified: {
    text: 'Correct',
    hoverColor: '#27AE604D',
    highlightColor: '#27AE60',
    hotkey: '2'
  },
  incorrect_mismatched: {
    text: 'Incorrect',
    hoverColor: '#EB575733',
    highlightColor: '#EB5757',
    hotkey: '3'
  },
  unknown_lacking: {
    text: 'Unknown',
    hoverColor: '#F2C94C33',
    highlightColor: '#F2C94C',
    hotkey: '4'
  },
  fabricated_lacking: {
    text: 'Fabricated',
    hoverColor: '#56B2FF4D',
    highlightColor: '#56B2FF',
    hotkey: '5'
  },
  escalated: {
    text: 'Escalated',
    hoverColor: '#BB6BD94D',
    highlightColor: '#BB6BD9',
    hotkey: '6'
  }
}

const resolutionStatusMapping = {
  pending: {
    text: 'Pending',
    hoverColor: '#0F6BFF4D',
    highlightColor: '#56B2FF',
    hotkey: 'Q'
  },
  resolved: {
    text: 'Resolved',
    hoverColor: '#0F6BFF4D',
    highlightColor: '#56B2FF',
    hotkey: 'W'
  },
  wont_fix: {
    text: "Won't Fix",
    hoverColor: '#0F6BFF4D',
    highlightColor: '#56B2FF',
    hotkey: 'E'
  },
  customer_action: {
    text: 'Customer Action',
    hoverColor: '#0F6BFF4D',
    highlightColor: '#56B2FF',
    hotkey: 'R'
  },
  investigation: {
    text: 'Investigate',
    hoverColor: '#0F6BFF4D',
    highlightColor: '#56B2FF',
    hotkey: 'T'
  }
}

interface IReviewStatusProps {
  reviewStatus: ReviewStatus
  onClick: (key: ReviewStatus) => void
}

const MessageAuditReviewStatus: React.FC<IReviewStatusProps> = ({
  reviewStatus,
  onClick
}) => {
  const statusTags: ReactElement[] = []
  Object.entries(reviewStatusMapping).forEach(([key, value], index) =>
    statusTags.push(
      <Tag
        key={index}
        color={key === reviewStatus ? value.highlightColor : 'default'}
        onClick={() => onClick(key as ReviewStatus)}
        style={{ cursor: 'pointer' }}
        css={css`
          border-radius: 4px;
          border: 1px solid #e0e0e0;
          background: #fff;
          margin-top: 16px;
          padding: 7px 14px;
          :hover {
            background-color: ${value.hoverColor};
          }
        `}
      >
        {value.text}
        <span style={{ marginLeft: 5 }}>{`(${value.hotkey})`}</span>
      </Tag>
    )
  )

  return (
    <Flex gap={12} flexWrap="wrap">
      {statusTags}
    </Flex>
  )
}

interface IResolutionStatusProps {
  resolutionStatus: ResolutionStatus
  onClick: (key: ResolutionStatus) => void
}

const MessageAuditResolutionStatus: React.FC<IResolutionStatusProps> = ({
  resolutionStatus,
  onClick
}) => {
  const statusTags: ReactElement[] = []
  Object.entries(resolutionStatusMapping).forEach(([key, value], index) =>
    statusTags.push(
      <Tag
        key={index}
        color={key === resolutionStatus ? value.highlightColor : 'default'}
        onClick={() => onClick(key as ResolutionStatus)}
        style={{ cursor: 'pointer' }}
        css={css`
          border-radius: 4px;
          border: 1px solid #e0e0e0;
          background: #fff;
          margin-top: 16px;
          padding: 7px 14px;
          :hover {
            background-color: ${value.hoverColor};
          }
        `}
      >
        {value.text}
        <span style={{ marginLeft: 5 }}>{`(${value.hotkey})`}</span>
      </Tag>
    )
  )

  return (
    <Flex gap={12} flexWrap="wrap">
      {statusTags}
    </Flex>
  )
}

export { MessageAuditReviewStatus, MessageAuditResolutionStatus }
