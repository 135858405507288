export const BUSINESS_TYPE = {
  FIREWORK_FOR_BUSINESS: 'firework_for_business'
}

export const FEED_STATUS = {
  LIVE: 'live',
  SANDBOX: 'sandbox',
  ARCHIVE: 'archive'
}

export const FEED_TYPE = {
  RECOMMENDATION: 'recommendation',
  HASHTAG: 'hashtag',
  USERS_VIDEOS: 'users_videos',
  USER_TIMELINE: 'user_timeline',
  PLAYLIST: 'playlist'
}

export const EMBED_MODE = {
  GRID: 'grid',
  ROW: 'row',
  PINNED: 'pinned'
}

export const EMBED_PLACEMENT = {
  TOP: 'top',
  MIDDLE: 'middle',
  BOTTOM: 'bottom',
  SIDE: 'side'
}

export const REQUEST_STATUS = {
  IDLE: 'idle',
  PENDING: 'pending',
  RESOLVED: 'resolved',
  REJECTED: 'rejected'
}

export const MEMBER_STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  PENDING: 'pending'
}

export const CTA_TYPE = {
  APPLY_NOW: 'apply',
  BOOK_A_TEST_DRIVE: 'book_a_test_drive',
  BOOK_NOW: 'book',
  CONTACT_US: 'contact',
  CTA: 'cta',
  CUSTOM: 'custom',
  DONATE: 'donate',
  DOWNLOAD: 'download',
  ENROLL_NOW: 'enroll_now',
  GET_OFFER: 'get_offer',
  GET_QUOTE: 'get_quote',
  GET_SHOWTIMES: 'get_showtimes',
  INVEST_NOW: 'invest_now',
  LEARN_MORE: 'learn_more',
  LIVE: 'live',
  ORDER_NOW: 'order',
  PLAY_NOW: 'play',
  READ_MORE: 'read_more',
  REMINDER: 'reminder',
  RSVP: 'rsvp',
  SEE_FULL_RECIPE: 'see_full_recipe',
  SHOP_NOW: 'shop_now',
  SHOP_RECIPE: 'shop_recipe',
  SIGN_UP: 'sign_up',
  VIEW: 'view',
  VIEW_MENU: 'view_menu',
  VIEW_RECIPE: 'view_recipe',
  SEE_MORE: 'see_more',
  TALK_TO_AN_EXPERT: 'talk_to_an_expert'
}

export const HASHTAGS = 'hashtags'
export const CHANNELS = 'channels'
export const RECOMMENDATION = 'recommendation'
export const ALL_YOUR_VIDEOS = 'all_your_videos'
export const FAVORITE = 'favorite'

export const CHANNEL_TABS = {
  ORIGINAL: 'original',
  ALL: 'all',
  PLAYLIST_OVERVIEW: 'playlist_overview',
  PLAYLIST: 'playlist',
  LINKED_PLAYLIST: 'linked_playlist',
  PLAYLIST_COLLECTION_OVERVIEW: 'playlist_collection_overview',
  PLAYLIST_COLLECTION: 'playlist_collection',
  LIVESTREAM: 'livestream',
  FLOWS: 'Flows',
  SOURCE_IMPORTER_OVERVIEW: 'source_importer_overview',
  SOURCE_IMPORTER: 'source_importer'
}

export const AUTO_EMBED_TABS = {
  HOME_PAGE: 'home_page',
  OTHER_PAGES: 'other_pages',
  PRODUCT_PAGES: 'product_pages',
  HTML: 'html',
  SHOP_APP: 'shop_app'
}

export const PAY_PLANS = {
  FREE: 'free',
  PRO: 'pro',
  PLUS: 'plus',
  STANDARD: 'standard',
  ENTERPRISE: 'enterprise'
}

export const ONBOARDING_MODES = {
  UPDATE: 'update',
  CREATE: 'create'
}

export const BILLING_PERIOD = {
  MONTHLY: 'monthly',
  ANNUALLY: 'yearly'
}

export const ROLES = {
  SUPER_USER: 'super_user',
  ADMIN: 'admin',
  MEMBER: 'member',
  MEMBER_LITE: 'member-lite',
  CREATOR: 'creator-store-member'
}

export const ROLE_STRINGS = {
  super_user: 'Super User',
  admin: 'Admin',
  member: 'Member',
  'member-lite': 'Member Lite',
  'creator-store-member': 'Creator Store Member'
}

export const VIDEO_UPLOAD_METHOD = {
  LOCAL_UPLOAD: 'local_upload',
  EXTERNAL_IMPORT: 'external_import',
  EDIT_VIDEO: 'edit_video',
  LONG_TO_SHORT: 'long_to_short'
}

export const LIVESTREAM_CREATE_METHOD = {
  FROM_VIDEO: 'from_video'
}

export const TAB_HERO_UNIT = 'TAB_HERO_UNIT'
export const TAB_FLOATING_VIDEO = 'TAB_FLOATING_VIDEO'
export const TAB_FLOATING_BUTTON = 'TAB_FLOATING_BUTTON'
export const TAB_STORY_BLOCK = 'TAB_STORY_BLOCK'
export const TAB_GRID = 'TAB_GRID'
export const TAB_CAROUSEL = 'TAB_CAROUSEL'
export const WORDPRESS = 'WORDPRESS'
export const WIX = 'WIX'
export const SQUARESPACE = 'SQUARESPACE'
export const SHOPIFY = 'SHOPIFY'
export const OTHER_HTML = 'OTHER_HTML'
export const SHOPIFY_PRODUCT_PAGES = 'SHOPIFY_PRODUCT_PAGES'
export const CHANNEL = 'channel'
export const VIDEO = 'video'
export const PLAYLIST = 'playlist'
export const MORE = 'more'
export const HTML = 'html'
export const EMAIL = 'email'
export const PLAYLIST_COLLECTION = 'playlist_collection'
export const DCS = 'dcs'

/**
 Storybook constants, do not change
 */

export const KNOB_WIDGET = 'Widget'
export const KNOB_CONTENT = 'Content'
export const KNOB_ENABLED = 'Enabled'
export const KNOB_PLACEMENT = 'Placement'

export const GROUP_HEADER = 'Header'
export const GROUP_BODY = 'Body'
export const GROUP_FOOTER = 'Footer'
export const GROUP_ASIDE = 'Aside'
export const GROUP_CHANNEL_BUTTON = 'Channel Button'

export const TYPE_FEED_ROW = `Embed Feed as Row`
export const TYPE_FEED_GRID = `Embed Feed as Grid`
export const TYPE_STORYBLOCK = `Storyblock`

export const CONTENT_SPORT = `Sports`
export const CONTENT_FASHION = `Fashion`
export const CONTENT_FOOD = `Food`

export const TOP_LEFT = 'Top Left'
export const TOP_RIGHT = 'Top Right'
export const BOTTOM_LEFT = 'Bottom Left'
export const BOTTOM_RIGHT = 'Bottom Right'

export const TYPE_OPTIONS = {
  [TYPE_FEED_ROW]: { type: 'fw-embed-feed', mode: 'row' },
  [TYPE_FEED_GRID]: { type: 'fw-embed-feed', mode: 'grid' },
  [TYPE_STORYBLOCK]: { type: 'fw-storyblock' }
}

export const CONTENT_OPTIONS = {
  [CONTENT_SPORT]: {
    channel: 'firework_layout_studio_official',
    playlist: '5qk8Ag'
  },
  [CONTENT_FASHION]: {
    channel: 'firework_layout_studio_official',
    playlist: 'vGnQDg'
  },
  [CONTENT_FOOD]: {
    channel: 'firework_layout_studio_official',
    playlist: 'v6YPMg'
  }
}

export const PLACEMENT_OPTIONS = {
  [TOP_LEFT]: 'top-left',
  [TOP_RIGHT]: 'top-right',
  [BOTTOM_LEFT]: 'bottom-left',
  [BOTTOM_RIGHT]: 'bottom-right'
}

export const COOKIES = {
  FW_STRIPE_COUPON_ID: 'fw_stripe_coupon_id'
}

export const LIVESTREAM_EVENT_DEFAULT_TRAILER_KEYWORD =
  'default_event_trailer.mp4'
export const LIVESTREAM_EVENT_TRAILER = 'assets/default_event_trailer.mp4'
export const LIVESTREAM_EVENT_POSTER = 'assets/default_event_trailer.jpg'
export const DEFAULT_TRAILER = `https://cdn1.fireworktv.com/assets/watermarked/540/${LIVESTREAM_EVENT_DEFAULT_TRAILER_KEYWORD}`

export const IOS_CAMERA_URL =
  'https://apps.apple.com/us/app/firework-camera/id1564522372'
export const ANDROID_CAMERA_URL =
  'https://play.google.com/store/apps/details?id=com.loopnow.camera'

export enum LIVESTREAM_STATUS {
  IDLE = 'idle',
  ACTIVE = 'active',
  PAUSED = 'paused',
  REPLAY = 'replay',
  COMPLETED = 'completed',
  EXPIRED = 'expired',
  REHEARSAL = 'rehearsal'
}

export enum LIVESTREAM_SOURCE {
  EXTERNAL = 'external',
  FIREWORK = 'fw',
  PRERECORDED = 'prerecorded'
}

export enum InteractionType {
  POLL = 'poll',
  APPOINTMENT = 'appointment',
  QUESTIONS = 'question',
  QUIZ = 'quiz',
  MULTIPLE_CHOICE = 'multiple_choice',
  PRODUCT = 'product',
  CTA = 'cta',
  REMINDER = 'reminder',
  GIVEAWAY = 'giveaway'
}

export const WEB_SOCKET_STATUS = {
  OPEN: 'open',
  CLOSED: 'closed',
  ERROR: 'error'
}

export const FW_FOR_BUSINESS = 'firework_for_business'

export const DEFAULT_PRODUCT_OPTION_JSON = '{"Title":["Default Title"]}'

export const STORE_PROVIDERS = {
  BOUTIR: 'boutir',
  SHOPIFY: 'shopify',
  WOOCOMMERCE: 'woocommerce',
  MAGENTO: 'magento',
  TEMP_SHOPIFY: 'temp_shopify',
  ECWID: 'ecwid',
  GOOGLE_MERCHANT: 'google_merchant',
  SALESFORCE: 'salesforce',
  LOCAL: 'local'
}

export const PLATFORM_PROVIDERS = {
  YOUTUBE: 'youtube',
  FACEBOOK: 'facebook',
  TIKTOK: 'tiktok',
  INSTAGRAM: 'instagram',
  TWITTER: 'twitter',
  LINE_LIVE: 'line'
}

export const PLATFORM_NAMES = {
  youtube: 'YouTube',
  facebook: 'Facebook',
  tiktok: 'TikTok',
  instagram: 'Instagram',
  twitter: 'Twitter',
  line: 'Line Live'
}

export const OAuthApps = {
  DUDA: 'duda',
  SHOPIFY: 'shopify'
}

export const PLAYLIST_SORT_OPTIONS = {
  NAME_ASC: 'name_asc',
  NAME_DESC: 'name_desc',
  UPDATED_AT_ASC: 'updated_at_asc',
  UPDATED_AT_DESC: 'updated_at_desc'
}

export const DATETIME_FORMAT = 'MMMM Do YYYY h:mm a'
export const ONE_DAY = 86400000
export const ONE_HOUR = 3600000
export const FOREVER_STREAM_VIDEO_IDS = ['g46qzz', 'oynOpO', 'v7Jqzx']
export const TIME_TO_START = 5 * 60 * 1000
export const LIVESTREAM_EVENT_PRESTART_MS = 5 * 60 * 1000
export const LIVESTREAM_PRERECORDED_EVENT_PRESTART_MS = 5 * 60 * 1000
export const FETCH_LIVESTREAM_DETAILS_INTERVAL = 3 * 1000
export const MIN_VIDEO_DURATION = 3
export const SHORT_FORM_VIDEO_DURATION = 60
export const LONG_FORM_VIDEO_DURATION = 180
export const LONG_TO_SHORT_EXTEND_TIME = 10
export const LONG_FORM_MAX_FILE_SIZE = 300000000
// This is basically 1s at a very low resolution
export const MIN_FILE_SIZE = 25000

export const LIVESTREAM_USER_STATUS = {
  ON_AIR: 'on_air',
  READY: 'ready'
}

export const LIVESTREAM_PROVIDERS = {
  IVS: 'ivs',
  AGORA: 'agora'
}

export const IMPORT_JOB_STATUS = {
  PROCESSING: 'processing',
  COMPLETE: 'complete',
  FAILED: 'failed'
}

export const BULK_HASHTAG_ACTION = {
  ADD: 'add',
  DELETE: 'delete',
  TOP: 'top',
  BOTTOM: 'bottom',
  CENTER: 'center'
}

export const MAX_VIDEO_SELECTION = 50
export const MAX_BULK_HASHTAGS = 50
export const MAX_HASHTAG_VISIBLE_LENGTH = 50
export const MAX_HASHTAG_LENGTH = 100

export enum LIVESTREAM_ACTION_TYPE {
  HIGHLIGHT_ENTITY = 'highlight_entity',
  UNHIGHLIGHT_ENTITY = 'unhighlight_entity',
  ENABLE_ENTITY = 'enable_entity',
  DISABLE_ENTITY = 'disable_entity',
  GIVEAWAY_COLLECTION = 'giveaway_collection',
  END_GIVEAWAY_COLLECTION = 'end_giveaway_collection'
}

export enum LIVESTREAM_ENTITY_TYPE {
  PRODUCT = 'product',
  QUESTION = 'question',
  POLL = 'poll'
}

export const INSIGHT_TIME = {
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month'
}

export const COLUMN_TITLES = {
  BRAND_NAME: 'brandName',
  DATE: 'date',
  PLAYLIST: 'playlist',
  USERNAME: 'username',
  INTERACTIONS: 'interactions',
  DURATION: 'duration',
  VIDEO_ID: 'videoId',
  STATUS: 'status',
  VIEWS: 'views',
  VISIBILITY: 'visibility',
  HASHTAG: 'hashtag'
}

export const TOP_PLAYLIST = {
  UNIQUE_DESCRIPTION: 'FW_BIZ_PORTAL_MOST_VIEWS_PLAYLIST',
  NUM_TRIGGER: 5,
  MAX_VIDEOS: 10
}

export const VIDEO_PERFORMANCE_CARD = {
  KEY_VIDEO_CAPTION: 'video_caption',
  KEY_VIEWS: 'total_views',
  KEY_WATCH_TIME: 'total_seconds_watched',
  PRODUCT_CLICKS: 'total_product_card_clicks',
  AVG_WATCH_TIME: 'average_watch_time_seconds',
  TYPE_CHANNEL: 'channel'
}

export const CHANNEL_URL_DEFAULT = 'fw.tv'

export const CHANNEL_URLS = {
  production: CHANNEL_URL_DEFAULT,
  staging: 'fw-staging.tv',
  development: 'fw-dev.tv',
  local: 'fw-staging.tv'
}

export const FITZ_WILLIAM_SPARK_BUSINESS_ID = '5EROOo'

export const FLOW_LINK_TARGET_TYPES = {
  VIDEO: 'video',
  URL: 'url'
}

export const DEFAULT_LS_POSTER =
  'https://cdn1-staging.fireworktv.com/assets/transcoded/540/default_event_trailer.jpg'

export const DEFAULT_AVATAR =
  'https://cdn1-staging.fireworktv.com/assets/default_avatar.png'

export const SIGMA_EMBED_LEVELS = {
  CHANNEL: 'channel',
  VIDEO: 'video',
  PLAYLIST: 'playlist',
  BUSINESS: 'business',
  LIVESTREAM: 'livestream',
  LIVESTREAMS: 'livestreams',
  SHOWROOM: 'showroom',
  OTO_CHAT: 'oto_chat',
  OTO_REQUEST: 'oto_request',
  OTO_PERFORMANCE: 'oto_performance',
  OTO_TEAM: 'oto_team',
  AVA_INDIVIDUAL: 'domain_assistant',
  AVA_BUSINESS: 'ava_business',
  REVENUE: 'revenue',
  SHORT_VIDEO: 'video'
}

export const PLG_PLANS = {
  PILOT: 'plg_pilot',
  STARTER: 'plg_starter',
  GROWTH: 'plg_growth'
}

export enum BA_STORE_MODE {
  FW_CHECKOUT = 'FW_CHECKOUT',
  CART_PASSOVER = 'CART_PASSOVER',
  PDP_REDIRECT = 'PDP_REDIRECT'
}

export enum AUTOMATION_TRIGGERS {
  CREATE = 'create',
  SCHEDULE = 'scheduled'
}

export const ALL_CHANNELS_PAGE_SIZE = 200

export const KEY_ACTIVATION_STATUS = 'fw_as'
export const KEY_ONBOARDING_STATUS = 'fw_ob'
export const KEY_COPILOT_WAITLIST = 'fw_cp'

export const INSIGHTS_VERSION = 'cms_insights_version'

export const shopifyDomain =
  process.env.REACT_APP_ENV === 'production'
    ? 'firework'
    : 'firework-app-staging'

// These are the columns to be provided in the CSV.
export const csvColumns = {
  encoded_id: 'string',
  access: 'string',
  caption: 'string',
  duration: 'duration',
  download_url: 'string',
  creator: { username: 'string' },
  inserted_at: 'datetime',
  published_at: 'datetime',
  status: 'string',
  video_type: 'string',
  hashtags: 'array',
  action_type: 'string',
  action_url: 'string',
  playlist_names: 'array'
}

export type VideoVisibility = 'public' | 'private' | 'unlisted'

export const MIN_CAPTION_LENGTH = 2
export const MAX_CAPTION_LENGTH = 100
export const MAX_SELECTED_VIDEOS = 20

export const MAX_BULK_PLAYLISTS = 10
export const BULK_PLAYLIST_ACTION = {
  ADD: 'add',
  DELETE: 'delete'
}

export const SHOPIFY_TUTORIAL_VIDEOS = ['5DLDLM', 'vJRbR2', 'oWjdjy', 'gKMkM8']

//View overage modal data locale storage key
export const VIEW_OVERAGE_MODAL_DATA = 'fw_vomd'
export const SHOPIFY_PDP_TUTORIAL_VIDEOS = ['gY6408', 'g2EAyQ', 'gOj83d']

export enum SHOPIFY_STORE_PAGES {
  SHOPIFY_STANDARD_HOMEPAGE = 'shopify_standard_homepage',
  SHOPIFY_STANDARD_PRODUCT_PAGE = 'shopify_standard_product_page',
  SHOPIFY_STANDARD_COLLECTION_PAGE = 'shopify_standard_collection_page'
}

export enum EMBED_TYPES {
  HERO_UNIT = 'hero_unit',
  FLOATING_VIDEO = 'floating_video',
  FLOATING_BUTTON = 'floating_button',
  STORYBLOCK = 'storyblock',
  GRID = 'grid',
  CAROUSEL = 'carousel'
}
export const VIEWED_EMBED_INSTRUCTIONS_KEY = 'has-viewed-embed-instructions'
export const currentStoreKey = 'fw_current_store'
export const currentShopifyStoreKey = 'fw_current_shopify_store'
export const ShopAppV2Modal = 'fw_shop_app_v2_modal'

export enum SOURCE_IMPORTER_TYPE {
  ACCOUNT = 'account',
  USERNAME = 'username',
  HASHTAG = 'hashtag'
}
