import {
  createAction,
  createSlice,
  PayloadAction,
  Slice
} from '@reduxjs/toolkit'

type SliceState = {
  message?: string
  path?: string
  status?: number
  apiErrorMessage?: string
}

const initialState: SliceState = {
  message: null,
  path: null,
  status: null,
  apiErrorMessage: null
}

type ISlice = {
  setApiErrorSuccess: (state, action) => void
  setErrorMessageSuccess: (state, action) => void
  resetErrorSuccess: (state, action) => void
}

const slice: Slice<SliceState, ISlice, 'errors'> = createSlice({
  name: 'errors',
  initialState: initialState,
  reducers: {
    setApiErrorSuccess: (
      state: Partial<SliceState>,
      action: PayloadAction<Partial<SliceState>>
    ) => {
      const { message, path, status } = action.payload
      state.apiErrorMessage = message
      state.path = path
      state.status = status
    },

    setErrorMessageSuccess: (
      state: Partial<SliceState>,
      action: PayloadAction<Partial<SliceState>>
    ) => {
      const { message } = action.payload
      state.message = message
      state.apiErrorMessage = null
    },

    resetErrorSuccess: (state: Partial<SliceState>) => {
      state.message = null
      state.path = null
      state.status = null
      state.apiErrorMessage = null
    }
  }
})

export default slice.reducer

const {
  setApiErrorSuccess,
  setErrorMessageSuccess,
  resetErrorSuccess
} = slice.actions

const setErrorMessageRequest = createAction('errors/setErrorRequest')

export function setErrorMessage(message: string) {
  return async (dispatch: any) => {
    dispatch(setErrorMessageRequest())
    dispatch(setErrorMessageSuccess({ message }))
  }
}

const setApiErrorRequest = createAction('errors/setApiErrorRequest')

export function setApiError(message: string, status: number, path: string) {
  return async (dispatch: any) => {
    dispatch(setApiErrorRequest())
    dispatch(setApiErrorSuccess({ message, status, path }))
  }
}

const resetErrorRequest = createAction('errors/resetErrorRequest')

export function resetError() {
  return async (dispatch: any) => {
    dispatch(resetErrorRequest())
    dispatch(resetErrorSuccess({}))
  }
}
