import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '@src/app/store'

export const subscriptionPlanIdByBusinessId = createSelector(
  (state: any, businessId: string) =>
    state.subscriptions.subscriptionsByBusinessId[businessId],
  (businessSubscription) => {
    return businessSubscription?.subscription_plan?.plan_id
  }
)

export const isPLGPilotBusiness = createSelector(
  (state: any, businessId: string) =>
    state.subscriptions.subscriptionsByBusinessId[businessId],
  (businessSubscription: globalLib.BusinessSubscription) => {
    return businessSubscription?.payment_plan_id === 'plg_pilot'
  }
)

export const isPLGBusiness = createSelector(
  (state: any, businessId: string) =>
    state.subscriptions.subscriptionsByBusinessId[businessId],
  (businessSubscription: globalLib.BusinessSubscription) => {
    return businessSubscription?.payment_plan_id.startsWith('plg_')
  }
)

export const createPaymentPlanByBusinessIdSelector = (businessId: string) => (
  state: RootState
): any => state.subscriptions.subscriptionsByBusinessId[businessId]

export const businessViewUsage = createSelector(
  (state: any, businessId: string) =>
    state.subscriptions.subscriptionUsageByBusinessId[businessId],
  (subscriptionUsage: globalLib.SubscriptionUsage) => {
    return {
      percentage: Math.round(
        (subscriptionUsage?.current?.video_views /
          subscriptionUsage?.maximum?.video_views) *
          100
      ),
      current: subscriptionUsage?.current?.video_views
    }
  }
)

export const getSubscriptionFeatures = createSelector(
  (state: any, businessId: string) =>
    state.subscriptions.subscriptionsByBusinessId[businessId],
  (subscriptionFeatures) => {
    return subscriptionFeatures?.feature_checks?.reduce((acc, feature) => {
      if (feature?.enabled) {
        acc[feature.feature_name] = feature
      }

      return acc
    }, {})
  }
)

export const getShortVideoLimit = createSelector(
  (state, businessIs) => getSubscriptionFeatures(state, businessIs),
  (features) => {
    if (features?.short_videos?.enabled) {
      return features?.short_videos?.limit?.max_videos || 999999999
    } else {
      return 0
    }
  }
)

export const isViewUsageOver80Under100 = createSelector(
  (state: any, businessId: string) => businessViewUsage(state, businessId),
  (usage) => {
    return usage?.percentage >= 80 && usage?.percentage < 100
  }
)

export const isViewUsageOver100 = createSelector(
  (state: any, businessId: string) => businessViewUsage(state, businessId),
  (usage) => {
    return usage?.percentage >= 100
  }
)
