import { useEffect } from 'react'

import { useAppDispatch } from '@src/app/hooks'
import { usePixelAmpTracking } from '@src/hooks'
import { setCmdBarIsOpen } from '@src/redux/ui'
import { TRACKING_EVENTS } from '@src/utils/tracking'

export const useHandleOpenCommandPalette = (): void => {
  const dispatch = useAppDispatch()
  const { pixelAmpTracking } = usePixelAmpTracking()
  useEffect(() => {
    function handleKeyDown(e: KeyboardEvent) {
      if (
        (navigator?.platform?.toLowerCase().includes('mac')
          ? e.metaKey
          : e.ctrlKey) &&
        e.key === 'k'
      ) {
        e.preventDefault()
        e.stopPropagation()
        pixelAmpTracking(TRACKING_EVENTS.BUSINESS_ACCESS_SEARCH_NAVIGATION, {
          action: 'shortcut'
        })
        dispatch(setCmdBarIsOpen())
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [dispatch, pixelAmpTracking])
}
