import {
  createAsyncThunk,
  Slice,
  SliceCaseReducers,
  createSlice
} from '@reduxjs/toolkit'
import api from '@src/utils/api'

const SLICE_NAME = 'autoEmbed'

type SliceState = {
  selectedPlaylistId: string
  playlistVideos: globalLib.IVideo[]
  playlistLoading: boolean
  isEnableBannerVisibile: boolean
  isHelpBannerVisible: boolean
  embedConfig: AutoEmbedConfiguration[]
}

const initialState: SliceState = {
  selectedPlaylistId: null,
  playlistVideos: [],
  playlistLoading: false,
  isEnableBannerVisibile: true,
  isHelpBannerVisible: false,
  embedConfig: null
}

export const autoEmbed_fetchPlaylistVideos = createAsyncThunk(
  `${SLICE_NAME}/fetchPlaylistVideos`,
  async (params: {
    playlistId: string
    channelId: string
    businessId: string
  }) => {
    const { data } = await api.get(
      `/bus/${params.businessId}/channels/${params.channelId}/playlists/${params.playlistId}/videos`
    )

    return data.videos
  }
)

export const autoEmbed_upsertEmbedConfig = createAsyncThunk(
  `${SLICE_NAME}/upsertEmbedConfig`,
  async (params: {
    channelId: string
    businessId: string
    embedConfig: AutoEmbedConfiguration
  }) => {
    const { data } = await api.post(
      `/bus/${params.businessId}/channels/${params.channelId}/upsert_embedded_component`,
      params.embedConfig
    )

    return data
  }
)

export const autoEmbed_fetchEmbedConfig = createAsyncThunk(
  `${SLICE_NAME}/fetchEmbedConfig`,
  async (params: { channelId: string; businessId: string }) => {
    const { data } = await api.get(
      `/bus/${params.businessId}/channels/${params.channelId}/auto_embed_config`
    )

    return data
  }
)

const slice: Slice<
  SliceState,
  SliceCaseReducers<SliceState>,
  typeof SLICE_NAME
> = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    resetPlaylistVideos: (state) => {
      state.playlistVideos = []
    },
    toggleHelpBanner: (state) => {
      state.isEnableBannerVisibile = false
      state.isHelpBannerVisible = true
    },
    setAutoEmbedSelectedPlaylistId: (state, action) => {
      state.selectedPlaylistId = action.payload
    },
    clearAutoEmbedPlaylistVideos: (state) => {
      state.playlistVideos = []
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(autoEmbed_fetchPlaylistVideos.fulfilled, (state, action) => {
        state.playlistLoading = false
        state.playlistVideos = action.payload
      })
      .addCase(autoEmbed_fetchPlaylistVideos.pending, (state) => {
        state.playlistLoading = true
        state.playlistVideos = []
      })
      .addCase(autoEmbed_upsertEmbedConfig.fulfilled, (state, action) => {
        if (!state.embedConfig) {
          state.embedConfig = [action.payload]

          return
        }

        state.embedConfig = state.embedConfig.map((config) => {
          if (config.id === action.payload.id) {
            return action.payload
          }

          return config
        })
      })
      .addCase(autoEmbed_upsertEmbedConfig.rejected, () => {
        throw new Error('Failed to save embed config')
      })
      .addCase(autoEmbed_fetchEmbedConfig.fulfilled, (state, action) => {
        state.embedConfig = action.payload
      })
  }
})

export default slice.reducer

export const {
  resetPlaylistVideos,
  toggleHelpBanner,
  setAutoEmbedSelectedPlaylistId,
  clearAutoEmbedPlaylistVideos
} = slice.actions
