import { createSlice } from '@reduxjs/toolkit'

type InitialState = {
  showSearch: boolean
  cmdBarIsOpen: boolean
  showPlaylistToWebsiteBanner: boolean
  showPDPEmbedBanner: boolean
  sidebarCollapsed: boolean
}

const initialState: InitialState = {
  showSearch: false,
  cmdBarIsOpen: false,
  showPlaylistToWebsiteBanner: false,
  showPDPEmbedBanner: false,
  sidebarCollapsed: true
}

const slice = createSlice({
  name: 'ui',
  initialState: initialState,
  reducers: {
    updateShowSearch(state, action) {
      state.showSearch = action.payload
    },
    setCmdBarIsOpen(state) {
      state.cmdBarIsOpen = !state.cmdBarIsOpen
    },
    setShowPlaylistToWebsiteBanner(state, action) {
      state.showPlaylistToWebsiteBanner = action.payload
    },
    setShowPDPEmbedBanner(state, action) {
      state.showPDPEmbedBanner = action.payload
    },
    setIsSidebarCollapsed(state, action) {
      state.sidebarCollapsed = action.payload
    }
  }
})

export default slice.reducer

export const {
  updateShowSearch,
  setCmdBarIsOpen,
  setShowPlaylistToWebsiteBanner,
  setShowPDPEmbedBanner,
  setIsSidebarCollapsed
} = slice.actions
