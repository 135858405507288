import React, { useState, useCallback } from 'react'

import { INSIGHT_TIME } from '@src/constants'
import { fetchAggregateVideoInsightsDetails } from '@src/redux/insight'
import { convertTimeUnit } from '@src/utils/stats'
import { Skeleton } from 'antd'
import { Chart, Axis, Geom, Tooltip, Legend } from 'bizcharts'
import { Heading, Flex, Box } from 'fwego'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { CardHeader, FWButton } from '.'
import { Card, EmptyInsightsContent } from '.'
import { numberFormatter } from '../utils/format'

type IProps = {
  data: {
    report_date: string
  }[]
  dataKey?: string
  dateKey?: string
  linesBy?: string
  headerExtra?: JSX.Element
  headerTitle?: string
  headerTooltip?: string | JSX.Element
  loading?: boolean
  toggle?: boolean
  startDate?: string
  endDate?: string
}

const ViewsLineChartCard = ({
  data = [],
  dataKey,
  dateKey = 'report_date',
  linesBy = 'source',
  headerExtra,
  headerTitle,
  headerTooltip,
  loading,
  toggle = false,
  startDate,
  endDate
}: IProps): JSX.Element => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { businessId, channelId } = useParams()
  const [dataType, setDataType] = useState(INSIGHT_TIME.DAY)
  const total = () => {
    const total = Math.round(
      data.reduce((acc, obj) => {
        return acc + obj[dataKey]
      }, 0)
    )

    return dataKey === 'total_views'
      ? total.toLocaleString()
      : convertTimeUnit(total)
  }
  const newData =
    dataType === INSIGHT_TIME.DAY
      ? data
      : data.map((d) => ({
          ...d,
          report_date: moment(d.report_date).format('MM/DD')
        }))

  const handleSelectTime = useCallback(
    (time) => {
      setDataType(time)
      dispatch(
        fetchAggregateVideoInsightsDetails(businessId, channelId, {
          start_date: startDate,
          end_date: endDate,
          time: time.toLowerCase()
        })
      )
    },
    [businessId, channelId, dispatch, endDate, startDate]
  )

  return (
    <Box height={450}>
      <Card height="100%" m="0">
        <CardHeader
          extra={headerExtra}
          title={headerTitle}
          tooltip={headerTooltip}
        />
        <Skeleton loading={loading}>
          {toggle && (
            <Flex gap="4px 4px" width="100%" justifyContent="flex-end">
              <FWButton
                type={dataType === INSIGHT_TIME.DAY ? 'primary' : 'default'}
                style={{ width: 100 }}
                onClick={() => handleSelectTime(INSIGHT_TIME.DAY)}
              >
                {t('Day')}
              </FWButton>
              <FWButton
                type={dataType === INSIGHT_TIME.WEEK ? 'primary' : 'default'}
                style={{ width: 100 }}
                onClick={() => handleSelectTime(INSIGHT_TIME.WEEK)}
              >
                {t('Week')}
              </FWButton>
              <FWButton
                type={dataType === INSIGHT_TIME.MONTH ? 'primary' : 'default'}
                style={{ width: 100 }}
                onClick={() => handleSelectTime(INSIGHT_TIME.MONTH)}
              >
                {t('Month')}
              </FWButton>
            </Flex>
          )}
          {data && data.length ? (
            <>
              <Heading level="2" my="0">
                {total()}
              </Heading>
              <Chart
                data={newData}
                scale={{
                  [dateKey]: {
                    tickCount: dataType === INSIGHT_TIME.DAY ? 14 : 6
                  },
                  total_views: {
                    alias: 'View'
                  },
                  total_minutes_watched: {
                    alias: 'Minute'
                  }
                }}
                height={300}
                padding={[30, 40, 60, 80]}
                forceFit
              >
                <Legend />
                <Axis name={dateKey} />
                <Axis
                  key={dataKey}
                  name={dataKey}
                  label={{ formatter: numberFormatter }}
                  title={{
                    offset: 60,
                    textStyle: {
                      rotate: -90,
                      fontWeight: 'bold',
                      fill: 'black'
                    }
                  }}
                />
                <Tooltip crosshairs={{ type: 'y' }} />
                <Geom
                  key="geom"
                  type="line"
                  color={linesBy}
                  shape="rect"
                  position={`${dateKey}*${dataKey}`}
                  tooltip={[
                    `${dateKey}*${dataKey}*${linesBy}`,
                    (date, value, linesBy) => {
                      return {
                        title: date,
                        name: linesBy,
                        value:
                          dataKey === 'total_views'
                            ? `${value?.toLocaleString()} ${t('views')}`
                            : convertTimeUnit(value)
                      }
                    }
                  ]}
                />
                {data.length === 1 && (
                  <Geom
                    key="interval"
                    type="interval"
                    color={linesBy}
                    size={40}
                    shape="rect"
                    position={`${dateKey}*${dataKey}`}
                    tooltip={[
                      `${dateKey}*${dataKey}*${linesBy}`,
                      (date, value, linesBy) => {
                        return {
                          title: date,
                          name: linesBy,
                          value:
                            dataKey === 'total_views'
                              ? `${value?.toLocaleString()} ${t('views')}`
                              : convertTimeUnit(value * 60)
                        }
                      }
                    ]}
                  />
                )}
              </Chart>
            </>
          ) : (
            <EmptyInsightsContent />
          )}
        </Skeleton>
      </Card>
    </Box>
  )
}

export default ViewsLineChartCard
