import React from 'react'

import { css } from '@emotion/core'
import { Skeleton } from 'antd'
import {
  Chart,
  Coord,
  Tooltip,
  Legend,
  Axis,
  Geom,
  Guide,
  Label
} from 'bizcharts'
import { Box } from 'fwego'

import { Card, EmptyInsightsContent } from '.'

type IProps = {
  data?: any[]
  dataKey?: string
  dataTotalKey?: string
  header?: React.ReactNode
  height?: number
  loading?: boolean
  titleTotal?: string
  padding?: any
}

const PieChartCard: React.FC<IProps> = ({
  data = [],
  dataKey,
  dataTotalKey,
  header,
  height = 250,
  loading,
  titleTotal,
  padding = [0, '30%', 0, 0]
}) => {
  const listTotal = () => {
    return Math.round(
      data.reduce((acc, obj) => {
        return acc + obj[dataTotalKey]
      }, 0)
    ).toLocaleString()
  }

  return (
    <Card height="100%" m="0">
      <Skeleton loading={loading}>
        {header}
        {data && data.length > 0 ? (
          <Chart
            height={height}
            data={data}
            padding={padding}
            forceFit
            css={css`
              position: relative;
            `}
          >
            <Coord type="theta" radius={0.8} innerRadius={0.7} />
            <Tooltip showTitle={false} />
            <Legend position="left-center" layout="vertical" />
            <Axis name={dataKey} />
            <Geom
              type="intervalStack"
              position={dataTotalKey}
              color={dataKey}
              style={{ lineWidth: 4, stroke: '#fff' }}
              tooltip={[
                `${dataTotalKey}*${dataKey}`,
                (value, name) => {
                  return {
                    name: name,
                    value: value.toLocaleString()
                  }
                }
              ]}
            >
              <Label
                content={dataKey}
                formatter={(_, item) =>
                  item.point[dataTotalKey]?.toLocaleString()
                }
              />
            </Geom>
            <Guide>
              <Guide.Text
                style={{
                  textAlign: 'center',
                  fontSize: 14,
                  fontWeight: '200'
                }}
                content={titleTotal}
                position={['50%', '45%']}
              />
              <Guide.Text
                style={{
                  textAlign: 'center',
                  fontSize: 24,
                  fontWeight: '300',
                  fill: 'black'
                }}
                content={listTotal()}
                position={['50%', '55%']}
              />
            </Guide>
          </Chart>
        ) : (
          <Box>
            <EmptyInsightsContent />
          </Box>
        )}
      </Skeleton>
    </Card>
  )
}

export default PieChartCard
