import { createSlice, Slice, SliceCaseReducers } from '@reduxjs/toolkit'

interface ActivationStatusState {
  uploadVideo: globalLib.ACTIVATION_STEP_STATUS
  createPlaylist: globalLib.ACTIVATION_STEP_STATUS
  embedPlaylist: globalLib.ACTIVATION_STEP_STATUS
  isActivationStatusModalOpen: boolean
  onboardingContext: 'home' | 'pdp'
}

const initialState: ActivationStatusState = {
  uploadVideo: 'active',
  createPlaylist: 'inactive',
  embedPlaylist: 'inactive',
  isActivationStatusModalOpen: true,
  onboardingContext: 'home'
}

const SLICE_NAME = 'activationStatus'

const slice: Slice<
  ActivationStatusState,
  SliceCaseReducers<ActivationStatusState>,
  typeof SLICE_NAME
> = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setActivationStatus(state, action) {
      const { uploadVideo, createPlaylist, embedPlaylist } = action.payload
      state.uploadVideo = uploadVideo ?? state.uploadVideo
      state.createPlaylist = createPlaylist ?? state.createPlaylist
      state.embedPlaylist = embedPlaylist ?? state.embedPlaylist
    },
    setActivationStatusModalOpen(state, action) {
      state.isActivationStatusModalOpen =
        action.payload ?? !state.isActivationStatusModalOpen
    },
    setOnboardingContext(state, action: { payload: 'home' | 'pdp' }) {
      state.onboardingContext = action.payload
    }
  }
})

export default slice.reducer

export const {
  setActivationStatus,
  setActivationStatusModalOpen,
  setOnboardingContext
} = slice.actions
