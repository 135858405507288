import { createAction, createSlice } from '@reduxjs/toolkit'
import {
  getAccountList,
  getHashtagList,
  getUsernameList,
  IgHashtag,
  IPublicPage
} from '@src/components/IgHashtagImporter/igHashtagImporterModel'
import { FbToken, getFbToken } from '@src/utils/fbAuth'
import { Dispatch } from 'redux'

type IProps = {
  fbToken: FbToken
  accountList: IPublicPage[]
  usernameList: string[]
  hashtagList: IgHashtag[]
}

const initialState: IProps = {
  fbToken: undefined,
  accountList: [],
  usernameList: [],
  hashtagList: []
}

const slice = createSlice({
  name: 'sourceImporter',
  initialState: initialState,
  reducers: {
    fetchLocalSourceImporterDataSuccess(state, action) {
      const { fbToken, accountList, usernameList, hashtagList } = action.payload
      state.fbToken = fbToken
      state.accountList = accountList
      state.usernameList = usernameList
      state.hashtagList = hashtagList
    },
    clearLocalSourceImporterDataSuccess(state) {
      state.fbToken = undefined
      state.accountList = []
      state.usernameList = []
      state.hashtagList = []
    },
    fetchLocalFbTokenSuccess(state, action) {
      const { fbToken } = action.payload
      state.fbToken = fbToken
    },
    fetchLocalAccountListSuccess(state, action) {
      const { accountList } = action.payload
      state.accountList = accountList
    },
    fetchLocalUsernameListSuccess(state, action) {
      const { usernameList } = action.payload
      state.usernameList = usernameList
    },
    fetchLocalHashtagListSuccess(state, action) {
      const { hashtagList } = action.payload
      state.hashtagList = hashtagList
    }
  }
})

export default slice.reducer

export const {
  fetchLocalSourceImporterDataSuccess,
  clearLocalSourceImporterDataSuccess,
  fetchLocalFbTokenSuccess,
  fetchLocalAccountListSuccess,
  fetchLocalUsernameListSuccess,
  fetchLocalHashtagListSuccess
} = slice.actions

const fetchLocalSourceImporterDataRequest = createAction(
  'sourceImporter/fetchLocalSourceImporterDataRequest'
)
const fetchLocalSourceImporterDataFailure = createAction(
  'sourceImporter/fetchLocalSourceImporterDataFailure'
)

export function fetchLocalSourceImporterData(userId: string) {
  return async (dispatch: Dispatch): Promise<IProps> => {
    try {
      dispatch(fetchLocalSourceImporterDataRequest())
      const fbToken = getFbToken(userId)
      const accountList = getAccountList(userId)
      const usernameList = getUsernameList(userId)
      const hashtagList = getHashtagList(userId)

      dispatch(
        fetchLocalSourceImporterDataSuccess({
          fbToken,
          accountList,
          usernameList,
          hashtagList
        })
      )

      return {
        fbToken,
        accountList,
        usernameList,
        hashtagList
      }
    } catch (error) {
      dispatch(fetchLocalSourceImporterDataFailure())

      return null
    }
  }
}

const fetchLocalFbTokenRequest = createAction(
  'sourceImporter/fetchLocalFbTokenRequest'
)
const fetchLocalFbTokenFailure = createAction(
  'sourceImporter/fetchLocalFbTokenFailure'
)

export function fetchLocalFbToken(userId: string) {
  return async (dispatch: Dispatch): Promise<FbToken> => {
    try {
      dispatch(fetchLocalFbTokenRequest())
      const fbToken = getFbToken(userId)
      dispatch(
        fetchLocalFbTokenSuccess({
          fbToken
        })
      )

      return fbToken
    } catch (error) {
      dispatch(fetchLocalFbTokenFailure())

      return null
    }
  }
}

const fetchLocalAccountListRequest = createAction(
  'sourceImporter/fetchLocalAccountListRequest'
)
const fetchLocalAccountListFailure = createAction(
  'sourceImporter/fetchLocalAccountListFailure'
)

export function fetchLocalAccountList(userId: string) {
  return async (dispatch: Dispatch): Promise<IPublicPage[]> => {
    try {
      dispatch(fetchLocalAccountListRequest())
      const accountList = getAccountList(userId)
      dispatch(
        fetchLocalAccountListSuccess({
          accountList
        })
      )

      return accountList
    } catch (error) {
      dispatch(fetchLocalAccountListFailure())

      return null
    }
  }
}

const fetchLocalUsernameListRequest = createAction(
  'sourceImporter/fetchLocalUsernameListRequest'
)
const fetchLocalUsernameListFailure = createAction(
  'sourceImporter/fetchLocalUsernameListFailure'
)

export function fetchLocalUsernameList(userId: string) {
  return async (dispatch: Dispatch): Promise<string[]> => {
    try {
      dispatch(fetchLocalUsernameListRequest())
      const usernameList = getUsernameList(userId)
      dispatch(
        fetchLocalUsernameListSuccess({
          usernameList
        })
      )

      return usernameList
    } catch (error) {
      dispatch(fetchLocalUsernameListFailure())

      return null
    }
  }
}

const fetchLocalHashtagListRequest = createAction(
  'sourceImporter/fetchLocalHashtagListRequest'
)
const fetchLocalHashtagListFailure = createAction(
  'sourceImporter/fetchLocalHashtagListFailure'
)

export function fetchLocalHashtagList(userId: string) {
  return async (dispatch: Dispatch): Promise<IgHashtag[]> => {
    try {
      dispatch(fetchLocalHashtagListRequest())
      const hashtagList = getHashtagList(userId)
      dispatch(
        fetchLocalHashtagListSuccess({
          hashtagList
        })
      )

      return hashtagList
    } catch (error) {
      dispatch(fetchLocalHashtagListFailure())

      return null
    }
  }
}
